/* @define InlineLinkPlayer */

:root {
  --InlineLinkPlayer-loadingColor: var(--bffBrandBlack);
}

.InlineLinkPlayer {
  display: inline-block;
  position: relative;
  padding-left: 30px;
}

.InlineLinkPlayer-pauseButton,
.InlineLinkPlayer-loadingButton,
.InlineLinkPlayer-playButton {
  position: absolute;
  left: 0;
  top: 0;
}

.InlineLinkPlayer-loadingButton {
  left: 2px;
  top: 3px;
}

.InlineLinkPlayer-loadingButton {
  border: 1px dashed var(--InlineLinkPlayer-loadingColor);
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.InlineLinkPlayer-pauseButton,
.InlineLinkPlayer-loadingButton {
  display: none;
}

.InlineLinkPlayer-pauseButton,
.InlineLinkPlayer-playButton {
  font-size: 175%;
  height: 20px;
  overflow: hidden;
}

/* @state is-loading */

@keyframes InlineLinkPlayer-loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-359deg); }
}

.InlineLinkPlayer.is-loading .InlineLinkPlayer-loadingButton {
  display: inline-block;
  animation: InlineLinkPlayer-loading 1.5s linear infinite;
}

.InlineLinkPlayer.is-loading .InlineLinkPlayer-playButton,
.InlineLinkPlayer.is-loading .InlineLinkPlayer-pauseButton {
  display: none;
}


/* @state is-playing */
.InlineLinkPlayer.is-playing .InlineLinkPlayer-pauseButton {
  display: inline-block;
}

.InlineLinkPlayer.is-playing .InlineLinkPlayer-loadingButton,
.InlineLinkPlayer.is-playing .InlineLinkPlayer-playButton {
  display: none;
}
