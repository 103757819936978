/** @define PopularTrack */

.PopularTrack {
  display: flex;
  gap: 20px;
  align-items: baseline;
  justify-content: space-between;

  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}

.PopularTrack-rank {
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
}

.PopularTrack-iconImage {
  width: 30px;
  height: 30px;
  object-fit: contain;
  flex-grow: 0;
  flex-shrink: 0;
}

.PopularTrack-label {
  flex-grow: 50;
  padding: 5px 0;
}

.PopularTrack-chart {
  min-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}

.PopularTrack--highlighted {
  --HorizontalBar-background: var(--bffBrandBlueAlpha);
  --HorizontalBar-fill: var(--bffBrandBlue);
}

.PopularTrack--highlighted .PopularTrack-label {
  font-weight: var(--fontWeightBold);
}
