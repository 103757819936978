/** @define ShowSchedule */

@import "./show-schedule-item";
@import "./time-ruler";
@import "./show-schedule-column";

:root {
  --ShowSchedule-timeRulerWidth: 5em;
}

@media (prefers-color-scheme: dark) {
  :root {
    --ShowSchedule-hoverColor: --bffBrandYellowAlpha;
  }
}

.ShowSchedule {
  position: relative;
  display: flex;
  width: 100%;

  overflow-x: scroll;
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
  scroll-padding: calc(var(--ShowSchedule-timeRulerWidth) + 10px);
}

.ShowSchedule-timeRuler {
  flex-basis: var(--ShowSchedule-timeRulerWidth);
  min-width: var(--ShowSchedule-timeRulerWidth);

  position: sticky;
  left: 0;
  z-index: 10; /* ensure appears over the schedule cells that scroll underneath it */
}

.ShowSchedule-dayColumn {
  flex-basis: calc(calc(100% - var(--ShowSchedule-timeRulerWidth)) / 7);
  min-width: 250px;

  scroll-snap-align: start;
  scroll-snap-stop: always;
}
