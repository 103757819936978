/** @define UserSummary */

:root {
  --UserSummary-headlineColor: var(--regularTextColor);
  --UserSummary-subColor: var(--bffBrandBlue);
  --UserSummary-bodyColor: var(--greyTextColor);
  --UserSummary-metadataColor: var(--lightGreyTextColor);
}

.UserSummary {
  padding: 0 20px 10px 0;
}

.UserSummary-title {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  color: var(--UserSummary-headlineColor);
}

.UserSummary-sub {
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--UserSummary-subColor);
}

.UserSummary-description {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 15px 0; /* Ends up looking more like 20px after line heights */
  color: var(--UserSummary-bodyColor);
}

.UserSummary-viewMoreLink {
  color: var(--UserSummary-subColor);
  display: inline-block;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}

.UserSummary-viewMoreLink::after {
  content: ' →';
}

.UserSummary-followButton {
  margin: 15px 0;
}

.UserSummary-shows {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  color: var(--UserSummary-bodyColor);
  margin: 10px 0;
}

.UserSummary-show {
  margin-bottom: 5px;
}

.UserSummary-socialIcons {
  margin: 15px 0;
  justify-content: left;
  font-size: 18px;
  line-height: 18px;
}

.UserSummary-time {}
