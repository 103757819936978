/** @define GiveawayRail */

.GiveawayRail {}

.GiveawayRail-header {
  display: flex;
  align-items: center;
}

.GiveawayRail-mascot {
  order: 0;
  height: 150px;
  flex-grow: 0;
}

.GiveawayRail-title {
  order: 1;
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-size: 35px;
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  text-align: right;
}

.GiveawayRail-item {
  margin: 0 0 20px;
  border-radius: 8px;
  flex-shrink: 0;
}

.GiveawayRail-moreLink {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--bffBrandBlue);
}

@media (--smallWidth) {
  .GiveawayRail {
    display: flex;
    flex-direction: row;
  }

  .GiveawayRail-header {
    flex-direction: column;
  }

  .GiveawayRail-mascot {
    height: 80px;
  }

  .GiveawayRail-title {
    order: 1;
    font-size: 20px;
    line-height: 16px;
    transform-origin: 50%;
    transform: translate(0, 25px) rotate(-90deg);
  }

  .GiveawayRail-item {
    margin: 0 20px 0 0;
    flex-basis: 200px;
  }

  .GiveawayRail-content {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  .GiveawayRail-footer {
    display: none;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    .GiveawayRail {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}
