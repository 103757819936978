/** @define BFFMenuBar */

:root {
  --BFFMenuBar-maxWidth: var(--contentMaxWidth);
  --BFFMenuBar-horizontalPadding: var(--contentStandardPadding);
  --BFFMenuBar-verticalPadding: var(--contentStandardPadding);
  --BFFMenuBar-expandedHeight: 130px;
  --BFFMenuBar-smallExpandedHeight: 100px;
  --BFFMenuBar-collapsedHeight: 50px;

  --BFFMenuBar-logoWidth: calc((var(--BFFMenuBar-expandedHeight) - (2 * var(--BFFMenuBar-verticalPadding))) * 2);
  --BFFMenuBar-smallerLogoWidth: calc(var(--BFFMenuBar-logoWidth) - 100px);
}

.BFFMenuBar {
  box-sizing: border-box;
  margin: 0 auto;
  padding: var(--BFFMenuBar-verticalPadding) var(--BFFMenuBar-horizontalPadding);

  padding-left: max(env(safe-area-inset-left), var(--BFFMenuBar-horizontalPadding));
  padding-right: max(env(safe-area-inset-right), var(--BFFMenuBar-horizontalPadding));

  height: var(--BFFMenuBar-expandedHeight);
  width: 100%;
  max-width: var(--BFFMenuBar-maxWidth);

  transition: height .3s ease-out;

  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: uppercase;
}

.BFFMenuBar-logo {
  --BFF10-renderWidth: var(--BFFMenuBar-logoWidth);
  width: var(--BFFMenuBar-logoWidth);
  position: absolute;
  left: 50%;
  margin-left: calc(var(--BFFMenuBar-logoWidth) / -2);
}

/* flex container separate from abs-positioned logo for Firefox */
.BFFMenuBar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 100%;
}

.BFFMenuBar-menuButton {
  order: 3;
}

.BFFMenuBar-link {
  order: 1;
}

.BFFMenuBar-link:hover,
.BFFMenuBar-link:focus {
  text-decoration: underline;
}

/* option is-collapsed */
.BFFMenuBar.is-collapsed {
  height: var(--BFFMenuBar-collapsedHeight);
}

@media (--smallWidth) {
  .BFFMenuBar {
    height: var(--BFFMenuBar-smallExpandedHeight);
  }
  .BFFMenuBar-logo {
    --BFF10-renderWidth: var(--BFFMenuBar-smallerLogoWidth);
    width: var(--BFFMenuBar-smallerLogoWidth);
    position: absolute;
    left: 50%;
    margin-left: calc(var(--BFFMenuBar-smallerLogoWidth) / -2);
  }
}
