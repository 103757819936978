/** @define RadioShow */

@import "../components/add-reminder";
@import "../components/show-schedule";

:root {
  --RadioShow-regularTextColor: var(--regularTextColor);
  --RadioShow-sidebarColor: var(--accentTextColor);
}

.RadioShow {}

.RadioShow-breadcrumb {
  margin: 0 0 20px;
  color: var(--RadioShow-sidebarColor);
}

.RadioShow-tabs {
  margin: -20px -20px 20px;
  display: none;
}

.RadioShow-about {
  color: var(--RadioShow-sidebarColor);
  flex-basis: 380px;
  flex-shrink: 50;
  min-width: 300px;
  margin-bottom: 20px;
}

.RadioShow-content {
  flex-shrink: 40;
}

.RadioShow-metaHeading {
  color: var(--RadioShow-regularTextColor);
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
}

.RadioShow-description,
.RadioShow-schedule {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 20px 0;
}

.RadioShow-categories {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}

.RadioShow-social {
  margin: 20px 0;
}

.RadioShow-websiteLink {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 20px 0 10px;
}

.RadioShow-emailLink {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 10px 0;
}

.RadioShow-socialIcons {
  margin: 20px 0;
  justify-content: left;
}

.RadioShow-categories {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
}

@media (--mediumWidth) {
  .RadioShow-breadcrumb {
    display: none;
  }

  .RadioShow-tabs {
    display: block;
  }
}

@media (--smallWidth) {
  .RadioShow-description,
  .RadioShow-schedule {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }

  .RadioShow-categories {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}
