/** @define PostsPage */

@import "../components/giveaway-rail";
@import "../components/giveaway-lozenge";
@import "../components/postsnavigation";
@import "../components/post-preview";
@import "../components/add-feed";

:root {
  --PostsPage-sectionDescriptionColor: var(--accentTextColor);
  --PostsPage-metadataColor: var(--accentTextColor);
}

.PostsPage-header {
  margin: 20px 0;
  display: flex;
}

.PostsPage-categories {
  flex-grow: 100;
}

.PostsPage-breadcrumb {
  margin: 0 0 20px;
  color: var(--PostsPage-metadataColor);
}

.PostsPage-subscribe {
  text-align: right;
}

.PostsPage-giveaways {
  flex-basis: 260px;
  flex-shrink: 0;
  margin-left: 10px;
}

.PostsPage-section {
  margin: 40px auto;
}

.PostsPage-sectionHeading {
  font-size: var(--fontSizeTitle);
  line-height: var(--fontHeightTitle);
  font-weight: var(--fontWeightBold);
  margin: 0 0 10px;
}

.PostsPage-sectionDescription {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 0 0 20px;
  color: var(--PostsPage-sectionDescriptionColor);
}

@media (--smallWidth) {
  .PostsPage-header {
    flex-direction: column;
  }

  .PostsPage-giveaways {
    order: 0;
    flex-basis: auto;
    margin: 0;
  }

  .PostsPage-mainFeed {
    order: 1;
  }

  .PostsPage-subscribe {
    text-align: left;
    margin: 20px 0 0;
  }

  /* In columns, override the default PageContainer padding to allow full-bleed of the giveaways rail */
  .PostsPage--columns {
    padding: 20px 0;
  }

  /* In columns, restore padding for the content feed */
  .PostsPage--columns .PostsPage-mainFeed {
    padding: 0 20px;
  }
}
