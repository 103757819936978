/** @define SimpleButton */

.SimpleButton {
  padding: 10px 30px;
  display: inline-block;

  background: inherit;
  color: inherit;
  border: none;
  border-radius: 4px;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  white-space: nowrap;

  text-align: center;
  cursor: pointer;
}

.SimpleButton--withBorder {
  border: 4px solid;
}

.SimpleButton--wrappable {
  white-space: normal;
}

.SimpleButton:hover,
.SimpleButton:focus {
  text-decoration: underline;
}
