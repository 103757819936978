/* @define ArticlePreview */

:root {
  --ArticlePreview-placeholderColor: var(--bffBrandBlue);
}

.ArticlePreview {
  display: flex;
  margin: 0 0 40px;
}

.ArticlePreview-banner {
  order: 1;
  flex-basis: 300px;
  flex-shrink: 0;
  margin-right: 20px;
}

.ArticlePreview-image {
  max-width: 100%;
}

.ArticlePreview-placeholder {
  order: 1;
  height: 5px;
  flex-basis: 300px;
  flex-shrink: 0;
  margin: 11px 20px 0 0;
  background: var(--ArticlePreview-placeholderColor);
}

.ArticlePreview-content {
  order: 2;
}

/* @variant narrowColumn */
.ArticlePreview--narrowColumn .ArticlePreview-banner,
.ArticlePreview--narrowColumn .ArticlePreview-placeholder {
  flex-basis: 180px;
}

@media (--midiWidth) {
  .ArticlePreview-banner,
  .ArticlePreview-placeholder {
    flex-basis: 180px;
  }
}

@media (--mediumWidth) {
  .ArticlePreview--multiColumn .ArticlePreview-banner,
  .ArticlePreview--multiColumn .ArticlePreview-placeholder {
    flex-basis: 180px;
  }
}


/* @variant stacked - as per small width, image above block */
.ArticlePreview--stacked {
  flex-direction: column;
}

.ArticlePreview--stacked  .ArticlePreview-placeholder {
  display: none;
}

.ArticlePreview--stacked .ArticlePreview-banner {
  flex-basis: 100%;
  margin-right: 0;
  margin-bottom: 10px;
  flex-basis: auto;
}

.ArticlePreview--stacked .ArticlePreview-image {
  width: 100%;
}

@media (--smallWidth) {
  .ArticlePreview {
    flex-direction: column;
  }

  .ArticlePreview-placeholder {
    display: none;
  }

  .ArticlePreview-banner {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    flex-basis: auto;
  }

  .ArticlePreview-image {
    width: 100%;
  }
}
