/** @define PodcastNetworkCta */

:root {
  --PodcastNetworkCta-background: var(--accentBackgroundHalf);
  --PodcastNetworkCta-backgroundStripes: repeating-linear-gradient(135deg, var(--accentBackground) 0px, var(--accentBackgroundHalf) 1px, var(--accentBackgroundHalf) 8px);
  --PodcastNetworkCta-headerText: var(--regularTextColor);
  --PodcastNetworkCta-descText: var(--accentTextColor);
  --PodcastNetworkCta-buttonText: var(--onLightTextColor);
  --PodcastNetworkCta-aboutButton: var(--bffBrandBlue);
  --PodcastNetworkCta-joinButton: var(--bffBrandYellow);
}

.PodcastNetworkCta {
  background-color: var(--PodcastNetworkCta-background);
  background-image: var(--PodcastNetworkCta-backgroundStripes);

  text-align: center;
  padding: 20px;
}

.PodcastNetworkCta-title {
  font-size: var(--fontSizeXL);
  line-height: var(--fontHeightXL);
  font-weight: var(--fontWeightBold);
  color: var(--PodcastNetworkCta-headerText);
}

.PodcastNetworkCta-description {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  color: var(--PodcastNetworkCta-descText);
  max-width: 35em;
  margin: 20px auto;
}

.PodcastNetworkCta-learnButton,
.PodcastNetworkCta-joinButton {
  margin: 20px 0 0;
  width: 280px;
  color: var(--PodcastNetworkCta-buttonText);
}

.PodcastNetworkCta-learnButton {
  background-color: var(--PodcastNetworkCta-aboutButton);
}
.PodcastNetworkCta-joinButton {
  background-color: var(--PodcastNetworkCta-joinButton);
}
