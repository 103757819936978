/** @define ShowScheduleColumn */

:root {
  --ShowScheduleColumn-unitHeight: max(20px, var(--fontHeightR));
  --ShowScheduleColumn-halfHourUnit: calc(var(--ShowScheduleColumn-unitHeight) * 2);
  --ShowScheduleColumn-hourHeight: calc(var(--ShowScheduleColumn-unitHeight) * 4);

  --ShowScheduleColumn-metadataColor: var(--accentTextColor);
  --ShowScheduleColumn-backgroundColor: var(--accentBackground);
  --ShowScheduleColumn-borderColor: var(--lightGreyTextColor);
  --ShowScheduleColumn-todayHighlightColor: var(--bffBrandBlueAlpha);
  --ShowScheduleColumn-todayBorderColor: var(--bffBrandBlue);
}

.ShowScheduleColumn {}

.ShowScheduleColumn-header {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--ShowScheduleColumn-metadataColor);

  padding: 8px 6px 10px;
  margin-bottom: 2px;
  border-bottom: 1px solid var(--ShowScheduleColumn-borderColor);
}

.ShowScheduleColumn-headerDate {
  font-weight: var(--fontWeightRegular);
}

.ShowScheduleColumn-canvas {
  position: relative;
  height: calc(var(--ShowScheduleColumn-hourHeight) * 24);
  background: var(--ShowScheduleColumn-backgroundColor);
}

.ShowScheduleColumn-item {
  position: absolute;
  left: 1px;
  width: calc(100% - 2px);
}

/** @variant today a column highlighted because it contains content for the current day */
.ShowScheduleColumn--today {
  background: var(--ShowScheduleColumn-todayHighlightColor);
}

.ShowScheduleColumn--today .ShowScheduleColumn-canvas {
  background: transparent;
}

.ShowScheduleColumn--today .ShowScheduleColumn-header {
  border-color: var(--ShowScheduleColumn-todayBorderColor);
}

.ShowScheduleColumn--today .ShowScheduleColumn-item {
  left: 4px;
  width: calc(100% - 8px);
}

/* Size and Position Alignment Classes */

.ShowScheduleColumn-itemPosition0 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 0); }
.ShowScheduleColumn-itemPosition1 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 1); }
.ShowScheduleColumn-itemPosition2 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 2); }
.ShowScheduleColumn-itemPosition3 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 3); }
.ShowScheduleColumn-itemPosition4 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 4); }
.ShowScheduleColumn-itemPosition5 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 5); }
.ShowScheduleColumn-itemPosition6 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 6); }
.ShowScheduleColumn-itemPosition7 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 7); }
.ShowScheduleColumn-itemPosition8 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 8); }
.ShowScheduleColumn-itemPosition9 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 9); }
.ShowScheduleColumn-itemPosition10 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 10); }
.ShowScheduleColumn-itemPosition11 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 11); }
.ShowScheduleColumn-itemPosition12 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 12); }
.ShowScheduleColumn-itemPosition13 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 13); }
.ShowScheduleColumn-itemPosition14 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 14); }
.ShowScheduleColumn-itemPosition15 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 15); }
.ShowScheduleColumn-itemPosition16 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 16); }
.ShowScheduleColumn-itemPosition17 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 17); }
.ShowScheduleColumn-itemPosition18 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 18); }
.ShowScheduleColumn-itemPosition19 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 19); }
.ShowScheduleColumn-itemPosition20 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 20); }
.ShowScheduleColumn-itemPosition21 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 21); }
.ShowScheduleColumn-itemPosition22 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 22); }
.ShowScheduleColumn-itemPosition23 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 23); }
.ShowScheduleColumn-itemPosition24 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 24); }
.ShowScheduleColumn-itemPosition25 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 25); }
.ShowScheduleColumn-itemPosition26 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 26); }
.ShowScheduleColumn-itemPosition27 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 27); }
.ShowScheduleColumn-itemPosition28 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 28); }
.ShowScheduleColumn-itemPosition29 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 29); }
.ShowScheduleColumn-itemPosition30 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 30); }
.ShowScheduleColumn-itemPosition31 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 31); }
.ShowScheduleColumn-itemPosition32 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 32); }
.ShowScheduleColumn-itemPosition33 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 33); }
.ShowScheduleColumn-itemPosition34 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 34); }
.ShowScheduleColumn-itemPosition35 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 35); }
.ShowScheduleColumn-itemPosition36 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 36); }
.ShowScheduleColumn-itemPosition37 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 37); }
.ShowScheduleColumn-itemPosition38 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 38); }
.ShowScheduleColumn-itemPosition39 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 39); }
.ShowScheduleColumn-itemPosition40 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 40); }
.ShowScheduleColumn-itemPosition41 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 41); }
.ShowScheduleColumn-itemPosition42 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 42); }
.ShowScheduleColumn-itemPosition43 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 43); }
.ShowScheduleColumn-itemPosition44 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 44); }
.ShowScheduleColumn-itemPosition45 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 45); }
.ShowScheduleColumn-itemPosition46 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 46); }
.ShowScheduleColumn-itemPosition47 { top: calc(var(--ShowScheduleColumn-halfHourUnit) * 47); }

.ShowScheduleColumn-itemHeight0 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 0 - 2px); }
.ShowScheduleColumn-itemHeight1 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 1 - 2px); }
.ShowScheduleColumn-itemHeight2 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 2 - 2px); }
.ShowScheduleColumn-itemHeight3 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 3 - 2px); }
.ShowScheduleColumn-itemHeight4 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 4 - 2px); }
.ShowScheduleColumn-itemHeight5 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 5 - 2px); }
.ShowScheduleColumn-itemHeight6 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 6 - 2px); }
.ShowScheduleColumn-itemHeight7 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 7 - 2px); }
.ShowScheduleColumn-itemHeight8 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 8 - 2px); }
.ShowScheduleColumn-itemHeight9 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 9 - 2px); }
.ShowScheduleColumn-itemHeight10 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 10 - 2px); }
.ShowScheduleColumn-itemHeight11 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 11 - 2px); }
.ShowScheduleColumn-itemHeight12 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 12 - 2px); }
.ShowScheduleColumn-itemHeight13 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 13 - 2px); }
.ShowScheduleColumn-itemHeight14 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 14 - 2px); }
.ShowScheduleColumn-itemHeight15 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 15 - 2px); }
.ShowScheduleColumn-itemHeight16 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 16 - 2px); }
.ShowScheduleColumn-itemHeight17 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 17 - 2px); }
.ShowScheduleColumn-itemHeight18 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 18 - 2px); }
.ShowScheduleColumn-itemHeight19 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 19 - 2px); }
.ShowScheduleColumn-itemHeight20 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 20 - 2px); }
.ShowScheduleColumn-itemHeight21 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 21 - 2px); }
.ShowScheduleColumn-itemHeight22 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 22 - 2px); }
.ShowScheduleColumn-itemHeight23 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 23 - 2px); }
.ShowScheduleColumn-itemHeight24 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 24 - 2px); }
.ShowScheduleColumn-itemHeight25 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 25 - 2px); }
.ShowScheduleColumn-itemHeight26 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 26 - 2px); }
.ShowScheduleColumn-itemHeight27 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 27 - 2px); }
.ShowScheduleColumn-itemHeight28 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 28 - 2px); }
.ShowScheduleColumn-itemHeight29 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 29 - 2px); }
.ShowScheduleColumn-itemHeight30 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 30 - 2px); }
.ShowScheduleColumn-itemHeight31 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 31 - 2px); }
.ShowScheduleColumn-itemHeight32 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 32 - 2px); }
.ShowScheduleColumn-itemHeight33 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 33 - 2px); }
.ShowScheduleColumn-itemHeight34 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 34 - 2px); }
.ShowScheduleColumn-itemHeight35 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 35 - 2px); }
.ShowScheduleColumn-itemHeight36 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 36 - 2px); }
.ShowScheduleColumn-itemHeight37 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 37 - 2px); }
.ShowScheduleColumn-itemHeight38 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 38 - 2px); }
.ShowScheduleColumn-itemHeight39 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 39 - 2px); }
.ShowScheduleColumn-itemHeight40 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 40 - 2px); }
.ShowScheduleColumn-itemHeight41 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 41 - 2px); }
.ShowScheduleColumn-itemHeight42 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 42 - 2px); }
.ShowScheduleColumn-itemHeight43 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 43 - 2px); }
.ShowScheduleColumn-itemHeight44 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 44 - 2px); }
.ShowScheduleColumn-itemHeight45 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 45 - 2px); }
.ShowScheduleColumn-itemHeight46 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 46 - 2px); }
.ShowScheduleColumn-itemHeight47 { height: calc(var(--ShowScheduleColumn-halfHourUnit) * 47 - 2px); }
