/** @define Nubbin */

:root {
  --Nubbin-attributionColor: var(--bffBrandBlue);
}

.Nubbin {
  display: flex;
  gap: 20px;
  align-items: center;
}

.Nubbin-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.Nubbin-title {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
}

.Nubbin-byline {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
}

.Nubbin-attribution {
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);
  color: var(--Nubbin-attributionColor);
}

.Nubbin--small .Nubbin-image {
  width: 50px;
  height: 50px;
}

.Nubbin--small .Nubbin-title {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
}

@media (--smallWidth) {
  .Nubbin-image {
    width: 50px;
    height: 50px;
  }
}

