/** @define ShowScheduleItem */

:root {
  --ShowScheduleItem-background: var(--regularBackground);
  --ShowScheduleItem-depreciatedBackground: var(--bffBrandBlueAlpha);
  --ShowScheduleItem-hostTextColor: var(--bffBrandRed);

  --ShowScheduleItem-logoBorder: var(--accentBackground);

  --ShowScheduleItem-onAirBorder: var(--bffBrandRed);
  --ShowScheduleItem-onAirBackground: var(--bffBrandRedAlpha);

  --ShowScheduleItem-highlightBackground: var(--bffBrandBlueAlpha);
  --ShowScheduleItem-highlightBorder: var(--bffBrandBlue);
}

.ShowScheduleItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 5px;
  /* text-align: center; */
  border: 2px solid transparent;

  background: var(--ShowScheduleItem-background);

  transition: background .2s ease-out, border-color .2s ease-out, border-radius .2s ease-out;
}

.ShowScheduleItem:hover {
  background: var(--ShowScheduleItem-highlightBackground);
  border-color: var(--ShowScheduleItem-highlightBorder);
  border-radius: 4px;
}

.ShowScheduleItem-title {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

.ShowScheduleItem-host {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  color: var(--ShowScheduleItem-hostTextColor);
}

.ShowScheduleItem-onAirLabel {
  position: absolute;
  top: 4px;
  left: 4px;
}

.ShowScheduleItem-specialLabel {
  position: absolute;
  top: 4px;
  right: 4px;
}

.ShowScheduleItem-logo {
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 2px solid var(--ShowScheduleItem-logoBorder);
  background: var(--ShowScheduleItem-logoBorder);
}

/** @variant onAir highlights a schedule item if it is currently live on air */
.ShowScheduleItem--onAir {
  border-color: var(--ShowScheduleItem-onAirBorder);
  border-radius: 4px;
}

.ShowScheduleItem--onAir:hover {
  border-color: var(--ShowScheduleItem-onAirBorder);
  background: var(--ShowScheduleItem-onAirBackground);
}

.ShowScheduleItem--onAir .ShowScheduleItem-logo {
  border-color: var(--ShowScheduleItem-onAirBorder);
}

/** @variant onAir highlights a schedule item if it is currently live on air */
.ShowScheduleItem--inPast {
  background: var(--ShowScheduleItem-depreciatedBackground);
}

.ShowScheduleItem--onAir .ShowScheduleItem-logo {
  border-color: var(--ShowScheduleItem-highlightBorder);
}

.ShowScheduleItem--withLogo {
  padding-left: 60px;
}

