/** @define TracklistItem */

:root {
  --TracklistItem-offsetColor: var(--accentTextColor);
  --TracklistItem-newColor: var(--bffBrandRed);
  --TracklistItem-localColor: var(--bffBrandBlue);
}

.TracklistItem {
  position: relative;
  margin-left: 20px;
}

.TracklistItem-headline {
  margin: 10px 0;
  display: flex;
  align-items: baseline;
}

.TracklistItem-timestamp {
  flex-basis: 5em;
  flex-shrink: 0;

  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;

  color: var(--TracklistItem-offsetColor);
}

.TracklistItem-trackInfo {
  flex-grow: 50;
}

.TracklistItem-artist,
.TracklistItem-title {
  font-weight: var(--fontWeightBold);
}

.TracklistItem-album {}
.TracklistItem-title {}
.TracklistItem-label {
  font-style: italic;
  white-space: nowrap; /* Stop wrapping inside of parantheses */
}

.TracklistItem-trackLink::before {
  content: '↗';
}

.TracklistItem-badges {
  display: flex;
}

.TracklistItem-badge {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0 0 0 6px;
}

.TracklistItem-badge:first-child {
  margin: 0;
}

.TracklistItem-buyLink {
  color: var(--TracklistItem-offsetColor);
}

.TracklistItem-localBadge {
  color: var(--TracklistItem-localColor);
  font-weight: var(--fontWeightThemeBold);
}
.TracklistItem-newBadge {
  color: var(--TracklistItem-newColor);
  font-weight: var(--fontWeightThemeBold);
}

.TracklistItem-feature {
  position: absolute;
  top: 6px;
  left: -30px;
  width: 30px;
  text-align: center;
  font-size: 13px;
}

.TracklistItem-commentary {
  margin-top: 10px;
  margin-left: 5em;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}

/** @variant --noFeature Do not intend for featured track */
.TracklistItem--noFeature {
  margin-left: 0;
}

@media (--smallWidth) {
  .TracklistItem-timestamp {
    flex-basis: 4em;
  }

  .TracklistItem-commentary {
    margin-left: 4em;
  }

  .TracklistItem-badges {
    flex-direction: column;
    align-items: flex-end;
  }

  .TracklistItem-badge {
    margin: 0;
  }
}
