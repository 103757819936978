/** @define UserBio */
:root {
  --UserBio-avatarBorder: var(--lightGreyTextColor);
  --UserBio-offset: var(--accentTextColor);
}


.UserBio {
  position: relative;
  min-height: 80px;
  padding-left: 100px;
}

.UserBio-image {
  width: 80px;
  height: 80px;
  object-fit: cover;

  border-radius: 3px;
  border: 4px solid var(--UserBio-avatarBorder);

  position: absolute;
  top: 0;
  left: 0;
}

.UserBio-name {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
}

.UserBio-caption {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightThemeBold);
}

.UserBio-description {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  margin: 20px 0;
}

.UserBio-links {
  margin: 20px 0;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  color: var(--UserBio-offset);
}

@media (--smallWidth) {
  .UserBio {
    padding-left: 90px;
  }

  .UserBio-name {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
  }

  .UserBio-caption {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .UserBio-description {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }

  .UserBio-links {
    margin: 20px 0;
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}
