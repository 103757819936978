/** @define StickyFooter */

:root {
  --StickyFooter-maxWidth: var(--contentMaxWidth);
  --StickyFooter-horizontalPadding: 20px;
  --StickyFooter-verticalPadding: 20px;

  --StickyFooter-background: #ccc;
  --StickyFooter-text: #000;

  --StickyFooter-reservedSpace: 120px;

  --StickyFooter-borderWidth: 8px;
  --StickyFooter-unitlessBorderWidth: 8;

  --StickyFooter-borderStartColor: var(--bffBrandRed);
  --StickyFooter-borderMiddleColor: var(--bffBrandYellow);
  --StickyFooter-borderEndColor: var(--bffBrandBlue);
  --StickyFooter-background: var(--accentBackground);
  --StickyFooter-text: var(--accentTextColor);
  --StickyFooter-barTextColor: var(--playerTextColor);
  --StickyFooter-barBackgroundColor: var(--playerBackground);
  --StickyFooter-barBackgroundTranslucentColor: var(--playerTranslucentBackground);
}

.StickyFooter {
  background: var(--StickyFooter-background);
  color: var(--StickyFooter-text);
  padding-bottom: var(--StickyFooter-reservedSpace);
}

.StickyFooter-nav {
  max-width: var(--StickyFooter-maxWidth);
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: var(--StickyFooter-verticalPadding) var(--StickyFooter-horizontalPadding);
  padding-left: max(env(safe-area-inset-left), var(--StickyFooter-horizontalPadding));
  padding-right: max(env(safe-area-inset-right), var(--StickyFooter-horizontalPadding));

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  line-height: 25px;
  text-transform: uppercase;
}

.StickyFooter-navBlock {
  min-width: 10em;
  padding: 4px 4px 4px 0;
  margin: 0 0 var(--StickyFooter-verticalPadding);
  flex-grow: 1000;
}

.StickyFooter-social {
  margin-bottom: 20px;
  flex-grow: 10;
}

.StickyFooter-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 5000;

  background: var(--StickyFooter-barBackgroundColor);
  border-style: solid;
  border-width: var(--StickyFooter-borderWidth) 0 0;
  border-image: linear-gradient(to right, var(--StickyFooter-borderStartColor), var(--StickyFooter-borderMiddleColor), var(--StickyFooter-borderEndColor)) var(--StickyFooter-unitlessBorderWidth);
  color: var(--StickyFooter-barTextColor);
  /* Eased Gradient version; negligable difference really, unnecessary expense:
    border-image: linear-gradient(
      to left,
      hsl(175, 45.65%, 63.92%) 0%,
      hsl(174.29, 45.05%, 64.48%) 4.05%,
      hsl(172.06, 43.36%, 65.98%) 7.75%,
      hsl(167.87, 40.66%, 68.17%) 11.25%,
      hsl(160.67, 37.02%, 70.79%) 14.5%,
      hsl(148.27, 32.36%, 73.64%) 17.65%,
      hsl(125.35, 26.55%, 76.58%) 20.6%,
      hsl(94.5, 29.44%, 76.56%) 23.5%,
      hsl(73.53, 34.01%, 75.94%) 26.45%,
      hsl(59.34, 38.92%, 75.41%) 29.4%,
      hsl(51.18, 53.44%, 76.52%) 32.35%,
      hsl(46.8, 67.26%, 77.42%) 35.5%,
      hsl(44.26, 79.53%, 78.12%) 38.75%,
      hsl(42.77, 89.33%, 78.61%) 42.25%,
      hsl(41.97, 95.81%, 78.92%) 45.95%,
      hsl(41.71, 98.13%, 79.02%) 50%,
      hsl(41.28, 97.89%, 78.86%) 54.05%,
      hsl(40.05, 97.23%, 78.4%) 57.75%,
      hsl(38.19, 96.27%, 77.69%) 61.25%,
      hsl(35.85, 95.12%, 76.74%) 64.5%,
      hsl(33.17, 93.88%, 75.61%) 67.65%,
      hsl(30.28, 92.65%, 74.31%) 70.6%,
      hsl(27.3, 91.48%, 72.87%) 73.5%,
      hsl(24.33, 90.43%, 71.35%)76.45%,
      hsl(21.46, 89.53%, 69.77%) 79.4%,
      hsl(18.75, 88.78%, 68.19%) 82.35%,
      hsl(16.3, 88.19%, 66.68%) 85.5%,
      hsl(14.18, 87.76%, 65.3%) 88.75%,
      hsl(12.5, 87.46%, 64.18%) 92.25%,
      hsl(11.38, 87.29%, 63.42%) 95.95%,
      hsl(10.98, 87.23%, 63.14%) 100%
  ) var(--StickyFooter-unitlessBorderWidth); */
}

@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
  .StickyFooter-bar {
    background: var(--StickyFooter-barBackgroundTranslucentColor);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

@media (--smallWidth) {
  .StickyFooter-social {
    width: 100%;
    flex-shrink: 0;
  }
}
