/** @define BlockPlayer */

.BlockPlayer {
  display: flex;
  align-items: center;
}

.BlockPlayer-playButton {
  order: 0;

  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.BlockPlayer-caption {
  order: 1;

  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  text-transform: uppercase;
  margin-left: 10px;
}

.BlockPlayer--small .BlockPlayer-playButton {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.BlockPlayer--small .BlockPlayer-caption {
  margin-left: 0;
}
