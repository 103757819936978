/** @define SiteSearch */

:root {
  --SiteSearch-inputBackgroundColor: var(--accentBackground);
  --SiteSearch-inputTextColor: var(--accentTextColor);
  --SiteSearch-buttonBackgroundColor: var(--bffBrandBlue);
  --SiteSearch-buttonTextColor: var(--onColorTextColor);
}

.SiteSearch {
  display: flex;
  min-width: 300px;
  max-width: 520px;
  min-height: 40px;
  padding: 0 10px;
}

.SiteSearch-box {
  flex-grow: 100;
  border: 0;
  padding: 10px 20px;
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  background: var(--SiteSearch-inputBackgroundColor);
  color: var(--SiteSearch-inputTextColor);
  border-radius: 4px 0 0 4px;
  -webkit-appearance: none;
}

.SiteSearch-box::placeholder {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  text-align: center;
}

.SiteSearch-button {
  border: 0;
  padding: 10px 20px;
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  background: var(--SiteSearch-buttonBackgroundColor);
  color: var(--SiteSearch-buttonTextColor);
  border-radius: 0 4px 4px 0;
}

@media (--smallWidth) {
  .SiteSearch {
    flex-direction: column;
  }

  .SiteSearch-box {
    padding: 10px 10px;
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightL);
    border-radius: 4px 4px 0 0;
  }

  .SiteSearch-box::placeholder {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightL);
  }

  .SiteSearch-button {
    padding: 10px 10px;
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
    border-radius: 0 0 4px 4px;
  }
}
