/** @define CroppedImage */

.CroppedImage {
  display: inline-block;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
}

.CroppedImage-image {
  min-width: 100%;
  min-height: 100%;
  height: 100%;

  /* Center in container */
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  margin: auto;
}
