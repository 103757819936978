/* PaginationContainer */

.pagination-container {
  text-align: center;
  margin: 10px auto;
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

.pagination-container .pagination-inner {
  display: flex;
  justify-content: center;
  overflow-x: scroll;
}

.pagination-container .pagination-inner span {
  text-align: center;
  margin: 0 5px;
  flex-basis: 40px;
}

.pagination-container .pagination-inner span a {
  padding: 0 15px;
}

.pagination-container .prev,
.pagination-container .next {
  white-space: nowrap;
}

.pagination-container .prev::before {
  content: "< ";
  font-weight: var(--fontWeightBold);
  color: var(--bffBrandBlue);
}

.pagination-container .next::after {
  content: " >";
  font-weight: var(--fontWeightBold);
  color: var(--bffBrandBlue);
}

.pagination-container .current {
  padding: 0 15px;
  color: var(--bffBrandBlue);
}

.pagination-container .disabled {
  display: none;
}
