/** @define BroadcastRow */

:root {
  --BroadcastRow--background: var(--accentBackground);
  --BroadcastRow--titleColor: var(--regularTextColor);
  --BroadcastRow--metadataColor: var(--accentTextColor);
  --BroadcastRow--highlightColor: var(--bffBrandRed);
  --BroadcastRow--highlightTextColor: var(--onColorTextColor);
  --BroadcastRow--seasonTextColor: var(--bffBrandBlue);
  --BroadcastRow--imageBackground: var(--accentBackground);
}

.BroadcastRow {
  position: relative;
  padding: 20px;
  min-height: 180px;
  margin: 0 0 20px 180px;

  background-color: var(--BroadcastRow--background);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BroadcastRow-title {
  order: 1;
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  color: var(--BroadcastRow--titleColor);
  margin: 10px 0;
}

.BroadcastRow-metadata {
  order: 0;
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightThemeBold);
  text-transform: uppercase;
  color: var(--BroadcastRow--metadataColor);
}

.BroadcastRow-caption {
  order: 3;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  color: var(--BroadcastRow--metadataColor);
}

.BroadcastRow-contributors {
  order: 2;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  color: var(--BroadcastRow--metadataColor);
  margin-bottom: 10px;
}

.BroadcastRow-guests, .BroadcastRow-hosts {
  display: inline;
}

.BroadcastRow-metaFooter {
  order: 4;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);
  color: var(--BroadcastRow--seasonTextColor);

  margin: 20px 0 0;
}

.BroadcastRow-bonusEpisode {
  text-transform: uppercase;
  border: 1px solid;
  padding: 0 4px;
  border-radius: 4px;
}

.BroadcastRow-duration::before {
  content: " | ";
}

.BroadcastRow-duration:first-child::before {
  content: "";
}

.BroadcastRow-image {
  position: absolute;
  top: 0;
  left: -180px;
  width: 180px;
  height: 180px;

  background: var(--BroadcastRow--imageBackground);
}

.BroadcastRow-playButton {
  position: absolute;
  top: 65px;
  left: -115px;
  width: 50px;
  height: 50px;
}

@media (--smallWidth) {
  .BroadcastRow {
    padding: 10px;
    margin-left: 100px;
    min-height: 100px;
  }

  .BroadcastRow-image {
    width: 100px;
    height: 100px;
    left: -100px;
  }

  .BroadcastRow-playButton {
    top: 25px;
    left: -75px;
  }

  .BroadcastRow-title {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    weight: var(--fontWeightBold);
    margin: 5px 0;
  }

  .BroadcastRow-metadata {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
  }

  .BroadcastRow-contributors,
  .BroadcastRow-caption {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}

/* @variant live
 * The current row is being broadcast right now, so highlight it */

.BroadcastRow--live {
  background: var(--BroadcastRow--highlightColor);
  color: var(--BroadcastRow--highlightTextColor);
  border: 10px var(--BroadcastRow--highlightColor) solid;
  margin: 10px 0 20px -10px; /* Adjust to keep content left-aligned */
  padding-left: 200px;
  min-height: 200px; /* increase to account for added border */
}

.BroadcastRow--live .BroadcastRow-image {
  left: 0px;
}

.BroadcastRow--live .BroadcastRow-playButton {
  left: 65px;
}

.BroadcastRow--live .BroadcastRow-title,
.BroadcastRow--live .BroadcastRow-caption,
.BroadcastRow--live .BroadcastRow-contributors,
.BroadcastRow--live .BroadcastRow-seasonFooter,
.BroadcastRow--live .BroadcastRow-metaFooter,
/* HACK: Break encapsulation to override guest name link colors */
.BroadcastRow--live .GuestList-item {
  color: var(--BroadcastRow--highlightTextColor);
}

.BroadcastRow--live .BroadcastRow-metadata {
  color: var(--BroadcastRow--highlightTextColor);
}

@media (--smallWidth) {
  .BroadcastRow--live {
    padding-left: 110px;
    min-height: 120px;
  }

  .BroadcastRow--live .BroadcastRow-playButton {
    left: 25px;
  }
}
