/** @define FeatureList */

.FeatureList {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.FeatureList-heading {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: capitalize;
  font-weight: var(--fontWeightBold);
  margin: 0 0 20px;

  flex-basis: calc(100%/3);
  flex-grow: 1;
  min-width: 250px;

  position: relative;
}

.FeatureList-footer {
  flex-basis: 100%;
}

.FeatureList-list {
  display: flex;
  flex-wrap: wrap;
  flex-basis: calc(100%/2);
  flex-grow: 50;

  counter-reset: featureItem;
}

.FeatureList-item {
  list-style-position: none;
  margin-bottom: 10px;

  flex-basis: calc(100%/2);
  flex-grow: 50;
  min-width: 250px;

  counter-increment: featureItem;
}

.FeatureList-title::before {
  content: counter(featureItem) ". ";
}

.FeatureList-item:last-child {
  margin-bottom: 0;
}

.FeatureList-title {
  display: block;
  font-size: 16px;
  line-height: 1.8;
  font-weight: normal;
}

.FeatureList-description {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
