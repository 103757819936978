/** @define DynamicDocument */

:root {
  --DynamicDocument-barColor: #c00;
  --DynamicDocument-barHeight: 4px;
  --DynamicDocument-barSegmentLength: 20px;

  --DynamicDocument-barFirstColor: var(--bffBrandBlue);
  --DynamicDocument-barSecondColor: var(--bffBrandYellow);
  --DynamicDocument-barThirdColor: var(--bffBrandRed);
}

.DynamicDocument {}

.DynamicDocument-content {
  min-height: calc(100vh - 450px);
  transition: filter 0.5s ease-out .2s, opacity 0.5s ease-out .2s;
}

.DynamicDocument-loadingBar {
  position: fixed;
  top: 0;
  left: 0;

  display: inline-block;
  opacity: 0;
  width: 0%;
  overflow-x: hidden;

  z-index: 30000;

  transition: opacity .2s ease-out, width .1s ease-out 1s;
}

.DynamicDocument-loadingBarFill {
  display: block;
  height: var(--DynamicDocument-barHeight);
  width: 4000px;
  background: transparent repeating-linear-gradient(to right, var(--DynamicDocument-barFirstColor), var(--DynamicDocument-barFirstColor) var(--DynamicDocument-barSegmentLength), var(--DynamicDocument-barSecondColor) var(--DynamicDocument-barSegmentLength), var(--DynamicDocument-barSecondColor) calc(var(--DynamicDocument-barSegmentLength) * 2), var(--DynamicDocument-barThirdColor) calc(var(--DynamicDocument-barSegmentLength) * 2), var(--DynamicDocument-barThirdColor) calc(var(--DynamicDocument-barSegmentLength) * 3));
}

/** @state is-loading
  * A page dynamic page request is in progress
  */
@keyframes DynamicDocument-loadingBarScroll {
  0%, 100% { transform: translate(0, 0); }
  99.9% { transform: translate(calc(var(--DynamicDocument-barSegmentLength) * -3), 0); }
}

.DynamicDocument.is-loading .DynamicDocument-loadingBarFill {
  animation: DynamicDocument-loadingBarScroll 1s ease-in-out infinite;
}

.DynamicDocument.is-loading .DynamicDocument-content {
  filter: blur(2px);
  opacity: 0.5;
  transition: filter 0.2s ease-in .2s, opacity .2s ease-in .2s;
}

/** @state has-loaded
  * Modifies is-loaded with longer progress bar
  */
.DynamicDocument.has-loaded .DynamicDocument-loadingBar {
  opacity: 1;
  width: 30%;
  transition: width .2s ease-out .2s, opacity .2s ease-out;
}

.DynamicDocument.has-loaded-40 .DynamicDocument-loadingBar { width: 40%; }
.DynamicDocument.has-loaded-50 .DynamicDocument-loadingBar { width: 50%; }
.DynamicDocument.has-loaded-60 .DynamicDocument-loadingBar { width: 60%; }
.DynamicDocument.has-loaded-70 .DynamicDocument-loadingBar { width: 70%; }
.DynamicDocument.has-loaded-80 .DynamicDocument-loadingBar { width: 80%; }
.DynamicDocument.has-loaded-90 .DynamicDocument-loadingBar { width: 90%; }
.DynamicDocument.has-loaded-100 .DynamicDocument-loadingBar { width: 100%; }

/* For unknown page sizes, go to 80% and then stop */
.DynamicDocument.has-loaded-unknown .DynamicDocument-loadingBar {
  width: 80%;
  transition: width 2s ease-out;
}


