/* Additional style resets/initial styles */
html {
  font: normal normal normal var(--fontSizeR)/var(--fontHeightR) var(--fontStack);
  background: var(--regularBackground);
  color: var(--regularTextColor);
}

a {
  color: inherit;
  text-decoration: inherit;
}

a:link:hover,
a:link:focus {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font: inherit;
}

abbr[title] {
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  /* Reduce intensity of images */
  img:not([src*=".svg"]) {
    filter: grayscale(20%);
  }
}

img {
  transition: filter 1s ease-out;
}

q::before, q::after {
  content: none;
}

cite {
  font-style: normal;
}

mark {
  background: none;
}
