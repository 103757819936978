/** @PlaybackStatBlurb */

.PlaybackStatBlurb {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

@media (--smallWidth) {
  .PlaybackStatBlurb {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }
}
