/** @define ArticleNeighbors */

.ArticleNeighbors {}

.ArticleNeighbors-title {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin-bottom: 40px;
}

.ArticleNeighbors-items {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.ArticleNeighbors-item {
  width: 250px;
}

@media (--smallWidth) {
  .ArticleNeighbors-item {
    width: 180px;
  }
}
