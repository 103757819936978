@import "./offer-star";

:root {
  --GiveawayLozenge-labelColor: var(--bffBrandRed);
  --GiveawayLozenge-labelTextColor: var(--bffBrandWhite);
}

.GiveawayLozenge {
  position: relative;
  /* background: linear-gradient(45deg, #252525, #58595B); */
  background: linear-gradient(45deg, #7BDDD5, #49AEB7);
  color: #fff;
  max-width: 260px;
}

.GiveawayLozenge-image {
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.GiveawayLozenge-header {
  width: 100%;
  padding: 5px 10px 10px;
  text-shadow: -1px 1px 2px rgba(0, 0, 0, .2);
}

.GiveawayLozenge-title {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
}

.GiveawayLozenge-subTitle {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
}

.GiveawayLozenge-date {
  position: absolute;
  top: 10px;
  right: -10px;
  padding: 4px 6px;
  border-radius: 2px;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  color: var(--GiveawayLozenge-labelTextColor);
  background: var(--GiveawayLozenge-labelColor);
}

.GiveawayLozenge-deadline {
  position: absolute;
  top: 85px;
  left: -10px;
  transform: rotate(12deg);
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);

  color: var(--GiveawayLozenge-labelTextColor);
  background: var(--GiveawayLozenge-labelColor);
}

.GiveawayLozenge:nth-child(odd) .GiveawayLozenge-deadline {
  top: auto;
  left: auto;
  bottom: 20px;
  right: -5px;
  transform: rotate(-6deg);
}

@media (--smallWidth) {
  .GiveawayLozenge-title {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
    font-weight: var(--fontWeightBold);
  }

  .GiveawayLozenge-subTitle {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
    text-transform: uppercase;
  }

  .GiveawayLozenge-date {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
    text-transform: uppercase;
  }
}
