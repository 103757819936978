/** @define ResponsiveImageSlide */

.ResponsiveImageSlide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  background: linear-gradient(45deg, #252525, #58595B);
  color: #fff;
}

.ResponsiveImageSlide-imageContainer {
  flex-basis: 60%;
  overflow: hidden;
  position: relative;
}

.ResponsiveImageSlide-image {
  height: 100%;
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  margin: auto;
}

.ResponsiveImageSlide-text {
  flex-basis: 40%;
  padding: 20px 20px;
  background: linear-gradient(45deg, #252525, #58595B);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.ResponsiveImageSlide-title {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin: 0 0 20px;
  order: 2;
}

.ResponsiveImageSlide-tag {
  order: 1;
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  text-transform: uppercase;
  margin: 0 0 20px;
}

.ResponsiveImageSlide-title::after {
  content: "";
  display: block;
  margin: 20px 0;
  width: 45px;
  border: 2px solid #fff;
  border-width: 4px 0 0 0;
}

.ResponsiveImageSlide-excerpt {
  order: 3;
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

.ResponsiveImageSlide--alt .ResponsiveImageSlide-text {
  background: linear-gradient(45deg, #7BDDD5, #49AEB7);
}

@media (--mediumWidth) {
  .ResponsiveImageSlide-title {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    font-weight: var(--fontWeightBold);
  }

  .ResponsiveImageSlide-excerpt {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }
}

@media (--smallWidth) {
  .ResponsiveImageSlide-imageContainer {
    flex-basis: 100%;
  }

  .ResponsiveImageSlide-text {
    position: absolute;
    display: block;
    padding: 20px 0 45px 20px;
    text-align: right;
    bottom: 0;
    right: 0;
    left: 0;
    background: none;
  }

  .ResponsiveImageSlide-title {
    display: inline;
    text-align: right;
    padding: 5px 5px 5px 10px;
    white-space: pre-wrap;
    background: linear-gradient(45deg, #252525, #58595B);
  }

  .ResponsiveImageSlide-title::after,
  .ResponsiveImageSlide-tag,
  .ResponsiveImageSlide-excerpt {
    display: none;
  }

  .ResponsiveImageSlide--alt .ResponsiveImageSlide-text {
    background: none;
  }

  .ResponsiveImageSlide--alt .ResponsiveImageSlide-title {
    display: inline;
    text-align: right;
    background: linear-gradient(45deg, #7BDDD5, #49AEB7);
  }
}
