/** @define FullContextTrack */

.FullContextTrack {
  --FullContextTrack-timeColor: var(--accentTextColor);

  display: flex;
  gap: 20px;
  align-items: center;
}

.FullContextTrack-data {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM)
}

.FullContextTrack-image {
  display: block;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.FullContextTrack-time {
  font-size: var(--fontSizeS);
  font-weight: var(--fontWeightBold);
  color: var(--FullContextTrack-timeColor);
}

@media(--smallWidth) {
  .FullContextTrack {
    gap: 10px;
    align-items: flex-start;
  }
}
