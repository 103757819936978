/** @define FixedGlobalNav */

:root {
  --FixedGlobalNav-backgroundColor: var(--regularBackground);
  --FixedGlobalNav-transparentBackgroundColor: var(--regularBackgroundTranslucent);
  --FixedGlobalNav-reservedSpace: 130px;
  --FixedGlobalNav-smallReservedSpace: 100px;
  --FixedGlobalNav-minReservedSpace: 40px;
  --FixedGlobalNav-menuReservedSpace: 100px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --FixedGlobalNav-backgroundColor: var(--accentBackground);
    --FixedGlobalNav-transparentBackgroundColor: var(--accentBackgroundTranslucent);
  }

  .FixedGlobalNav-content {
    /* Easing gradient from: https://larsenwork.com/easing-gradients/ */
    background-image: linear-gradient(
      to bottom,
      hsl(0, 0%, 15%) 0%,
      hsl(0, 0%, 14.92%) 10.9%,
      hsl(0, 0%, 14.69%) 20.3%,
      hsl(0, 0%, 14.33%) 28.6%,
      hsl(0, 0%, 13.85%) 35.9%,
      hsl(0, 0%, 13.27%) 42.4%,
      hsl(0, 0%, 12.59%) 48.2%,
      hsl(0, 0%, 11.83%) 53.5%,
      hsl(0, 0%, 11%) 58.5%,
      hsl(0, 0%, 10.13%) 63.4%,
      hsl(0, 0%, 9.22%) 68.3%,
      hsl(0, 0%, 8.32%) 73.5%,
      hsl(0, 0%, 7.46%) 79%,
      hsl(0, 0%, 6.72%) 85.2%,
      hsl(0, 0%, 6.2%) 92.1%,
      hsl(0, 0%, 6%) 100%
    );
    background-size: 300px 300px;
    background-repeat: repeat-x;
  }

  @supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
    .FixedGlobalNav-content {
      /* Easing gradient from: https://larsenwork.com/easing-gradients/ */
      background-image: linear-gradient(
        to bottom,
        hsl(0, 0%, 13%) 0%,
        hsl(0, 0%, 12.93%) 8.1%,
        hsl(0, 0%, 12.75%) 15.5%,
        hsl(0, 0%, 12.46%) 22.5%,
        hsl(0, 0%, 12.07%) 29%,
        hsl(0, 0%, 11.6%) 35.3%,
        hsl(0, 0%, 11.05%) 41.2%,
        hsl(0, 0%, 10.45%) 47.1%,
        hsl(0, 0%, 9.79%) 52.9%,
        hsl(0, 0%, 9.1%) 58.8%,
        hsl(0, 0%, 8.4%) 64.7%,
        hsl(0, 0%, 7.7%) 71%,
        hsl(0, 0%, 7.06%) 77.5%,
        hsl(0, 0%, 6.52%) 84.5%,
        hsl(0, 0%, 6.14%) 91.9%,
        hsl(0, 0%, 6%) 100%
      );
      background-size: 300px 300px;
      background-repeat: repeat-x;
    }
  }
}

.FixedGlobalNav {
  /* Push the page down to make space for the fixed bar */
  padding-top: var(--FixedGlobalNav-reservedSpace);
}

.FixedGlobalNav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background: var(--FixedGlobalNav-backgroundColor);
  z-index: 20000;
  transition: height 0.2s ease-out, box-shadow 0.3s ease-out, background 0.2s ease-in, backdrop-filter 0.2s ease-in;
}

@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
  .FixedGlobalNav-bar {
    background: var(--FixedGlobalNav-transparentBackgroundColor);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

.FixedGlobalNav-mainMenu {
  clip: rect(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: var(--FixedGlobalNav-menuReservedSpace);

  opacity: 0;
  background: transparent;

  transition: background 0.2s ease-in;

  overflow-y: auto;
}

.FixedGlobalNav-content {
  /* transition: filter 0.2s ease-in .2s, opacity 0.2s ease-in .2s; */
}

/* option is-scrolled */
.FixedGlobalNav.is-scrolled .FixedGlobalNav-bar {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

/* option is-menuShowing */
.FixedGlobalNav.is-menuShowing .FixedGlobalNav-mainMenu {
  clip: auto;
  opacity: 1.0;
  background: transparent;
  transition: background 0.3s ease-out;
  -webkit-overflow-scrolling: touch;
  z-index: 4500;
}

.FixedGlobalNav.is-menuShowing .FixedGlobalNav-bar {
  box-shadow: none;
  background: transparent;
  backdrop-filter: none;
}

.FixedGlobalNav.is-menuShowing .FixedGlobalNav-content {
  filter: blur(10px);
  opacity: 0.2;
  /* transition: filter 0.2s ease-out, opacity 0.2s ease-out; */
}

@media (--smallWidth) {
  .FixedGlobalNav {
    /* Push the page down to make space for the fixed bar */
    padding-top: var(--FixedGlobalNav-smallReservedSpace);
  }
}

@media (--smallHeight) {
  /* On small screens we always collapse the menu */
  .FixedGlobalNav {
    padding-top: var(--FixedGlobalNav-minReservedSpace);
  }
  .FixedGlobalNav-mainMenu {
    padding-top: var(--FixedGlobalNav-minReservedSpace);
  }
}
