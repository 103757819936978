/** @define ScheduleNavigation */

:root {
  --ScheduleNavigation-textColor: var(--regularTextColor);
  --ScheduleNavigation-selectedColor: var(--bffBrandBlue);
}

.ScheduleNavigation {
  display: flex;
  justify-content: space-between;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  color: var(--ScheduleNavigation-textColor);

  margin: 20px 0;
  max-width: 100%;
}

.ScheduleNavigation-pivots {
  display: flex;
}

.ScheduleNavigation-filters {
  position: relative;
  flex-grow: 100;
  overflow: hidden;
}

.ScheduleNavigation-subMenu {
  position: absolute;
  left: 0;
  right: -20px; /* adjust for -item margin */

  white-space: nowrap;
  overflow-x: scroll;

  transition: translate .7s ease-out, opacity .5s ease-out;
}

.ScheduleNavigation-item {
  display: inline-block;
  margin-right: 20px;
  white-space: nowrap;
  vertical-align: top;
}

.ScheduleNavigation-item a {
  vertical-align: top;
}

.ScheduleNavigation-selected {
  font-weight: var(--fontWeightBold);
  color: var(--ScheduleNavigation-selectedColor);
}

.ScheduleNavigation-calendarIcon,
.ScheduleNavigation-podcastIcon,
.ScheduleNavigation-genreIcon {
  font-size: 15px;
  vertical-align: text-top;
}

.ScheduleNavigation-genreIcon {
  transition: transform .3s ease-out;
  transform: rotate(180deg);
}

.ScheduleNavigation .ScheduleNavigation-dow {
  text-align: right;
  transform: translateX(0);
  opacity: 1;
}

.ScheduleNavigation .ScheduleNavigation-genre {
  transform: translateX(-200%);
  opacity: 0;
}

/** @modifier is-browsingGenre
  * Expand genre tags, hide days of week to make room */
.ScheduleNavigation.is-browsingGenre .ScheduleNavigation-dow {
  transform: translateX(200%);
  opacity: 0;
}

.ScheduleNavigation.is-browsingGenre .ScheduleNavigation-genre {
  transform: translateX(0);
  opacity: 1;
}

.ScheduleNavigation.is-browsingGenre .ScheduleNavigation-genreIcon {
  transform: rotate(90deg);
}

@media (--smallWidth) {
  .ScheduleNavigation-longName { display: none; }
  .ScheduleNavigation-item { margin-right: 10px; }
  .ScheduleNavigation-item:last-child { margin-right: 20px; }
}
