/* FormattedSectionDefault */

.FormattedSectionDefault {
  clear: both;
}

/* FormattedSectionLede - an example section for intro text */
.FormattedSectionLede {
  font-size: var(--fontSizeXL);
  line-height: var(--fontHeightXL);
  font-weight: var(--fontWeightLight);
}

.FormattedSectionLede--serif {
  font-family: Georgia, serif;
}
