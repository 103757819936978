/** @define Spark */
/* Spark graphs drawn with SVG */
:root {
  --Spark-lineColor: #000;
  --Spark-fillColor: rgba(255, 255, 255, 0.6);
  --Spark-highlightColor: #c00;
}

.Spark {}

.Spark-drawing {
  width: 100%;
  height: 100%;
}

.Spark-line {
  stroke: var(--Spark-lineColor);
  stroke-width: 3px;
  fill: none;
}

.Spark-fill {
  fill: var(--Spark-fillColor);
  stroke-width: 0;
}

.Spark-highlight {
  stroke: var(--Spark-highlightColor);
  stroke-width: 3px;
  fill: none;
}
