/* Style the Top30 blog post, using known mark-up patterns */

/* HACK: We don't have charts in native data, so instead we're reformatting the blog posts we use for
   charts based on their legacy content (italic and bold) in lists. It's... messy. */

.Top30 {}

.Top30-title {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
}

.Top30-image {
  width: 100%;
  margin-top: 20px;
}

/* Port in FeatureList styles */
.Top30-article {
  counter-reset: top30;
}

/* Don't show underlines on these links */
.Top30-article a {
  text-decoration: none;
}

/* Truncate the article after 10 items */
.Top30-article p:nth-child(11) ~ p {
  display: none;
}

/* Truncate Top5 variant after 5 items */
.Top30--top5 .Top30-article p:nth-child(6) ~ p {
  display: none;
}

.Top30-article p:nth-child(1) ~ p {
  list-style-position: none;
  margin-bottom: 10px;
  counter-increment: top30;
  color: transparent; /* Inline formatting is made invisible */
}

.Top30-article p:nth-child(1) ~ p::before {
  content: counter(top30) ". ";
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  color: var(--regularTextColor);
}

.Top30-article p:nth-child(1) ~ p b {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: normal;
  color: var(--regularTextColor);
  margin-left: -20px; /* overlap the “1.” in the text */
}

.Top30-article p:nth-child(1) ~ p i {
  display: block;
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  color: var(--accentTextColor);
}

.Top30-article p:nth-child(1) ~ p i ~ i {
  display: none;
}

.Top30-footer {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--bffBrandBlue);
}

@media (--smallWidth) {
  .Top30-article p:nth-child(1) ~ p::before,
  .Top30-article p:nth-child(1) ~ p b {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }

  .Top30-article p:nth-child(1) ~ p i {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
    font-weight: var(--fontWeightBold);
  }
}
