/** @define AuthorBlock */

:root {
  --AuthorBlock-avatarBorder: var(--lightGreyTextColor);
  --AuthorBlock-boldAuthorColor: var(--bffBrandRed);
}

.AuthorBlock {
  position: relative;
  padding-left: 42px;
  min-height: 32px;
  color: var(--Article-metadataColor);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AuthorBlock:link:hover,
.AuthorBlock:link:focus,
.AuthorBlock:link:active {
  text-decoration: none;
}

.AuthorBlock-image {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 2px solid var(--AuthorBlock-avatarBorder);
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -16px;
}

.AuthorBlock-name {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: uppercase;
}

.AuthorBlock:link:hover .AuthorBlock-name,
.AuthorBlock:link:focus .AuthorBlock-name,
.AuthorBlock:link:active .AuthorBlock-name {
  text-decoration: underline;
}

.AuthorBlock-caption {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: none;
}

.AuthorBlock--noImage {
  min-height: auto;
  padding-left: 0;
}

.AuthorBlock--boldAuthor .AuthorBlock-name {
  color: var(--AuthorBlock-boldAuthorColor);
  font-weight: var(--fontWeightThemeBold);
}
