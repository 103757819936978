/** @define GuestList */

:root {
  --GuestList-authorColor: var(--bffBrandBlue);
  --GuestList-metadataColor: var(--accentTextColor);
}

.GuestList-item {
  display: inline;
  font-weight: var(--fontWeightBold);
  color: var(--GuestList-authorColor);
}

.GuestList-item::before {
  content: ', ';
  font-weight: var(--fontWeightRegular);
  color: var(--GuestList-metadataColor);
}

.GuestList-item:last-child::before {
  content: ' & ';
  font-weight: var(--fontWeightRegular);
  color: var(--GuestList-metadataColor);
}

.GuestList-item:first-child::before {
  content: '';
}
