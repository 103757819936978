/* Standard font variants */

:root {
  --fontStack: FlamaWeb, system, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;

  --fontSizeBanner: 44px;
  --fontSizeTitle: 28px;
  --fontSizeXL: 24px;
  --fontSizeL: 18px;
  --fontSizeR: 16px;
  --fontSizeM: 14px;
  --fontSizeS: 12px;
  --fontSizeXS: 10px;

  --fontHeightBanner: 52px;
  --fontHeightTitle: 36px;
  --fontHeightXL: 32px;
  --fontHeightL: 26px;
  --fontHeightR: 24px;
  --fontHeightM: 22px;
  --fontHeightS: 20px;
  --fontHeightXS: 14px;

  --fontWeightLight: 300;
  --fontWeightRegular: normal;
  --fontWeightBold: bold;
  --fontWeightThemeBold: var(--fontWeightBold);
}

@media (prefers-color-scheme: dark) {
  :root {
    --fontWeightThemeBold: var(--fontWeightRegular);
  }
}
