/** @define DJProfile */

:root {
  --DJProfile-sidebarColor: var(--accentTextColor);
  --DJProfile-metadataColor: var(--accentTextColor);
}

.DJProfile {}

.DJProfile-breadcrumb {
  margin: 0 0 20px;
  color: var(--DJProfile-metadataColor);
}

.DJProfile-about {
  color: var(--DJProfile-sidebarColor);
  min-width: 280px;
  flex-basis: 320px;
}

.DJProfile-description {
  margin: 20px 0;
}

.DJProfile-broadcastPreview {
  margin-bottom: 20px;
}

.DJProfile-heading {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  text-transform: uppercase;
}

.DJProfile-contentHeading {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin: 0 0 20px;
}

.DJProfile-contentFooter {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: uppercase;
  margin: 0 0 40px;
}

.DJProfile-social {
  margin: 20px 0;
}

.DJProfile-socialIcons {
  margin: 20px 0;
}

@media (--smallWidth) {
  .DJProfile-heading {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }
  .DJProfile-contentHeading {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    font-weight: var(--fontWeightBold);
  }
  .DJProfile-contentFooter {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}
