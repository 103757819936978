/** @define WikiContributors */
.WikiContributors {
  --WikiContributors-avatarBorderColor: var(--accentBackground);
}

.WikiContributors-heading {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  margin-bottom: 10px;
}

.WikiContributors-description {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  margin-bottom: 20px;
}

.WikiContributors-userContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.WikiContributors-user {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  display: block;
}

.WikiContributors-userLink {
  display: flex;
  align-items: center;
  gap: 7px;
}

.WikiContributors-avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 2px;
  border: 2px solid var(--WikiContributors-avatarBorderColor);
}

.WikiContributors-userName {
  font-weight: var(--fontWeightBold);
}

@media (--smallWidth) {
  .WikiContributors-userContainer {
    gap: 10px;
  }

  .WikiContributors-heading {
    margin-bottom: 5px;
  }

  .WikiContributors-description {
    margin-bottom: 10px;
  }
}
