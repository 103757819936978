/** @define InfoBlock */

:root {
  --InfoBlock-backgroundColor: var(--regularBackground);
  --InfoBlock-descriptionColor: var(--accentTextColor);
  --InfoBlock-buttonBackground: var(--bffBrandYellow);
  --InfoBlock-buttonTextColor: var(--bffBrandBlack);
}

@media (prefers-color-scheme: dark) {
  :root {
    --InfoBlock-backgroundColor: var(--accentBackground);
  }
}


.InfoBlock {
  background: var(--InfoBlock-backgroundColor);
  padding: 20px 0;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.InfoBlock-title {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  margin-bottom: 10px;
}

.InfoBlock-description {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  color: var(--InfoBlock-descriptionColor);
  max-width: 424px;
  margin: 10px 0;
}

.InfoBlock-actionButton {
  display: block;
  background: var(--GetNewsletter-buttonBackground);
  color: var(--GetNewsletter-buttonTextColor);
  padding: 10px 20px;

  width: 100%;
  max-width: 230px;
  margin: 20px auto;
}
