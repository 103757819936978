/** @define Aspect */

.Aspect {
  position: relative;
}

.Aspect-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Aspect--168 {
  padding-bottom: 51.3%;
}
