/** @define PlayerMenu */

:root {
  --PlayerMenu-buttonColor: var(--playerTextColor);
  --PlayerMenu-menuBackgroundColor: var(--bffBrandBlue);
  --PlayerMenu-menuBorderColor: #49AEB7;
  --PlayerMenu-menuTextColor: var(--bffBrandBlack);
}

.PlayerMenu-menu {
  position: absolute;
  left: 0;
  bottom: 100%;

  /* HACK: We use 1px here as max() requires units, but literal 0px gets optimized back to 0 by the build process) */
  padding-left: max(1px, calc(env(safe-area-inset-left) - 20px));

  background: var(--PlayerMenu-menuBackgroundColor);
  color: var(--PlayerMenu-menuTextColor);

  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  transform: scale(0.3);

  max-height: calc(100vh - 100px);
  overflow-y: scroll;

  transition: transform .3s var(--transitionBounce), opacity .2s ease-out;
  transform-origin: 0 100%;

  border: 4px solid var(--PlayerMenu-menuBorderColor);
  border-width: 4px 4px 0 0;
  border-radius: 0 5px 0 0;
}

.PlayerMenu-menuGroup {
  margin: 20px;
}

.PlayerMenu-menuItem {
  margin: 5px;
}

.PlayerMenu-button {
  display: block;
  margin: 0 0 0 20px;
}

.PlayerMenu-button::before {
  content: "• • •";
  white-space: nowrap;
  color: var(--PlayerMenu-buttonColor);

  display: block;
  transform: rotate(90deg);
}

.PlayerMenu-buttonHighlight {
  opacity: 0;

  display: block;
  position: absolute;
  top: 0px;
  width: 30px;
  height: 20px;
  overflow: hidden;

  transform: translateX(-6px) scale(0.3);
  transform-origin: 50% 0;
  transition: opacity .2s ease-out, transform .2s ease-out;
}

.PlayerMenu-buttonHighlightInner {
  display: block;
  width: 10px;
  height: 10px;

  transform: translate(10px, -5px) rotate(45deg);
  background: var(--PlayerMenu-menuBackgroundColor);
}

.PlayerMenu-button:link:focus,
.PlayerMenu-button:link:hover {
  text-decoration: none;
}

.PlayerMenu-button:link:focus::before,
.PlayerMenu-button:link:hover::before,
.PlayerMenu.is-showingMenu .PlayerMenu-button::before {
  color: var(--PlayerMenu-menuBackgroundColor);
}

.PlayerMenu-button:hover .PlayerMenu-buttonHighlight,
.PlayerMenu-button:focus .PlayerMenu-buttonHighlight,
.PlayerMenu.is-showingMenu .PlayerMenu-buttonHighlight {
  opacity: 1.0;
  transform: translateX(-6px) scale(1);
  transition: opacity .1s ease-out, transform .2s ease-out .1s;
}

.PlayerMenu.is-showingMenu .PlayerMenu-menu {
  clip: auto;
  height: auto;
  width: 260px;

  transform: scale(1.0);
  opacity: 1;
}
