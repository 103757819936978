/** @define Slider */

:root {
  --Slider-barWidth: 4px;
  --Slider-borderColor: var(--bffBrandWhite);
  --Slider-barColor: var(--bffBrandBlue);
  --Slider-trackColor: rgba(0, 0, 0, .4);
}

.Slider {
  display: flex;
  align-items: center;
}

.Slider-up, .Slider-down {
  background: transparent;
  color: inherit;
  transition: background-color .3s ease-out;
  flex-shrink: 0;

  font-weight: bold;
  text-shadow: rgba(0,0,0,.7) 1px 1px 1px;
}

.Slider-up, .Slider-down {
  width: 40px;
  height: 40px;
  border-radius: 3px;
}

.Slider-up:hover, .Slider-down:hover {
  border: 1px solid var(--Slider-borderColor);
}

.Slider-up:focus, .Slider-down:focus {
  border: 1px solid var(--Slider-borderColor);
  background: rgba(255, 255, 255, 0.5);
}

.Slider-up:active, .Slider-down:active {
  background: rgba(255, 255, 255, 0.75);
}

.Slider-up {
  order: 3;
}
.Slider-down {
  order: 1;
}

.Slider-bar {
  order: 2;
  min-width: 100px;
  margin: 0 5px;
  flex-shrink: 10;
  flex-grow: 100;
  height: var(--Slider-barWidth);
  border-radius: calc(var(--Slider-barWidth) / 2);
  background: var(--Slider-trackColor);
  box-shadow: rgba(0,0,0,.7) 1px 1px 1px
}

.Slider-fill {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 50%;
  background: var(--Slider-barColor);
  border-radius: calc(var(--Slider-barWidth) / 2);

  transition: width .2s ease-out;
}


