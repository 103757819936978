/** @define Label */

:root {
  --Label-backgroundColor: var(--accentBackground);
  --Label-textColor: var(--brightTextColor);
}

.Label {
  display: inline-block;
  background: var(--Label-backgroundColor);
  color: var(--Label-textColor);
  border-radius: 2px;
  font-size: var(--fontSizeS);
  line-height: var(--fontSizeS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  padding: 6px 16px;
  margin: 0 6px 0 0;
}

.Label--small {
  padding: 3px 8px;
  margin: 0 3px 0 0;
  font-size: var(--fontSizeXS);
  line-height: var(--fontSizeXS);
}

.Label--blue {
  background: var(--bffBrandBlue);
  color: var(--bffBrandBlack);
}

.Label--red {
  background: var(--bffBrandRed);
  color: var(--bffBrandWhite);
}

.Label--yellow {
  background: var(--bffBrandYellow);
  color: var(--bffBrandBlack);
}
