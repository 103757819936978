/** @define Transcript */

:root {
  --Transcript-offsetBackground: var(--accentBackground);
  --Transcript-offsetTextColor: var(--accentTextColor);
  --Transcript-speakerColor: var(--bffBrandRed);
  --Transcript-timestampIndent: 20px;
}

.Transcript {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

.Transcript-heading {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin: 0 0 20px;
}

.Transcript-sectionHeading {
  font-weight: var(--fontWeightBold);
  margin: 0 0 20px;
}

.Transcript-annotation {
  margin: 10px 0;
  padding: 20px 0 20px 20px;
  background: var(--Transcript-offsetBackground);
  font-style: italic;
}

.Transcript-timestamp {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  font-style: normal;
  color: var(--Transcript-offsetTextColor);
  float: right;
  margin: 0 20px 5px 5px;
}

.Transcript-dialog {
  margin: 15px 0;
  display: flex;
}

.Transcript-dialogContent {
  flex-grow: 100;
  flex-shrink: 1;
}

.Transcript-speaker {
  font-weight: var(--fontWeightBold);
  color: var(--Transcript-speakerColor);
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
}

.Transcript-speech {
  margin: 0 0 15px;
  max-width: 100%;
}

@media (prefers-color-scheme: dark) {
  .Transcript-speaker {
    font-weight: var(--fontWeightRegular);
  }
}
