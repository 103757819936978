/** @define PostsNavigation */

:root {
  --PostsNavigation-textColor: var(--accentTextColor);
  --PostsNavigation-selectedColor: var(--bffBrandBlue);
}

.PostsNavigation {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  color: var(--PostsNavigation-textColor);

  max-width: 100%;

  white-space: nowrap;
  overflow-x: scroll;
}

.PostsNavigation-item {
  display: inline-block;
  margin-right: 20px;
  white-space: nowrap;
  vertical-align: top;
}

.PostsNavigation-itemIcon {
  font-size: var(--fontSizeR);
  vertical-align: text-top;
  margin-right: 4px;
}

.PostsNavigation-selected {
  font-weight: var(--fontWeightBold);
  color: var(--PostsNavigation-selectedColor);
}

@supports (-webkit-overflow-scrolling: touch) {
  .PostsNavigation {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
