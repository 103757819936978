/** @define DeployTab  */

.DeployTab {
  position: fixed;
  top: 0;
  right: 10px;

  display: inline-block;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);

  background: #000000 repeating-linear-gradient(135deg, #ed9017, #e8caa7 10px, #666 10px, #333 20px);
  color: #fff;
  text-shadow: #000 1px 1px 3px;

  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;

  z-index: 40000;
}

.DeployTab dt {
  display: none;
}

.DeployTab dd {
  display: inline;
}

.DeployTab dd ~ dd::before {
  content: " / ";
}
