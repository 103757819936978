/** @define LinkBlock
 *
 * Simple component for conventional line underlining inside of more complex
 * visual components
 */

.LinkBlock:link:hover, .LinkBlock:link:focus {
  text-decoration: none;
}

.LinkBlock-text {}

.LinkBlock:link:hover .LinkBlock-text,
.LinkBlock:link:focus .LinkBlock-text {
  text-decoration: underline;
}