/** @define ProfileHeader */
:root {
  --ProfileHeader-backgroundColor: var(--accentBackground);
  --ProfileHeader-textColor: var(--bffBrandWhite);
  --ProfileHeader-avatarBorderColor: var(--regularBackground);
}

@media (prefers-color-scheme: dark) {
  :root {
    --ProfileHeader-avatarBorderColor: var(--accentBackground);
  }
}

.ProfileHeader {
  background-color: var(--ProfileHeader-backgroundColor);
  color: var(--ProfileHeader-textColor);
  padding: 20px;

  position: relative;
  overflow: hidden;
}

.ProfileHeader-backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ProfileHeader-backgroundMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Easing gradient, generated using https://larsenwork.com/easing-gradients/
     There's a PostCSS plugin for this, but it requires upgrading to v7 first */
  background-image: linear-gradient(0deg,
    hsla(0, 0%, 0%, 0.5) 0%,
    hsla(0, 0%, 0%, 0.494) 7.6%,
    hsla(0, 0%, 0%, 0.476) 14.9%,
    hsla(0, 0%, 0%, 0.448) 21.9%,
    hsla(0, 0%, 0%, 0.412) 28.7%,
    hsla(0, 0%, 0%, 0.37) 35.3%,
    hsla(0, 0%, 0%, 0.324) 41.7%,
    hsla(0, 0%, 0%, 0.275) 48%,
    hsla(0, 0%, 0%, 0.225) 54.2%,
    hsla(0, 0%, 0%, 0.176) 60.5%,
    hsla(0, 0%, 0%, 0.13) 66.7%,
    hsla(0, 0%, 0%, 0.088) 73.1%,
    hsla(0, 0%, 0%, 0.052) 79.5%,
    hsla(0, 0%, 0%, 0.024) 86.1%,
    hsla(0, 0%, 0%, 0.006) 92.9%,
    hsla(0, 0%, 0%, 0) 100%);
}

.ProfileHeader-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 100;

  min-height: 370px;
}

.ProfileHeader-image {
  background: var(--ProfileHeader-avatarBorderColor);
  border: 6px var(--ProfileHeader-avatarBorderColor) solid;
  width: 240px;
  height: 240px;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.45);
}

.ProfileHeader-summary {
  margin-left: 50px;
}

.ProfileHeader-headline {
  font-size: var(--fontSizeTitle);
  line-height: var(--fontHeightTitle);
  text-shadow: rgba(0,0,0,0.5) 0 1px 2px;
}

.ProfileHeader-subhead {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  text-shadow: rgba(0,0,0,0.5) 0 1px 2px;
}

.ProfileHeader-appendedContent {
  margin-top: 10px;
}

@media (--smallWidth) {
  .ProfileHeader-image {
    width: 164px;
    height: 164px;
  }

  .ProfileHeader-content {
    flex-direction: column;
    min-height: 0;
  }

  .ProfileHeader-summary {
    margin: 20px 10px 0;
    width: 100%;
    text-align: center;
  }

  .ProfileHeader-headline {
    font-size: var(--fontSizeL);
    line-height: var(--fontHeightL);
    font-weight: var(--fontWeightBold);
  }

  .ProfileHeader-subhead {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .ProfileHeader-appendedContent {
    margin-top: 20px;
  }
}
