/** @define Snap */
/* Standards-based scroll snap */

.Snap {
  overflow-x: scroll;
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
}

.Snap-item {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
