:root {
  /* Dimensions */
  --contentMaxWidth: 1220px;
  --contentStandardPadding: 20px;
}

/* Breakpoints */
@custom-media --fullBleed (max-width: 1220px);
@custom-media --mediumWidth (max-width: 960px);
@custom-media --midiWidth (max-width: 750px);
@custom-media --smallWidth (max-width: 480px);
@custom-media --smallHeight (max-height: 450px);
