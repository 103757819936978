/* FormattedText */

:root {
  --FormattedText-linkColor: var(--bffBrandBlue);
  --FormattedText-quoteColor: var(--accentTextColor);
  --FormattedText-pullQuoteColor: var(--bffBrandBlue);
  --FormattedText-horizontalRuleColor: var(--bffBrandBlue);
  --FormattedText-horizontalRuleBackgroundColor: var(--regularBackground);
  --FormattedText-buttonLinkBackgroundColor: var(--bffBrandYellow);
  --FormattedText-buttonLinkTextColor: var(--bffBrandBlack);
  --FormattedText-imageBorder: var(--accentBackground);
  --FormattedText-accentText: var(--accentTextColor);
  --FormattedText-margin: 25px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --FormattedText-imageBorder: var(--accentBackground);
  }
}

.FormattedText {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

.FormattedText::after {
  content: '';
  display: table;
  clear: both;
}

/* Block Elements */

.FormattedText h1 {
  font-size: var(--fontSizeXL);
  line-height: var(--fontHeightXL);
  font-weight: var(--fontWeightBold);
  margin-bottom: var(--FormattedText-margin);
}

.FormattedText h2 {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin-bottom: var(--FormattedText-margin);
}

.FormattedText h3 {
  font-size: 100%;
  font-weight: var(--fontWeightBold);
  margin-bottom: var(--FormattedText-margin);
}

.FormattedText dl {
  margin: var(--FormattedText-margin) 0;
}

.FormattedText dd {
  margin-left: var(--FormattedText-margin);
}

.FormattedText ul,
.FormattedText ol {
  counter-reset: list;
  margin: var(--FormattedText-margin) 0 var(--FormattedText-margin) var(--FormattedText-margin);
}

.FormattedText li,
.FormattedText dt,
.FormattedText dd {
  margin-bottom: 5px;
}

.FormattedText ul li::before {
  content: '·';
  display:inline-block;
  margin-left: calc(-1 * var(--FormattedText-margin));
  width: var(--FormattedText-margin);
}

.FormattedText ol li::before {
  counter-increment: list;
  content: counters(list, ".") ". ";
  display: inline-block;
  margin-left: calc(-1 * var(--FormattedText-margin));
  width: var(--FormattedText-margin);
  font-size: 85%;
}

.FormattedText dt {
  font-weight: var(--fontWeightBold);
}

/* Regular Quotes */
.FormattedText blockquote {
  margin-bottom: var(--FormattedText-margin);
  font-style: italic;
}

/* Fancy quotes */
/* HACK: Ideally we'd have a proper class for this but blockquite > strong is distinct enough for now. */
.FormattedText blockquote strong,
.FormattedText blockquote.decorative-pull-quote {
  margin: 0 var(--FormattedText-margin) var(--FormattedText-margin) 0;
  float: left;
  width: 50%;
  display: block;

  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-size: 24px;
  line-height: 32px;
  color: var(--FormattedText-pullQuoteColor);
  font-weight: var(--fontWeightLight);
  font-style: italic;
}

/* Phrase Elements */

.FormattedText a {
  text-decoration: underline;

  /* handle aggressive overlows, via: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

.FormattedText a:link,
.FormattedText a:visited {
}

.FormattedText a:active,
.FormattedText a:hover,
.FormattedText a:focus {
  color: var(--FormattedText-linkColor);
}

.FormattedText p {
  margin-bottom: var(--FormattedText-margin);
}

.FormattedText b,
.FormattedText strong {
  font-weight: bold;
}

.FormattedText i,
.FormattedText em {
  font-style: italic;
}

/* Stylistic Elements */

/* Decorative Horizontal Rules */
.FormattedText hr {
  height: 1px;
  width: 50%;
  display: block;
  background: var(--FormattedText-horizontalRuleColor);
  border: none;
  margin: calc(var(--FormattedText-margin) * 2) auto;
  text-align: center;
}

.FormattedText hr::before {
  content: '✿';
  display: inline-block;
  background: var(--FormattedText-horizontalRuleBackgroundColor);
  top: -0.5em;
  position: relative;
  font-size: 24px;
  padding: 0 .3em;
  color: var(--FormattedText-horizontalRuleColor);
}

/* Media elements */
.FormattedText iframe,
.FormattedText img,
.FormattedText audio,
.FormattedText video,
.FormattedText figure:not(.FormattedText-noFrame) {
  display: inline-block;
  max-width: 100%;
  margin-bottom: var(--FormattedText-margin);
  border: 6px solid var(--FormattedText-imageBorder);
  background: var(--FormattedText-imageBorder);
  transform: translate(-6px);
}

.FormattedText figure audio,
.FormattedText figure video,
.FormattedText figure iframe,
.FormattedText figure img {
  display: block;
  border: none;
  background: none;
  transform: none;
  margin: 0;
}

/* HACK: Force <iframe> to expand the <figure> container (constrained by 'max-width: 100%' above */
.FormattedText iframe {
  width: 3000px;
}

.FormattedText figcaption {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  padding: 15px 10px;
}

.FormattedText p iframe,
.FormattedText p img {
  margin-bottom: 0;
}

/* Use “original” mode to disable photo styles */
.FormattedText .media-size-original img {
  border: none;
  box-shadow: none;
}

/* Button-style Links
 * <p><b><i><a …>Buy Now</a></i></b></p>
 * <p><i><b><a …>Buy Now</a></b></i></p>
 *
 * c.f. SimpleButton.css
 */
.FormattedText p > a > b > i,
.FormattedText p > a > i > b,
.FormattedText p > i > b > a:only-child,
.FormattedText p > b > i > a:only-child,
.FormattedText a.decorative-button-link {
  padding: 10px 30px;
  display: inline-block;

  background: var(--FormattedText-buttonLinkBackgroundColor);
  color: var(--FormattedText-buttonLinkTextColor);

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;

  text-decoration: none;

  text-align: center;
  cursor: pointer;
}

.FormattedText p > a:hover > b > i,
.FormattedText p > a:hover > i > b,
.FormattedText p > i > b > a:only-child:hover,
.FormattedText p > b > i > a:only-child:hover,
.FormattedText p > i > b > a:only-child:focus,
.FormattedText p > b > i > a:only-child:focus,
.FormattedText p > a:focus > b > i,
.FormattedText p > a:focus > i > b,
.FormattedText a.decorative-button-link:hover,
.FormattedText a.decorative-button-link:focus {
  text-decoration: underline;
}

/* @variant --condensed — smaller margins between text elements, for cases where maybe body-text is lower-priority been shrunk */
.FormattedText--condensed p,
.FormattedText--condensed ul,
.FormattedText--condensed ol,
.FormattedText--condensed dl,
.FormattedText--condensed blockquote,
.FormattedText--condensed h1,
.FormattedText--condensed h2,
.FormattedText--condensed h3 {
  margin-bottom: calc(var(--FormattedText-margin) / 2);
}

/* @variant --nested — Where formatted text is nested within some other content, remove certain indent/shape styles */
.FormattedText--nested dl,
.FormattedText--nested ol,
.FormattedText--nested ul {
  margin: calc(var(--FormattedText-margin) / 2) 0;
}

.FormattedText--nested li {
  color: var(--FormattedText-accentText);
}

.FormattedText--nested ul li::before {
  display: none;
}

.FormattedText--nested ol li::before {
  margin-left: 0;
  text-align: left;
}

.FormattedText--nested iframe,
.FormattedText--nested img,
.FormattedText--nested audio,
.FormattedText--nested video,
.FormattedText--nested figure:not(.FormattedText-noFrame) {
  border: none;
  transform: none;
  margin-bottom: calc(var(--FormattedText-margin) / 2);
}

.FormattedText--nested h1,
.FormattedText--nested h2 {
  margin-bottom: calc(var(--FormattedText-margin) / 2);
}

/* @variant --wrapped — Enforce a readable line-length on body text content */
.FormattedText--wrapped h1,
.FormattedText--wrapped h2,
.FormattedText--wrapped h3,
.FormattedText--wrapped p,
.FormattedText--wrapped dl,
.FormattedText--wrapped ol,
.FormattedText--wrapped ul,
.FormattedText--wrapped blockquote,
.FormattedText--wrapped figcaption {
  max-width: 45em;
}

@media (--smallWidth) {
  .FormattedText {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }

  .FormattedText h1 {
    font-size: var(--fontSizeL);
    line-height: var(--fontHeightL);
  }

  .FormattedText h2 {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
  }

  .FormattedText figcaption {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
    padding: 10px 10px;
  }

  .FormattedText blockquote strong,
  .FormattedText blockquote.decorative-pull-quote {
    width: 100%;
    float: none;
    margin: var(--FormattedText-margin) 0;
  }
}
