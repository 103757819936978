/** @define IndexHeader */

:root {
  --IndexHeader-desc: var(--accentTextColor);
}

.IndexHeader {}

.IndexHeader-title {
  font-size: var(--fontSizeTitle);
  line-height: var(--fontHeightTitle);
  font-weight: var(--fontWeightBold);
  margin: 0 0 20px;
}

.IndexHeader-description {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 0 0 20px;
  color: var(--IndexHeader-desc);
}

.IndexHeader-filtering {
  margin: 0 0 20px;
}

.IndexHeader-smallHeader {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  margin: 0 0 5px;
}
