/** @define AddFeed */
:root {
  --AddFeed-buttonTextColor: var(--regularTextColor);
}

.AddFeed-subscribeButton {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--AddFeed-buttonTextColor);
  cursor: pointer;
  white-space: nowrap;
}

.AddFeed-subscribeButton:hover .AddFeed-label,
.AddFeed-subscribeButton:focus .AddFeed-label {
  text-decoration: underline;
}

.AddFeed-icon {
  margin-right: 5px;
}

.AddFeed-label {
  vertical-align: text-bottom;
}

.AddFeed-dialog {
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  opacity: 0;
}

.AddFeed.is-subscribing .AddFeed-dialog {
  clip: auto;
  width: auto;
  height: auto;

  transform: scale(1.0);
  opacity: 1;
}
