/** @define MusicPlayer */

:root {
  --MusicPlayer-maxWidth: 100%;
  --MusicPlayer-horizontalPadding: 20px;
  --MusicPlayer-verticalPadding: 0;
  --MusicPlayer-height: 85px;
  --MusicPlayer-borderColor: rgba(255, 255, 255, .5);
  --MusicPlayer-switcherColor: var(--bffBrandTrueBlack);
  --MusicPlayer-switcherText: var(--onColorTextColor);
}

.MusicPlayer {
  position: relative;

  box-sizing: border-box;
  margin: 0 auto;
  padding: var(--MusicPlayer-verticalPadding) 0;
  padding: var(--MusicPlayer-verticalPadding) 0 env(safe-area-inset-bottom);
  transition: padding-bottom .3s ease; /* Eases the footer grow/shrink when iOS Safari toolbar show/hides */

  width: 100%;
  max-width: var(--MusicPlayer-maxWidth);

  line-height: 1.6;
}

.MusicPlayer-contextualBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  opacity: .70;
}

/* Assume a 200px height image */
.MusicPlayer-backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity .4s ease;
}

.MusicPlayer-liveBackground {
  opacity: 1;
}

.MusicPlayer[data-focus="archive"] .MusicPlayer-liveBackground,
.MusicPlayer[data-focus="page"] .MusicPlayer-liveBackground {
  opacity: 0;
}

.MusicPlayer[data-focus="archive"] .MusicPlayer-archiveBackground {
  opacity: 1;
}

.MusicPlayer[data-focus="page"] .MusicPlayer-pageBackground {
  opacity: 1;
}
/* Flex container for the horizonal controls */
.MusicPlayer-activePlayerBar {
  display: flex;
  align-items: center;
  align-content: center;
  height: var(--MusicPlayer-height);
  padding: 0 var(--MusicPlayer-horizontalPadding);
}

.MusicPlayer-playButton {
  order: 0;
}

.MusicPlayer-metadata {
  order: 1;
  height: 100%;
  flex-grow: 100;
  flex-shrink: 10;

  margin-left: var(--MusicPlayer-horizontalPadding);
  border-left: 1px var(--MusicPlayer-borderColor) solid;
  padding-left: var(--MusicPlayer-horizontalPadding);

  text-shadow: rgba(0, 0, 0, .8) 1px 1px 1px;
}

.MusicPlayer-metadataInner {
  position: relative;
  display: flex;
  align-items: center;
  align-content: stretch;
  height: var(--MusicPlayer-height);
}

.MusicPlayer-metadataPane {
  min-width: 0; /* Ensure that text can truncate */
  flex-grow: 80;
  transition: transform .5s ease-out, opacity .5s ease-out;
}

.MusicPlayer-volumeControl {
  order: 5;
  margin-left: var(--MusicPlayer-horizontalPadding);
  display: flex;
  z-index: 10; /* Bring above background mask */
}

.MusicPlayer-volumeLabel {
  display: none;
  font-size: 32px;
}

.MusicPlayer-liveRadioSwitcher {
  padding: 0 10px;
  padding: 0 10px;
  transform: padding-bottom .3s ease;
  background: var(--MusicPlayer-switcherColor);
  color: var(--MusicPlayer-switcherText);

  position: relative;
  overflow: hidden;
  height: 0;
  transition: height .3s linear;
}

/* When the live panel is shown, remove the safe area padding from the main component */
.MusicPlayer[data-focus="archive"],
.MusicPlayer[data-focus="page"] {
  padding: var(--MusicPlayer-verticalPadding) 0 0;
}

/* Safe area padding is now applied to the live ticker, so that it pull blocks out the background image  */
.MusicPlayer[data-focus="archive"] .MusicPlayer-liveRadioSwitcher,
.MusicPlayer[data-focus="page"] .MusicPlayer-liveRadioSwitcher {
  padding: 5px 10px 10px;
  padding: 5px 10px calc(10px + env(safe-area-inset-bottom));
  height: 42px;
}

.MusicPlayer[data-focus="archive"] .MusicPlayer-liveRadioSwitcher,
.MusicPlayer[data-focus="page"] .MusicPlayer-liveRadioSwitcher {

}

/** @state playingLiveStream/default */
.MusicPlayer-liveMode {
  display: block;
}

.MusicPlayer-archiveMode,
.MusicPlayer-pageMode {
  display: none;
}

/** @state playingArchive */
.MusicPlayer[data-focus="archive"] .MusicPlayer-liveMode,
.MusicPlayer[data-focus="archive"] .MusicPlayer-pageMode {
  display: none;
}
.MusicPlayer[data-focus="archive"] .MusicPlayer-archiveMode {
  display: initial;
}

/** @state showingPage */
.MusicPlayer[data-focus="page"] .MusicPlayer-liveMode,
.MusicPlayer[data-focus="page"] .MusicPlayer-archiveMode,
.MusicPlayer[data-focus="page"] .MusicPlayer-playbackMode {
  display: none;
}
.MusicPlayer[data-focus="page"] .MusicPlayer-pageMode {
  display: block;
}

@media (--midiWidth) {
  .MusicPlayer-volumeLabel {
    display: block;
  }

  .MusicPlayer-volumeSlider {
    position: absolute;
    opacity: 0;
    transform: rotateX(90deg);
    transition: transform .3s ease-out, opacity .3s ease-out;
    left: 80px;
    top: 0px;
    right: 50px;
    height: var(--MusicPlayer-height);
  }

  .MusicPlayer-metadata {
    transform: rotateX(0deg) translate(0, 0);
    transition: transform .3s ease-out, opacity .3s ease-out;
  }

  /** @state volumeFocused
    * Applies at small widths to have the volume control take over most of the bar
    */

  /* When showing volume at narrow width, move the track info out of sight */
  .MusicPlayer.is-volumeFocused .MusicPlayer-metadata {
    transform: rotateX(90deg) translate(0, -55%);
    opacity: 0;
  }

  .MusicPlayer.is-volumeFocused .MusicPlayer-moreControls {
    transition: transform .3s ease-out .3s, opacity .3s ease-out .3s;
    transform: translate(-210%, 0);
    opacity: 0;;
  }

  .MusicPlayer.is-volumeFocused .MusicPlayer-volumeSlider {
    opacity: 1;
    transform: rotateX(0);
    transition: transform .3s ease-out .3s, opacity .3s ease-out .3s;
  }
}

@media (--smallWidth) {
  .MusicPlayer-metadata {
    margin-left: calc(var(--MusicPlayer-horizontalPadding) / 2);
    border-left: 1px var(--MusicPlayer-borderColor) solid;
    padding-left: calc(var(--MusicPlayer-horizontalPadding) / 2);
  }

  /* When small, track info panes are stacked and alternate using ContentCycle */
  .MusicPlayer-metadataPane {
    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    height: 100%;

    transform: rotateX(90deg) translate(0, -55%);
    opacity: 0;
  }

  /* Dependency: ContentCycle-item */
  .MusicPlayer-metadataPane.is-active {
    transform: rotateX(0) translate(0, 0);
    opacity: 1;
  }
}

