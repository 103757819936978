/** @define SupportShare */

:root {
  --SupportShare-donateButtonBackground: var(--accentBackground);
  --SupportShare-donateButtonText: var(--regularTextColor);
}

.SupportShare {
  margin: 20px 0 30px;
  display: flex;
  justify-content: space-between;
}

.SupportShare-donate {
  width: 100%;
  background: var(--SupportShare-donateButtonBackground);
  color: var(--SupportShare-donateButtonText);
  border-color: var(--SupportShare-donateButtonBackground);
  transition: transform .1s linear;
}

.SupportShare-donate:active {
  transform: scale(.9);
}
