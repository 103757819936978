/* @define TabLink */

:root {
  --TabLink-hoverColor: var(--accentBackground);
  --TabLink-selectColor: var(--bffBrandBlue);
}

.TabLink {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: uppercase;
  display: block;
  padding: 15px;
  border-bottom: 4px solid transparent;
  text-decoration: none;
}

.TabLink:link:hover,
.TabLink:link:focus,
.TabLink:link:active {
  text-decoration: none;
}

.TabLink:hover,
.TabLink:focus {
  border-color: var(--TabLink-hoverColor);
}

.TabLink.is-tabSelected,
.TabLink.is-tabSelected:hover,
.TabLink.is-tabSelected:focus {
  font-weight: var(--fontWeightBold);
  border-color: var(--TabLink-selectColor);
}
