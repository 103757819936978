/** @define RadioBlock */

:root {
  --RadioBlock-selectedColor: var(--bffBrandBlue);
  --RadioBlock-selectedTextColor: var(--onColorTextColor);
}

/*
 * 1. Position relative ensures we can keep the <input> in the flow and focusable, without interfering with label design
 */
.RadioBlock {
  display: inline-block;
  position: relative; /* 1 */
}

.RadioBlock-input {
  position: absolute; /* Out of flow */
  opacity: 0; /* Focusable, invisible */
}

.RadioBlock-label {
  display: block;
  padding: 20px;
  border: 4px solid transparent;
  text-align: center;
  cursor: pointer;

  transition: background-color .3s ease-out, border-color .3s ease-out;
}

.RadioBlock-input:checked + .RadioBlock-label {
  border-color: transparent;
  background: var(--RadioBlock-selectedColor);
  color: var(--RadioBlock-selectedTextColor);
}

.RadioBlock-input + .RadioBlock-label:hover,
.RadioBlock-input:focus + .RadioBlock-label {
  border-color: var(--RadioBlock-selectedColor);
}
