
:root {
  --EditPageLink-background: var(--bffBrandRed);
  --EditPageLink-textColor: var(--onColorTextColor);
}

.EditPageLink {
  display: none;

  background: var(--EditPageLink-background);
  color: var(--EditPageLink-textColor);

  position: fixed;
  right: 0;
  bottom: 180px;
  padding: 10px;

  border-radius: 4px 0 0 4px;

  z-index: 20000;
}

.EditPageLink-link {
  display: inline-flex;
  transition: padding .3s ease-out;

  align-items: center;
  gap: 5px;
}

.EditPageLink-link:hover,
.EditPageLink-link:focus {
  padding: 0 10px;
}

.EditPageLink-icon {
  font-size: 25px;
  line-height: 25px;
}

.EditPageLink-label {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
}

/** @state is-authed: Display the link if the user has permission */
.EditPageLink.is-authed {
  display: block;
}
