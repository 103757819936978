/** @define Breadcrumb */

:root {
  --Breadcrumb-highlightColor: var(--bffBrandBlue);
}

.Breadcrumb {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
}

.Breadcrumb-crumb {
  display: inline-block;
  margin: 0 5px 0 0;
}

.Breadcrumb-crumb::after {
  content: '/';
  margin: 0 5px;
  color: var(--Breadcrumb-highlightColor);
}

.Breadcrumb-crumb:last-child {
  color: var(--Breadcrumb-highlightColor);
  margin: 0;
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
}

.Breadcrumb-crumb:last-child::after {
  content: none;
  margin: 0;
}

@media (--smallWidth) {
  /* Don't show breadcrumbs on small screens */
  .Breadcrumb {
    display: none;
  }
}
