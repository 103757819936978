/** @define SourceInfo */

:root {
  --SourceInfo-onAirTextColor: var(--bffBrandRed);
  --SourceInfo-artBackgroundColor: var(--accentBackground);
}

.SourceInfo {
  text-align: right;
  margin: 0 105px 0 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SourceInfo-title {
  order: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SourceInfo-caption {
  order: 0;

  display: block;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SourceInfo--live .SourceInfo-caption {
  display: block;
  color: var(--SourceInfo-onAirTextColor);
}

.SourceInfo-art {
  position: absolute;
  right: 0;
  top: 0;
  height:100%;
  background: var(--SourceInfo-artBackgroundColor);
  flex-shrink: 0;
  transition: opacity .3s ease-out;
}

.SourceInfo-artImage {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.SourceInfo.is-missingImage {
  margin: 0;
}

.SourceInfo.is-missingImage .SourceInfo-art {
  opacity: 0;
}

@media (--smallWidth) {
  .SourceInfo {
    text-align: left;
  }

  .SourceInfo {
    text-align: right;
    margin: 0;
  }

  .SourceInfo-art {
    display: none;
  }
}
