/** @define PlaybackStatsBlock */

:root {
  --PlaybackStatsBlock-graphLine: var(--bffBrandRed);
  --PlaybackStatsBlock-graphFill: var(--bffBrandRedAlpha);
}

.PlaybackStatsBlock {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.PlaybackStatsBlock-number {
  text-align: center;
}

.PlaybackStatsBlock-numberStat {
  font-size: 60px;
  line-height: 60px;
  font-weight: var(--fontWeightBold);
}

.PlaybackStatsBlock-numberLabel {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
}

.PlaybackStatsBlock-graph {
  width: 275px;
  height: 100px;
  --Spark-lineColor: var(--PlaybackStatsBlock-graphLine);
  --Spark-fillColor: var(--PlaybackStatsBlock-graphFill);
  --Spark-highlightColor: var(--PlaybackStatsBlock-graphLine);
}

/* Fade out the background when in highlight mode */
.PlaybackStatsBlock--highlighted .PlaybackStatsBlock-graph {
  --Spark-lineColor: var(--PlaybackStatsBlock-graphFill);
}

@media (--smallWidth) {
  .PlaybackStatsBlock {
    gap: 10px;
  }

  .PlaybackStatsBlock-numberStat {
    font-size: 40px;
    line-height: 40px;
  }

  .PlaybackStatsBlock-numberLabel {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .PlaybackStatsBlock-graph {
    width: 200px;
    height: 80px;
  }
}
