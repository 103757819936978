/** @define TabSwitcher */

.TabSwitcher {
  display: flex;
  flex-direction: row;
  align-content: stretch;
}

.TabSwitcher-tab {
  text-align: center;
  flex-grow: 1;
}
