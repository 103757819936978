/** @define LiveStreamContextBar */

.LiveStreamContextBar {
  text-align: center;
}

.LiveStreamContextBar-icon {
  font-size: 24px;
}

.LiveStreamContextBar-text {
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;

  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.LiveStreamContextBar.is-playing .LiveStreamContextBar-playingText {
  display: inline;
}

.LiveStreamContextBar-playingText,
.LiveStreamContextBar.is-playing .LiveStreamContextBar-idleText {
  display: none;
}

.LiveStreamContextBar-showInfo.is-empty {
  display: none;
}
