/** @define Columns */
.Columns {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
}

.Columns-body {
  order: 1;
  flex-grow: 1;
}

.Columns-sidebar {
  order: 2;
  min-width: 100px; /* Ensure text truncates and wraps */
}

/* @variant equalSplit - equal size both columns */
.Columns--equalSplit .Columns-body,
.Columns--equalSplit .Columns-sidebar {
  flex-basis: calc(50% - 10px);
  flex-shrink: 50;
  min-width: calc(50% - 10px);;
}

/* @variant noGap - no padding between columns */
.Columns--noGap {
  gap: 0;
}

.Columns--alignMiddle {
  align-items: center;
}

.Columns--alignFoot {
  align-items: baseline;
}

@media (--mediumWidth) {
  .Columns--mediumCollapse {
    display: block;
  }

  .Columns--mediumCollapse .Columns-body {
    width: 100%;
    margin-bottom: 20px;
  }

  .Columns--noGap .Columns-body {
    margin-bottom: 0px;
  }

  .Columns--mediumCollapse .Columns-sidebar {
    width: 100%;
  }

  /** @variant tabbedCollapse Enable tab-switching between columns  */
  .Columns--mediumCollapse.Columns--tabbedCollapse .Columns-body,
  .Columns--mediumCollapse.Columns--tabbedCollapse .Columns-sidebar {
    display: none;
  }
  .Columns--mediumCollapse.Columns--tabbedCollapse .is-activeTab {
    display: block;
  }
}

@media (--midiWidth) {
  .Columns {
    gap: 10px;
  }

  .Columns--noGap {
    gap: 0;
  }

  .Columns--midiCollapse {
    display: block;
  }

  .Columns--midiCollapse .Columns-body {
    width: 100%;
    margin-bottom: 20px;
  }

  .Columns--noGap .Columns-body {
    margin-bottom: 0px;
  }

  .Columns--midiCollapse .Columns-sidebar {
    width: 100%;
  }

  /** @variant tabbedCollapse Enable tab-switching between columns  */
  .Columns--midiCollapse.Columns--tabbedCollapse .Columns-body,
  .Columns--midiCollapse.Columns--tabbedCollapse .Columns-sidebar {
    display: none;
  }
  .Columns--midiCollapse.Columns--tabbedCollapse .is-activeTab {
    display: block;
  }
}

@media (--smallWidth) {
  .Columns {
    display: block;
  }

  .Columns-body {
    width: 100%;
    margin-bottom: 20px;
  }

  .Columns--noGap .Columns-body {
    margin-bottom: 0px;
  }

  .Columns-sidebar {
    width: 100%;
  }

  /** @variant tabbedCollapse Enable tab-switching between columns  */
  .Columns--tabbedCollapse .Columns-body,
  .Columns--tabbedCollapse .Columns-sidebar {
    display: none;
  }

  .Columns--tabbedCollapse .is-activeTab {
    display: block;
  }
}
