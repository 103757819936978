/** @define GivebutterDonationForm */

:root {
  --GivebutterDonationForm-backgroundColor: var(--regularBackground);
  --GivebutterDonationForm-textColor: var(--regularTextColor);
}

@media (prefers-color-scheme: dark) {
  :root {
    --GivebutterDonationForm-backgroundColor: var(--accentBackground);
  }

  /* Givebutter's embed widget does not support a native dark-mode, therefore apply CSS filter to
     invert the colours, then counteract by applying hue-rotate so that green returns to green
     again, then boost brightness */
  .GivebutterDonationForm-container {
    filter: invert(1) hue-rotate(180deg) brightness(2.5);
  }
}

.GivebutterDonationForm {
  position: relative;
}

.GivebutterDonationForm-donate {
  text-align: left;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
}

.GivebutterDonationForm-dialog {
  background: var(--GivebutterDonationForm-backgroundColor);
  color: var(--GivebutterDonationForm-textColor);
  padding: 5px;
  width: calc(100% - 20px); /* Because the Givebutter iframe embed is dynamic, we have to force width */
}

.GivebutterDonationForm-heading {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  margin: 0 0 10px;
  /* Compensate for the reduced dialog padding above, since we're embedded the Givebutter iframe */
  padding: 15px 15px 0;
}

.GivebutterDonationForm-blurb {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: none;
  margin: 10px 0 0;
  /* Compensate for the reduced dialog padding above, since we're embedded the Givebutter iframe */
  padding: 0 15px 15px;
}

.GivebutterDonationForm-container {
  display: block;
  margin: 20px 0;
}

.GivebutterDonationForm.is-donating .GivebutterDonationForm-donate {
  clip: auto;
  width: auto;
  height: auto;

  transform: scale(1.0);
  opacity: 1;
}
