/** @define Hamburger */

:root {
  --Hamburger-burgerWidth: 35px;
  --Hamburger-burgerWeight: 2px;
  --Hamburger-burgerSpacing: 6px;
  --Hamburger-topColor: var(--bffBrandBlue);
  --Hamburger-middleColor: var(--bffBrandYellow);
  --Hamburger-bottomColor: var(--bffBrandRed);

  --Hamburger-burgerHeight: calc((var(--Hamburger-burgerWeight) * 3) + (var(--Hamburger-burgerSpacing) * 2));
  --Hamburger-burgerCrossLength: calc(var(--Hamburger-burgerHeight) * 1.41421356); /* sqrt(2) */
}

.Hamburger {
  display: flex;
  height: var(--Hamburger-burgerHeight);
}

.Hamburger-label {
  padding: 0px 14px;
}

.Hamburger-sandwich {
  position: relative;
  width: var(--Hamburger-burgerWidth);
}

.Hamburger-filling {
  display: block;
  width: 100%;
  height: var(--Hamburger-burgerWeight);

  position: absolute;
  left: 0;
  top: 0;

  background: transparent;
  border-radius: calc(var(--Hamburger-burgerWeight) / 2);

  transition: width .5s ease-in, transform .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Hamburger-topBun {
  background-color: var(--Hamburger-topColor);
}

.Hamburger-patty {
  background-color: var(--Hamburger-middleColor);
  z-index: 10;
  transform: translate(0, calc((var(--Hamburger-burgerHeight) - var(--Hamburger-burgerWeight)) / 2));
}

.Hamburger-baseBun {
  background-color: var(--Hamburger-bottomColor);
  transform: translate(0, calc(var(--Hamburger-burgerHeight) - var(--Hamburger-burgerWeight)));
}

/* @state is-menuActive
 * Repositions layers into an “X” shape for dismissal.
 */
.Hamburger.is-menuActive .Hamburger-filling {
  width: var(--Hamburger-burgerCrossLength);
}

.Hamburger.is-menuActive .Hamburger-topBun {
  transform: translate(0, calc((var(--Hamburger-burgerHeight) - var(--Hamburger-burgerWeight)) / 2))
             rotate(-45deg);
}

.Hamburger.is-menuActive .Hamburger-baseBun {
  transform: translate(0, calc((var(--Hamburger-burgerHeight) - var(--Hamburger-burgerWeight)) / 2))
             rotate(45deg);
}

.Hamburger.is-menuActive .Hamburger-patty {
  width: var(--Hamburger-burgerWeight);
  border-radius: 0.5px;
  transform: translate(calc((var(--Hamburger-burgerCrossLength) / 2) - (var(--Hamburger-burgerWeight) / 2)), calc((var(--Hamburger-burgerHeight) - var(--Hamburger-burgerWeight)) / 2))
             rotate(-45deg);
}

@media (--smallWidth) {
  .Hamburger-label {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    padding: 0;
    position: absolute;
  }
}
