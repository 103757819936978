/** @define ShowSummary */

:root {
  --ShowSummary-headlineColor: var(--regularTextColor);
  --ShowSummary-hostColor: var(--bffBrandBlue);
  --ShowSummary-bodyColor: var(--accentTextColor);
  --ShowSummary-timeColor: var(--accentTextColor);
  --ShowSummary-metadataColor: var(--lightGreyTextColor);
}

.ShowSummary {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.ShowSummary-title {
  order: 1;

  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  color: var(--ShowSummary-headlineColor);
  margin: 0 0 1px 0;
}


.ShowSummary-hosts {
  order: 2;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--ShowSummary-hostColor);

  margin: 1px 0 3px;
}

.ShowSummary-time {
  order: 3;
  margin: 0 0 10px;

  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--ShowSummary-timeColor);
}

.ShowSummary-website {
  order: 3;
  margin: 0 0 10px;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  color: var(--ShowSummary-hostColor);
}

.ShowSummary-description {
  order: 4;

  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 10px 10px 15px 0; /* Ends up looking more like 15px after line heights */
  color: var(--ShowSummary-bodyColor);
}

.ShowSummary-categories {
  order: 5;

  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightBold);
  color: var(--ShowSummary-metadataColor);
  text-transform: uppercase;
}

@media (--smallWidth) {
  .ShowSummary {
    text-align: center;
  }

  .ShowSummary-description {
    margin: 10px 15px 15px 15px;
  }

  .ShowSummary--leftAlign {
    text-align: left;
  }

  .ShowSummary--leftAlign .ShowSummary-description {
    margin: 10px 10px 10px 0;
  }
}
