/** @define CategoryList; strict */

.CategoryList {
  display: block;
}

.CategoryList-item {
  display: inline-block;
}

.CategoryList-item::after {
  content: ' · ';
  margin: 0 5px;
}

.CategoryList-item:last-child::after {
  content: none;
}
