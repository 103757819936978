/* BFF.fm Theme Colors */

:root {
  --bffBrandBlue: #79cdc6;
  --bffBrandYellow: #fede91;
  --bffBrandRed: #f36c4f;
  --bffBrandWhite: #fafafa;
  --bffBrandBlack: #252525;
  --bffBrandTrueBlack: #101010;

  --bffBrandRedAlpha: color(#f36c4f a(30%));
  --bffBrandBlueAlpha: color(#79cdc6 a(30%));
  --bffBrandYellowAlpha: color(#fede91 a(30%));

  --regularBackground: #fafafa;
  --regularBackgroundTranslucent: color(#fafafa a(85%));
  --regularBackgroundTransparent: color(#fafafa a(0));

  --accentBackground: #efefef;
  --accentBackgroundTranslucent: color(#efefef a(75%));
  --accentBackgroundHalf: color(#efefef a(50%));
  --accentBackgroundSmoke: color(#efefef a(25%));
  --accentBackgroundTransparent: color(#efefef a(0));

  --brightTextColor: #252525;
  --regularTextColor: #252525;
  --greyTextColor: #474747;
  --accentTextColor: #707070;
  --lightGreyTextColor: #acacac;
  --onColorTextColor: #fafafa;
  --onLightTextColor: #252525;

  --playerBackground: #252525;
  --playerTranslucentBackground: color(#252525 a(90%));
  --playerTextColor: #fafafa;
  --playerAccentColor: #58595B;
}

@media (prefers-color-scheme: dark) {
  :root {
    --regularBackground: #101010;
    --regularBackgroundTranslucent: color(#101010 a(85%));
    --regularBackgroundTransparent: color(#101010 a(0));

    --accentBackground: #252525;
    --accentBackgroundTranslucent: color(#252525 a(75%));
    --accentBackgroundHalf: color(#252525 a(50%));
    --accentBackgroundSmoke: color(#252525 a(25%));
    --accentBackgroundTransparent: color(#252525 a(0));

    --brightTextColor: #e8e8e8;
    --regularTextColor: #dadada;
    --greyTextColor: #b8b8b8;
    --accentTextColor: #7e7e7e;
    --lightGreyTextColor: #535353;

    --playerBackground: #050505;
  }
}
