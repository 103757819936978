/** @define SocialFollowButton */

:root {
  --SocialFollowButton-backgroundColor: var(--accentBackgroundSmoke);
  --SocialFollowButton-borderColor: var(--accentBackground);
  --SocialFollowButton-foreColor: var(--bffBrandWhite);
  --SocialFollowButton-highlightColor: var(--regularTextColor);

  --SocialFollowButton-twitterBackground: #1DA1F2;
  --SocialFollowButton-facebookBackground: #1877F2;
  --SocialFollowButton-instagramBackground: #e9478b;
  --SocialFollowButton-mastodonBackground: linear-gradient(#6364FF, #563ACC);
  --SocialFollowButton-lastfmBackground: #d50000;
  --SocialFollowButton-mixcloudBackground: #273a4b;
  --SocialFollowButton-soundcloudBackground: linear-gradient(#f79810, #f8310e);
  --SocialFollowButton-spotifyBackground: #2Ed153;
  --SocialFollowButton-appleMusicBackground: #d60017;
  --SocialFollowButton-youtubeBackground: #ff0000;
}

.SocialFollowButton {
  display: inline-block;
  border: 1px solid var(--SocialFollowButton-borderColor);
  border-radius: 4px;
  color: var(--SocialFollowButton-foreColor);
  text-shadow: rgba(0,0,0,0.5) 0 1px 2px;
  background: var(--SocialFollowButton-backgroundColor);

  padding: 3px 10px;

  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.SocialFollowButton:hover,
.SocialFollowButton:link:hover,
.SocialFollowButton:link:focus {
  text-decoration: none;
  border-color: var(--SocialFollowButton-highlightColor);
  cursor: pointer;
}

.SocialFollowButton:active {
  transform: scale(.95);
  transition: transform .1s ease-out;
}

.SocialFollowButton-text {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 5px;
  vertical-align: top;
}

.SocialFollowButton-textContext {
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightRegular);
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 5px;
  vertical-align: top;
}

.SocialFollowButton-textWithContext {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  vertical-align: middle;
  margin: 0 0 5px;
}

.SocialFollowButton-icon {
  font-size: 16px;
  line-height: 16px;
}

/** @variant twitter */
.SocialFollowButton--twitter { background: var(--SocialFollowButton-twitterBackground); }
.SocialFollowButton--facebook { background: var(--SocialFollowButton-facebookBackground); }
.SocialFollowButton--instagram { background: var(--SocialFollowButton-instagramBackground); }
.SocialFollowButton--mastodon { background: var(--SocialFollowButton-mastodonBackground); }
.SocialFollowButton--lastfm { background: var(--SocialFollowButton-lastfmBackground); }
.SocialFollowButton--mixcloud { background: var(--SocialFollowButton-mixcloudBackground); }
.SocialFollowButton--soundcloud { background: var(--SocialFollowButton-soundcloudBackground); }
.SocialFollowButton--spotify { background: var(--SocialFollowButton-spotifyBackground); }
.SocialFollowButton--applemusic { background: var(--SocialFollowButton-appleMusicBackground); }
.SocialFollowButton--youtube { background: var(--SocialFollowButton-youtubeBackground); }

@media (--smallWidth) {
  .SocialFollowButton {
    display: block;
    width: 100%;
  }
}
