/** @define ClampedContent */

:root {
  --ClampedContent-backgroundColor: var(--regularBackground);
  --ClampedContent-transparentBackgroundColor: var(--regularBackgroundTransparent);
}

.ClampedContent {
  position: relative;
}

.ClampedContent-showMoreButton {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;

  padding-top: 2.5em;

  background: linear-gradient(var(--ClampedContent-transparentBackgroundColor) 0%, var(--ClampedContent-backgroundColor) 50%);

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  text-align: right;
}

.ClampedContent-more {
  opacity: 0;
  overflow-y: hidden;
  height: 0;
  transition: opacity .3s ease-out .1s;
}

.ClampedContent.is-expanded .ClampedContent-more {
  opacity: 1.0;
  overflow-y: visibe;
  height: auto;
}

.ClampedContent.is-expanded .ClampedContent-showMoreButton {
  display: none;
}
