/** @define BasicPlayButton */

:root {
  --BasicPlayButton-size: 50px;
  --BasicPlayButton-ringWidth: 2px;
  --BasicPlayButton-buttonSize: calc(var(--BasicPlayButton-size) - (var(--BasicPlayButton-ringWidth) * 2));
  --BasicPlayButton-buttonColor: var(--playerBackground);
  --BasicPlayButton-ringColor: var(--playerAccentColor);
}

.BasicPlayButton {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: var(--ProgressPlayButton-size);
  height: var(--ProgressPlayButton-size);

  background: var(--BasicPlayButton-buttonColor);

  padding: var(--ProgressPlayButton-ringWidth);
  border-radius: calc(var(--ProgressPlayButton-size) / 2);
  border: var(--ProgressPlayButton-ringWidth) solid var(--ProgressPlayButton-ringColor);
  box-shadow: 0 0 0px calc(var(--ProgressPlayButton-ringWidth)/2) var(--ProgressPlayButton-buttonColor);
}

.BasicPlayButton-playButton {
  font-size: 32px;
}
