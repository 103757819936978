/* @define Grid */

.Grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  /* negative margin pulls the .programs back to the left/right edges */
  margin: 0 -20px;
}

.Grid-item {
  box-sizing: border-box;
  padding: 0 20px 20px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow-x: hidden; /* ensure unwrapped text doesn't break the grid */
}

.Grid--1 .Grid-item {
  flex-basis: 100%;
}

.Grid--2 .Grid-item {
  flex-basis: calc(100%/2);
}

.Grid--3 .Grid-item {
  flex-basis: calc(100%/3);
}

.Grid--4 .Grid-item {
  flex-basis: calc(100%/4);
}

.Grid--5 .Grid-item {
  flex-basis: calc(100%/5);
}

.Grid--6 .Grid-item {
  flex-basis: calc(100%/6);
}

.Grid--growItems .Grid-item {
  flex-grow: 100;
}

.Grid--narrowGutter {
  /* negative margin pulls the .programs back to the left/right edges */
  margin: 0 -10px;
}

.Grid--narrowGutter .Grid-item {
  padding: 0 10px 20px;
}

@media (--mediumWidth) {
  .Grid .Grid-item {
    flex-basis: 50%;
  }

  .Grid--4 .Grid-item {
    flex-basis: calc(100%/3);
  }

  .Grid--5 .Grid-item,
  .Grid--6 .Grid-item {
    flex-basis: calc(100%/4);
  }

  .Grid--min3 .Grid-item {
    flex-basis: calc(100%/3);
  }

  .Grid--min4 .Grid-item {
    flex-basis: calc(100%/4);
  }
}

@media (--smallWidth) {
  .Grid .Grid-item {
    flex-basis: 100%;
  }

  .Grid--min2 .Grid-item {
    flex-basis: 50%;
  }

  .Grid--min3 .Grid-item {
    flex-basis: calc(100%/3);
  }

  .Grid--min4 .Grid-item {
    flex-basis: calc(100%/4);
  }
}
