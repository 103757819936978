/** @define HoverIcon */

.HoverIcon .HoverIcon-on {
  display: none;
}

.HoverIcon:hover .HoverIcon-on,
.HoverIcon:focus .HoverIcon-on {
  display: inline-block;
}

.HoverIcon:hover .HoverIcon-off,
.HoverIcon:focus .HoverIcon-off {
  display: none;
}
