/** @define MiniSchedule */

:root {
  --MiniSchedule-backgroundColor: var(--regularBackground);
  --MiniSchedule-transparentBackgroundColor: var(--regularBackgroundTransparent);
  --MiniSchedule-highlightColor: var(--accentBackground);
  --MiniSchedule-transparentHighlightColor: var(--accentBackgroundTransparent);
  --MiniSchedule-textColor: var(--regularTextColor);
  --MiniSchedule-offsetTextColor: var(--accentTextColor);
  --MiniSchedule-onAirTextColor: var(--bffBrandRed);
  --MiniSchedule-fullScheduleLinkColor: var(--bffBrandBlue);
  --MiniSchedule-showWidth: 180px;
  --MiniSchedule-showSpacing: 20px;

  /* internal vars */
  --MiniSchedule-showTotalWidth: calc(var(--MiniSchedule-showWidth) + var(--MiniSchedule-showSpacing));
}

.MiniSchedule {
  display: flex;
  position: relative;
  /* NB we don't apply the full background color here as we want the dark-mode gradient to show through */
  color: var(--MiniSchedule-textColor);
}

/* fadeLeft and fadeRight and presentational elements to fade the scrolling portion
 * of the schedule as it slides under the “on air” show */
.MiniSchedule-fadeLeft,
.MiniSchedule-fadeRight {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.MiniSchedule-fadeLeft {
  width: 3px;
  background: radial-gradient(rgba(80, 80, 80, .8) 0%, rgba(80, 80, 80, 0) 60%);
  /* We indent this with translate rather than `left` so that we
    don't have to account for variable container padding */
  transform: translateX(198.5px);
}

.MiniSchedule-fadeRight {
  width: 15px;
  transform: translateX(7.5px);
  background: radial-gradient(var(--MiniSchedule-backgroundColor), var(--MiniSchedule-transparentBackgroundColor));
  right: 0;
}

@media (prefers-color-scheme: dark) {
  /* The fade doesn't look good on top of the dark-mode top gradient, so we re-use the shadow/fold style instead */
  .MiniSchedule-fadeRight {
    width: 3px;
    background: radial-gradient(rgba(80, 80, 80, .8) 0%, rgba(80, 80, 80, 0) 60%);
    transform: translateX(1.5px);
    right: 0;
  }
}

.MiniSchedule-upNext {
  flex-grow: 10; /* fill available horizontal space */

  /* scrolling */
  overflow-x: auto;
}

.MiniSchedule-upNextContent {
  /* another flex container inside this for the shows */
  display: flex;
  align-items: stretch;
  scroll-snap-points-x: repeat(calc(var(--MiniSchedule-showWidth) + var(--MiniSchedule-showSpacing)));
}

/* TODO: Possibly break “mini show” into its own component */
.MiniSchedule-show {
  display: block;
  width: var(--MiniSchedule-showWidth);
  margin-left: var(--MiniSchedule-showSpacing);
  padding-bottom: 0;

  text-align: center;
}

.MiniSchedule-onAir .MiniSchedule-show {
  margin-left: 0;
  margin-right: var(--MiniSchedule-showSpacing);
}

.MiniSchedule-divider {
  display: block;
  height: 100%;
  width: 3px;
  background: radial-gradient(rgba(80, 80, 80, .8) 0%, rgba(80, 80, 80, 0) 60%);
  transform: translateX(-1.5px);
  margin-left: var(--MiniSchedule-showSpacing);
}

/* No margin on last item */
.MiniSchedule-seeMore {
  margin-right: calc(var(--MiniSchedule-showSpacing) / 2);
}

.MiniSchedule-show:hover,
.MiniSchedule-show:focus {
  background: radial-gradient(circle, var(--MiniSchedule-highlightColor) 0%, var(--MiniSchedule-transparentHighlightColor) 100%);
}

.MiniSchedule-thumbnail {
  width: var(--MiniSchedule-showWidth);
  height: var(--MiniSchedule-showWidth);
  object-fit: cover;
}

.MiniSchedule-showName {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  margin: var(--MiniSchedule-showSpacing) 0 calc(var(--MiniSchedule-showSpacing) / 2);
}

.MiniSchedule-time,
.MiniSchedule-broadcastingNow {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  color: var(--MiniSchedule-offsetTextColor);
}

.MiniSchedule-broadcastingNow {
  text-transform: uppercase;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  color: var(--MiniSchedule-onAirTextColor);
}

.MiniSchedule-browseFullLink {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--MiniSchedule-fullScheduleLinkColor);
}

@media (--smallWidth), (--smallHeight) {
  .MiniSchedule-upNextContent {
     scroll-snap-points-x: repeat(calc(calc(var(--MiniSchedule-showWidth) / 1.5) + calc(var(--MiniSchedule-showSpacing) / 2)));
  }
  .MiniSchedule-show {
    width: calc(var(--MiniSchedule-showWidth) / 1.5);
    margin-left: calc(var(--MiniSchedule-showSpacing) / 2);
  }

  .MiniSchedule-onAir .MiniSchedule-show {
    margin-left: 0;
    margin-right: calc(var(--MiniSchedule-showSpacing) / 2);
  }

  .MiniSchedule-divider {
    margin-left: calc(var(--MiniSchedule-showSpacing) / 2);
  }

  .MiniSchedule-seeMore {
    margin-right: calc(var(--MiniSchedule-showSpacing) / 2);
  }

  .MiniSchedule-thumbnail {
    width: calc(var(--MiniSchedule-showWidth) / 1.5);
    height: calc(var(--MiniSchedule-showWidth) / 1.5);
  }

  .MiniSchedule-showName {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
    margin: calc(var(--MiniSchedule-showSpacing) / 2) 0 ;
  }

  .MiniSchedule-time {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
    font-weight: var(--fontWeightBold);
  }

  .MiniSchedule-fadeLeft {
    /* We indent this with translate rather than `left` so that we
       don't have to account for variable container padding */
    transform: translateX(128.5px);
  }
}

@media (--smallHeight) {
  .MiniSchedule-upNextContent {
     scroll-snap-points-x: repeat(calc(var(--MiniSchedule-showWidth) + var(--MiniSchedule-showSpacing) / 2));
  }
  .MiniSchedule-show {
    width: calc(var(--MiniSchedule-showWidth) / 2);
  }

  .MiniSchedule-showName {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
  }

  .MiniSchedule-thumbnail {
    width: calc(var(--MiniSchedule-showWidth) / 2);
    height: calc(var(--MiniSchedule-showWidth) / 2);
  }

  .MiniSchedule-fadeLeft {
    /* We indent this with translate rather than `left` so that we
       don't have to account for variable container padding */
    transform: translateX(98.5px);
  }
}

@media (--fullBleed) {
  .MiniSchedule-fadeRight {
    display: none;
  }
}
