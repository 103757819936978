/** @define Pip */
.Pip {
  overflow: hidden;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  color: transparent;
  background: rgba(255, 255, 255, .5);
  border: 1px solid rgba(255, 255, 255, .8);
}

.Pip--selected {
  background: rgba(255, 255, 255, .9);
}
