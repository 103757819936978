/** @define TimeRuler */

:root {
  --TimeRuler-backgroundColor: var(--accentBackground);
  --TimeRuler-metadataColor: var(--accentTextColor);
  --TimeRuler-borderColor: var(--lightGreyTextColor);
  --TimeRuler-barColour: var(--bffBrandRed);
  --TimeRuler-highlightBackground: var(--bffBrandBlueAlpha);
}

.TimeRuler {
  background: var(--TimeRuler-backgroundColor);
  border-right: 1px solid var(--TimeRuler-borderColor);
}

.TimeRuler-time {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  color: var(--TimeRuler-metadataColor);
  padding: 3px 6px;
  border: 1px solid transparent;

  transition: border-color .2s ease-out, width .5s ease-out;
}

.TimeRuler-time:hover {
  border-top-color: var(--TimeRuler-barColour);
  width: 100vw;
  font-weight: var(--fontWeightBold);
}
