/** @define Tracklisting */

:root {
  --Tracklisting-timestampColor: var(--accentTextColor);
}

.Tracklisting {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

.Tracklisting-heading {
  font-weight: var(--fontWeightBold);
}

.Tracklisting-support {
  margin: 20px 0;
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-style: italic;
  color: var(--Tracklisting-timestampColor);
}

.Tracklisting-listItem {
  margin: 10px 0;
}

.Tracklisting-break {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-style: italic;
  color: var(--Tracklisting-timestampColor);
  margin-left: 20px;
}

/** @variant --noFeatures Where no featured tracks require indentation */
.Tracklisting--noFeatures .Tracklisting-break {
  margin-left: 0;
}
