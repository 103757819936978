/* @define LinkButton */

.LinkButton {
  display: inline-block;
  color: inherit;
  border: none;
  background: none;
  text-align: left;
}

.LinkButton:hover,
.LinkButton:focus {
  outline: none;
  cursor: pointer;
  text-decoration: underline;
}

.LinkButton--block:hover,
.LinkButton--block:focus {
  text-decoration: none;
}

.LinkButton:hover .LinkButton-text,
.LinkButton:focus .LinkButton-text {
  text-decoration: underline;
}
