/** @define PodcastSubscribe */

:root {
  --PodcastSubscribe-borderColor: var(--accentBackground);
  --PodcastSubscribe-backgroundColor: var(--accentBackgroundSmoke);
  --PodcastSubscribe-highlightColor: var(--bffBrandBlue);
  --PodcastSubscribe-hoverText: var(--brightTextColor);
  --PodcastSubscribe-hoverBackgroundColor: var(--accentBackgroundTranslucent);
}

.PodcastSubscribe {}

.PodcastSubscribe-button:link:hover,
.PodcastSubscribe-button:link:focus {
  color: var(--PodcastSubscribe-hoverText);
  background: var(--PodcastSubscribe-hoverBackgroundColor);
}

.PodcastSubscribe-feedUrlDialog {
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  opacity: 0;
}

.PodcastSubscribe.is-subscribing .PodcastSubscribe-feedUrlDialog {
  clip: auto;
  width: auto;
  height: auto;

  transform: scale(1.0);
  opacity: 1;
}

.PodcastSubscribe--translucentButton .PodcastSubscribe-button {
  display: inline-block;
  border: 1px solid var(--PodcastSubscribe-borderColor);
  border-radius: 4px;
  color: inherit;
  text-shadow: rgba(0,0,0,0.5) 0 1px 2px;
  background: var(--PodcastSubscribe-backgroundColor);
}

.PodcastSubscribe--translucentButton .PodcastSubscribe-button:link:hover,
.PodcastSubscribe--translucentButton .PodcastSubscribe-button:link:focus {
  text-decoration: none;
  border-color: var(--PodcastSubscribe-highlightColor);
  color: var(--PodcastSubscribe-hoverText);
  background: var(--PodcastSubscribe-backgroundColor);
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.PodcastSubscribe--fullWidthButton .PodcastSubscribe-button {
  width: 100%;
  background-color: inherit;
}

@media (--smallWidth) {
  .PodcastSubscribe-button {
    padding: 10px 20px;
  }
}
