/** @define SocialIcons */

.SocialIcons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 35px;
}

.SocialIcons-icon {
  /* Icon is implemented as a black mask. Make transluent for grey */
  opacity: .45;
  margin: 0 5px 5px;
}

.SocialIcons-icon:last {
  margin: 0 0 5px 5px;
}
