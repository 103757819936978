/** @define DonationPrompt */

:root {
  --DonationPrompt-donateBackgroundColor: var(--regularBackground);
  --DonationPrompt-donateTextColor: var(--regularTextColor);
  --DonationPrompt-designationTextColor: var(--bffBrandRed);
}

@media (prefers-color-scheme: dark) {
  :root {
    --DonationPrompt-donateBackgroundColor: var(--accentBackground);
  }
}

.DonationPrompt {
  position: relative;
}

.DonationPrompt-donate {
  text-align: left;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
}

.DonationPrompt-dialog {
  background: var(--DonationPrompt-donateBackgroundColor);
  color: var(--DonationPrompt-donateTextColor);
  padding: 20px;
  max-width: 450px;
}

.DonationPrompt-heading {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  margin: 0 0 10px;
}

.DonationPrompt-blurb {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: none;
  margin: 10px 0 0;
}

.DonationPrompt-designatedBlurb {
  display: none;
}

.DonationPrompt-designation {
  text-transform: uppercase;
  color: var(--DonationPrompt-designationTextColor);
}

.DonationPrompt-campaign {
  margin: 20px 0;
}

.DonationPrompt-podcastCampaign {
  display: none;
}

.DonationPrompt-form {
  display: block;
  margin: 20px 0;
}

.DonationPrompt.is-donating .DonationPrompt-donate {
  clip: auto;
  width: auto;
  height: auto;

  transform: scale(1.0);
  opacity: 1;
}

/* @state is-designation When a donation is attributed to a show/DJ */
.DonationPrompt.is-designated .DonationPrompt-genericBlurb {
  display: none;
}

.DonationPrompt.is-designated .DonationPrompt-designatedBlurb {
  display: block;
}

.DonationPrompt.is-podcast .DonationPrompt-podcastCampaign {
  display: block;
}

.DonationPrompt.is-podcast .DonationPrompt-radioCampaign {
  display: none;
}
