/** @define PodcastCarousel */

:root {
  --PodcastCarousel-offsetText: var(--accentTextColor);
}

.PodcastCarousel {}

.PodcastCarousel-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 40px;
  padding-left: max(env(safe-area-inset-left), 20px);
}

.PodcastCarousel-title {
  font-size: var(--fontSizeXL);
  line-height: var(--fontHeightXL);
  font-weight: var(--fontWeightBold);
}

.PodcastCarousel-infoLink {
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--PodcastCarousel-offsetText);
}

.PodcastCarousel-content {
  display: flex;
  padding-right: 20px;
  scroll-padding: 20px;
  padding-left: 20px;
  scroll-padding: max(env(safe-area-inset-left), 20px);
  padding-left: max(env(safe-area-inset-left), 20px);
}

.PodcastCarousel-item {
  box-sizing: content-box;
  width: 250px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 20px;
}

/* No margin on the first item, as that's applied by the container which also accounts for the safe-area */
.PodcastCarousel-item:first-child {
  margin-left: 0;
}

.PodcastCarousel-item:last-child {
  padding-right: 20px;
}

/** @variant small Reduce card size */
.PodcastCarousel--small .PodcastCarousel-title {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
}

.PodcastCarousel--small .PodcastCarousel-infoLink {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
}

.PodcastCarousel--small .PodcastCarousel-item {
  min-width: 200px; /* Overrides SummaryCard */
  width: 200px;
}

@media (--smallWidth) {
  .PodcastCarousel-header {
    padding: 20px 10px 20px;
    padding-left: max(env(safe-area-inset-left), 10px);
  }

  .PodcastCarousel-title {
    font-size: var(--fontSizeL);
    line-height: var(--fontHeightL);
  }

  .PodcastCarousel-infoLink {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .PodcastCarousel-content {
    padding-right: 10px;
    scroll-padding: 10px;
    padding-left: 10px;
    scroll-padding: max(env(safe-area-inset-left), 10px);
    padding-left: max(env(safe-area-inset-left), 10px);
  }

  .PodcastCarousel-item {
    min-width: 200px; /* Overrides SummaryCard */
    width: 200px;
    margin-left: 10px;
  }

  .PodcastCarousel-item:last-child {
    padding-right: 10px;
  }
}
