/** @define LabelIndex */

:root {
  --LabelIndex-recentBackground: var(--accentBackground);
}

.LabelIndex {}

.LabelIndex-heading {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin-bottom: 20px;
}

.LabelIndex-recent {
  margin: 0 0 20px;
  padding: 20px;
  background: var(--LabelIndex-recentBackground);
}

.LabelIndex-recentTrack {
  margin-top: 7px;
}

.LabelIndex-featuredLabels,
.LabelIndex-moreLabels {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media(--smallWidth) {
  .LabelIndex-heading {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    margin-bottom: 10px;
  }

  .LabelIndex-recent {
    padding: 10px;
    margin-bottom: 10px;
  }

  .LabelIndex-recentTrack {
    margin-top: 5px;
  }

  .LabelIndex-featuredLabels,
  .LabelIndex-moreLabels {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
