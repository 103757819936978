/** @define Modal */

:root {
  --Modal-contentBorderColor: transparent;
}

@media (prefers-color-scheme: dark) {
  :root {
    --Modal-contentBorderColor: var(--accentBackground);
  }
}

.Modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 20000;

  background: rgba(0, 0, 0, .2);
  box-shadow: inner 0 0 2px rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal-content {
  background-color:inherit;
  position: relative;
  z-index: 20100;
  border-radius: 5px;

  max-width: 600px;
  max-height: 85%;
  overflow-x: hidden;
  overflow-y: auto;

  border: 4px solid var(--Modal-contentBorderColor);
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);
  margin: 10px;
}

.Modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 20px 0;
  opacity: .7;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  transition: transform .3 var(--transitionBounce);
}

.Modal-close:hover,
.Modal-close:focus-visible {
  opacity: 1;
  transform: scale(1.5) translateY(-5px);
}

.Modal-close::after {
  content: '×';
  line-height: 0;
}

@media (--smallWidth), (--smallHeight) {
  .Modal-close {
    padding: 10px 10px 0;
  }

  .Modal-close:hover,
  .Modal-close:focus-visible {
    transform: scale(1.5) translateY(-2.5px);
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .Modal-content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
