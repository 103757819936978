/** @define CheckBox */

.CheckBox {
  position: relative;
  padding-left: 24px;
}

.CheckBox-input {
  position: absolute;
  top: 5px;
  left: 0;
}

.CheckBox-label {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: none;
}
