/** @define HeaderPlayButton */

:root {
  --HeaderPlayButton-backgroundColor: var(--accentBackgroundSmoke);
  --HeaderPlayButton-borderColor: var(--accentBackground);
  --HeaderPlayButton-foreColor: var(--regularTextColor);
  --HeaderPlayButton-highlightColor: var(--bffBrandBlue);
}

.HeaderPlayButton {
  display: inline-block;
  border: 1px solid var(--HeaderPlayButton-borderColor);
  border-radius: 4px;
  color: inherit;
  text-shadow: rgba(0,0,0,0.5) 0 1px 2px;
  background: var(--HeaderPlayButton-backgroundColor);

  padding: 5px 30px 5px 15px; /* This is less than the subscribe button, due to extra icon sizing */

  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.HeaderPlayButton:hover,
.HeaderPlayButton:link:hover,
.HeaderPlayButton:link:focus {
  text-decoration: none;
  border-color: var(--HeaderPlayButton-highlightColor);
  cursor: pointer;
}

.HeaderPlayButton:active {
  transform: scale(.95);
  transition: transform .1s ease-out;
}

.HeaderPlayButton-text {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 10px;
}


.HeaderPlayButton-playButton,
.HeaderPlayButton-loadingButton,
.HeaderPlayButton-pauseButton {
  font-size: 32px;
  line-height: 14px;
}

.HeaderPlayButton-loadingButton,
.HeaderPlayButton-pauseButton {
  display: none;
}

/* @state is-playing Playback of this source is occuring */
.HeaderPlayButton.is-playing .HeaderPlayButton-pauseButton {
  display: inline-block;
}

.HeaderPlayButton.is-playing .HeaderPlayButton-playButton {
  display: none;
}

/* @state is-loading The source is loading before being played */
.HeaderPlayButton.is-loading .HeaderPlayButton-loadingButton {
  display: inline-block;
}

.HeaderPlayButton.is-loading .HeaderPlayButton-pauseButton,
.HeaderPlayButton.is-loading .HeaderPlayButton-playButton {
  display: none;
}

@media (--smallWidth) {
  .HeaderPlayButton {
    display: block;
    width: 100%;
  }
}
