/* @define ProgressPlayButton; weak */

:root {
  --ProgressPlayButton-size: 50px;
  --ProgressPlayButton-ringWidth: 2px;

  --ProgressPlayButton-buttonSize: calc(var(--ProgressPlayButton-size) - (var(--ProgressPlayButton-ringWidth) * 2));

  --ProgressPlayButton-buttonColor: var(--playerBackground);
  --ProgressPlayButton-ringColor: var(--playerAccentColor);
  --ProgressPlayButton-loadingBackgroundColor: var(--bffBrandYellow);
  --ProgressPlayButton-loadingBackgroundColorTranslucent: var(--bffBrandYellowAlpha);
  --ProgressPlayButton-pausedBackgroundColor: var(--bffBrandRed);
  --ProgressPlayButton-playingBackgroundColor: var(--bffBrandBlue);
  --ProgressPlayButton-streamingBackgroundColor: var(--bffBrandBlue);
  --ProgressPlayButton-streamingBackgroundColorTranslucent: var(--bffBrandBlueAlpha);
}

.ProgressPlayButton {
  display: inline-block;
  vertical-align: middle;
  width: var(--ProgressPlayButton-size);
  height: var(--ProgressPlayButton-size);
}

.ProgressPlayButton-progressRing {
  display: inline-block;
  box-sizing: border-box;
  width: var(--ProgressPlayButton-size);
  height: var(--ProgressPlayButton-size);
  padding: var(--ProgressPlayButton-ringWidth);
  border-radius: calc(var(--ProgressPlayButton-size) / 2);
  background: var(--ProgressPlayButton-ringColor);
  box-shadow: 0 0 0px calc(var(--ProgressPlayButton-ringWidth)/2) var(--ProgressPlayButton-buttonColor);

  position: relative;
  overflow: hidden;

  /* Force onto the GPU so that border-radius crop applies during opacity transition */
  transform: translateZ(0);
}

.ProgressPlayButton-infiniteRingGlow {
  display: block;
  position: absolute;
  top: 0; left: 0;
  width: calc(var(--ProgressPlayButton-size) * 2);
  height: calc(var(--ProgressPlayButton-size) * 2);

  transition: opacity .5s ease-in;
  opacity: 0;
}

.ProgressPlayButton.is-loading .ProgressPlayButton-infiniteRingGlow {
  background: #fff radial-gradient(circle, var(--ProgressPlayButton-loadingBackgroundColorTranslucent) 0%, var(--ProgressPlayButton-loadingBackgroundColor) 100%);
}

.ProgressPlayButton.is-streaming .ProgressPlayButton-infiniteRingGlow {
  background: #fff radial-gradient(circle, var(--ProgressPlayButton-streamingBackgroundColorTranslucent) 0%, var(--ProgressPlayButton-streamingBackgroundColor) 100%);
}

.ProgressPlayButton-playbackRingGlow {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: var(--ProgressPlayButton-size);
  background: var(--ProgressPlayButton-playingBackgroundColor);

  opacity: 0;
}

.ProgressPlayButton-playbackRingGlow--right {
  left: 50%;
  z-index: 1400;
}

.ProgressPlayButton-playbackRingGlow--right {
  z-index: 1300;
}

.ProgressPlayButton-playbackMask {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: var(--ProgressPlayButton-size);
  width: calc(var(--ProgressPlayButton-size) / 2);
  transition: transform .1s linear;

  background: var(--ProgressPlayButton-ringColor);

  opacity: 0;
}

.ProgressPlayButton-playbackMask--right {
  left: 50%;
  transform-origin: 0 50%;

  z-index: 1500;
}

.ProgressPlayButton-playbackMask--left {
  transform-origin: 100% 50%;
  z-index: 1200;
}

.ProgressPlayButton-button {
  display: block;
  position: absolute;
  width: var(--ProgressPlayButton-buttonSize);
  height: var(--ProgressPlayButton-buttonSize);
  border-radius: calc(var(--ProgressPlayButton-buttonSize) / 2);

  background: var(--ProgressPlayButton-buttonColor);

  z-index: 1600;
}

.ProgressPlayButton-toggleButton {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* Prevents blur glitch during loading animation */
.ProgressPlayButton-toggleButton:focus {
  outline: none;
}

.ProgressPlayButton-playButton {
  display: block;
}

.ProgressPlayButton-pauseButton {
  display: none;
}

/* Draw the Play/Pause icon */
.ProgressPlayButton-icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;

  height: 16px;
  width: 16px;
  transform: translate(-3.5px, -7px);
  transition: transform .3s ease-out;

  overflow: hidden;
}

.ProgressPlayButton-iconLineA,
.ProgressPlayButton-iconLineB {
  display: block;
  position: absolute;
  top: 0;
  left: 0;

  transition: background .2s ease-in .6s, border .3s ease-out, transform .3s ease-out, width .3s ease-out;
}

.ProgressPlayButton-iconLineA {
  height: 100%;
  width: 0;
  border-left: 1px solid #fff;
  border-radius: 2px 0 0 2px;
}

.ProgressPlayButton-iconLineB {
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-width: 1px 1px 0 0;
  border-radius: 0 2px 0 0;
  background: #fff;
  transform: translate(-11.5px, 0px) scale(1.4, 1) rotate(45deg);
}

@media  only screen and (-webkit-max-device-pixel-ratio: 1.3),
  only screen and (max-resolution: 120dpi) {
  .ProgressPlayButton-iconLineA { border-left-width: 2px; }
  .ProgressPlayButton-iconLineB { border-width: 2px 2px 0 0; }
}

.ProgressPlayButton.is-loading .ProgressPlayButton-icon,
.ProgressPlayButton.is-streaming .ProgressPlayButton-icon,
.ProgressPlayButton.is-playing .ProgressPlayButton-icon {
  transform: translate(-5px, -7px);
}

.ProgressPlayButton.is-loading .ProgressPlayButton-iconLineA,
.ProgressPlayButton.is-loading .ProgressPlayButton-iconLineB,
.ProgressPlayButton.is-streaming .ProgressPlayButton-iconLineA,
.ProgressPlayButton.is-streaming .ProgressPlayButton-iconLineB,
.ProgressPlayButton.is-playing .ProgressPlayButton-iconLineA,
.ProgressPlayButton.is-playing .ProgressPlayButton-iconLineB {
  border: 1px solid #fff;
  border-radius: 2px;

  transition: background .2s ease-in, border .3s ease-out .3s, transform .3s ease-out .3s, width .3s ease-out .3s;
}

.ProgressPlayButton.is-loading .ProgressPlayButton-iconLineA,
.ProgressPlayButton.is-streaming .ProgressPlayButton-iconLineA,
.ProgressPlayButton.is-playing .ProgressPlayButton-iconLineA {
  transform: translate(8px, 0px);
}

.ProgressPlayButton.is-loading .ProgressPlayButton-iconLineB,
.ProgressPlayButton.is-streaming .ProgressPlayButton-iconLineB,
.ProgressPlayButton.is-playing .ProgressPlayButton-iconLineB {
  width: 0;
  background: #fff;
  transform: translate(0px, 0px) scale(1) rotate(180deg);
}

/* @state is-streaming
 * The player is playing back media of unbounded length
 */
@keyframes ProgressPlayButton-infiniteGlow {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(calc(-1 * var(--ProgressPlayButton-buttonSize)), 0); }
  50% { transform: translate(calc(-1 * var(--ProgressPlayButton-buttonSize)), calc(-1 * var(--ProgressPlayButton-buttonSize))); }
  75% { transform: translate(0, calc(-1 * var(--ProgressPlayButton-buttonSize))); }
}

.ProgressPlayButton.is-loading .ProgressPlayButton-infiniteRingGlow,
.ProgressPlayButton.is-streaming .ProgressPlayButton-infiniteRingGlow {
  opacity: 1;
  animation: ProgressPlayButton-infiniteGlow 3s ease-in infinite;
}

.ProgressPlayButton.is-streaming .ProgressPlayButton-playButton {
  display: none;
}

.ProgressPlayButton.is-streaming .ProgressPlayButton-pauseButton {
  display: block;
}

/* @state is-paused
 * Playback has been paused
 */
.ProgressPlayButton.is-paused .ProgressPlayButton-playButton {
  display: block;
}

.ProgressPlayButton.is-paused .ProgressPlayButton-pauseButton {
  display: none;
}

@keyframes ProgressPlayButton-pausePulse {
  0%, 100% { background: var(--ProgressPlayButton-pausedBackgroundColor); }
  75% { background: #fff; }
}

.ProgressPlayButton.is-paused .ProgressPlayButton-playbackRingGlow {
  animation: ProgressPlayButton-pausePulse 3s ease-in infinite;;
}

/* @state is-playing
 * The player is playing back media of fixed length
 */
.ProgressPlayButton.is-playing .ProgressPlayButton-playButton {
  display: none;
}

.ProgressPlayButton.is-playing .ProgressPlayButton-pauseButton {
  display: block;
}

/* @state is-progressing
 * The player represends media of fixed length
 */
.ProgressPlayButton.is-progressing .ProgressPlayButton-playbackRingGlow,
.ProgressPlayButton.is-progressing .ProgressPlayButton-playbackMask {
  opacity: 1;
}

.ProgressPlayButton.is-progressing .ProgressPlayButton-playbackMask {
  transition: transform 72s linear; /* 1% of a 2 hour show is 72s */
}

/* 0-5% is 18deg */
.ProgressPlayButton.is-progressing .ProgressPlayButton-playbackMask--right {
  transform: rotate(18deg);
  opacity: 1;
}

.ProgressPlayButton.is-progressing[data-playback-percentage="0"] .ProgressPlayButton-playbackMask--right,
.ProgressPlayButton.is-progressing[data-playback-percentage="1"] .ProgressPlayButton-playbackMask--right,
.ProgressPlayButton.is-progressing[data-playback-percentage="2"] .ProgressPlayButton-playbackMask--right,
.ProgressPlayButton.is-progressing[data-playback-percentage="3"] .ProgressPlayButton-playbackMask--right,
.ProgressPlayButton.is-progressing[data-playback-percentage="4"] .ProgressPlayButton-playbackMask--right,
.ProgressPlayButton.is-progressing[data-playback-percentage="5"] .ProgressPlayButton-playbackMask--right {
  transition: transform 10s linear; /* iniital progress to fill bar goes faster */
}

.ProgressPlayButton.is-progressing[data-playback-percentage="6"] .ProgressPlayButton-playbackMask--right { transform: rotate(21.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="7"] .ProgressPlayButton-playbackMask--right { transform: rotate(25.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="8"] .ProgressPlayButton-playbackMask--right { transform: rotate(28.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="9"] .ProgressPlayButton-playbackMask--right { transform: rotate(32.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="10"] .ProgressPlayButton-playbackMask--right { transform: rotate(36deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="11"] .ProgressPlayButton-playbackMask--right { transform: rotate(39.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="12"] .ProgressPlayButton-playbackMask--right { transform: rotate(43.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="13"] .ProgressPlayButton-playbackMask--right { transform: rotate(46.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="14"] .ProgressPlayButton-playbackMask--right { transform: rotate(50.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="15"] .ProgressPlayButton-playbackMask--right { transform: rotate(54deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="16"] .ProgressPlayButton-playbackMask--right { transform: rotate(57.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="17"] .ProgressPlayButton-playbackMask--right { transform: rotate(61.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="18"] .ProgressPlayButton-playbackMask--right { transform: rotate(64.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="19"] .ProgressPlayButton-playbackMask--right { transform: rotate(68.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="20"] .ProgressPlayButton-playbackMask--right { transform: rotate(72deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="21"] .ProgressPlayButton-playbackMask--right { transform: rotate(75.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="22"] .ProgressPlayButton-playbackMask--right { transform: rotate(79.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="23"] .ProgressPlayButton-playbackMask--right { transform: rotate(82.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="24"] .ProgressPlayButton-playbackMask--right { transform: rotate(86.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="25"] .ProgressPlayButton-playbackMask--right { transform: rotate(90deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="26"] .ProgressPlayButton-playbackMask--right { transform: rotate(93.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="27"] .ProgressPlayButton-playbackMask--right { transform: rotate(97.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="28"] .ProgressPlayButton-playbackMask--right { transform: rotate(100.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="29"] .ProgressPlayButton-playbackMask--right { transform: rotate(104.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="30"] .ProgressPlayButton-playbackMask--right { transform: rotate(108deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="31"] .ProgressPlayButton-playbackMask--right { transform: rotate(111.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="32"] .ProgressPlayButton-playbackMask--right { transform: rotate(115.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="33"] .ProgressPlayButton-playbackMask--right { transform: rotate(118.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="34"] .ProgressPlayButton-playbackMask--right { transform: rotate(122.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="35"] .ProgressPlayButton-playbackMask--right { transform: rotate(126deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="36"] .ProgressPlayButton-playbackMask--right { transform: rotate(129.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="37"] .ProgressPlayButton-playbackMask--right { transform: rotate(133.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="38"] .ProgressPlayButton-playbackMask--right { transform: rotate(136.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="39"] .ProgressPlayButton-playbackMask--right { transform: rotate(140.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="40"] .ProgressPlayButton-playbackMask--right { transform: rotate(144deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="41"] .ProgressPlayButton-playbackMask--right { transform: rotate(147.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="42"] .ProgressPlayButton-playbackMask--right { transform: rotate(151.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="43"] .ProgressPlayButton-playbackMask--right { transform: rotate(154.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="44"] .ProgressPlayButton-playbackMask--right { transform: rotate(158.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="45"] .ProgressPlayButton-playbackMask--right { transform: rotate(162deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="46"] .ProgressPlayButton-playbackMask--right { transform: rotate(165.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="47"] .ProgressPlayButton-playbackMask--right { transform: rotate(169.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="48"] .ProgressPlayButton-playbackMask--right { transform: rotate(172.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="49"] .ProgressPlayButton-playbackMask--right { transform: rotate(176.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="50"] .ProgressPlayButton-playbackMask--right { transform: rotate(180deg); }

/* At 50%, the right-mask gets hidden. For cleanliness, set a dedicated data attr for this */
.ProgressPlayButton.is-progressing[data-playback-half="true"] .ProgressPlayButton-playbackMask--right {
  opacity: 0;
}

.ProgressPlayButton.is-progressing[data-playback-percentage="50"] .ProgressPlayButton-playbackMask--left { transform: rotate(0deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="51"] .ProgressPlayButton-playbackMask--left { transform: rotate(3.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="52"] .ProgressPlayButton-playbackMask--left { transform: rotate(7.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="53"] .ProgressPlayButton-playbackMask--left { transform: rotate(10.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="54"] .ProgressPlayButton-playbackMask--left { transform: rotate(14.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="55"] .ProgressPlayButton-playbackMask--left { transform: rotate(18deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="56"] .ProgressPlayButton-playbackMask--left { transform: rotate(21.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="57"] .ProgressPlayButton-playbackMask--left { transform: rotate(25.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="58"] .ProgressPlayButton-playbackMask--left { transform: rotate(28.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="59"] .ProgressPlayButton-playbackMask--left { transform: rotate(32.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="60"] .ProgressPlayButton-playbackMask--left { transform: rotate(36deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="61"] .ProgressPlayButton-playbackMask--left { transform: rotate(39.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="62"] .ProgressPlayButton-playbackMask--left { transform: rotate(43.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="63"] .ProgressPlayButton-playbackMask--left { transform: rotate(46.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="64"] .ProgressPlayButton-playbackMask--left { transform: rotate(50.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="65"] .ProgressPlayButton-playbackMask--left { transform: rotate(54deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="66"] .ProgressPlayButton-playbackMask--left { transform: rotate(57.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="67"] .ProgressPlayButton-playbackMask--left { transform: rotate(61.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="68"] .ProgressPlayButton-playbackMask--left { transform: rotate(64.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="69"] .ProgressPlayButton-playbackMask--left { transform: rotate(68.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="70"] .ProgressPlayButton-playbackMask--left { transform: rotate(72deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="71"] .ProgressPlayButton-playbackMask--left { transform: rotate(75.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="72"] .ProgressPlayButton-playbackMask--left { transform: rotate(79.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="73"] .ProgressPlayButton-playbackMask--left { transform: rotate(82.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="74"] .ProgressPlayButton-playbackMask--left { transform: rotate(86.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="75"] .ProgressPlayButton-playbackMask--left { transform: rotate(90deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="76"] .ProgressPlayButton-playbackMask--left { transform: rotate(93.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="77"] .ProgressPlayButton-playbackMask--left { transform: rotate(97.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="78"] .ProgressPlayButton-playbackMask--left { transform: rotate(100.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="79"] .ProgressPlayButton-playbackMask--left { transform: rotate(104.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="80"] .ProgressPlayButton-playbackMask--left { transform: rotate(108deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="81"] .ProgressPlayButton-playbackMask--left { transform: rotate(111.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="82"] .ProgressPlayButton-playbackMask--left { transform: rotate(115.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="83"] .ProgressPlayButton-playbackMask--left { transform: rotate(118.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="84"] .ProgressPlayButton-playbackMask--left { transform: rotate(122.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="85"] .ProgressPlayButton-playbackMask--left { transform: rotate(126deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="86"] .ProgressPlayButton-playbackMask--left { transform: rotate(129.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="87"] .ProgressPlayButton-playbackMask--left { transform: rotate(133.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="88"] .ProgressPlayButton-playbackMask--left { transform: rotate(136.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="89"] .ProgressPlayButton-playbackMask--left { transform: rotate(140.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="90"] .ProgressPlayButton-playbackMask--left { transform: rotate(144deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="91"] .ProgressPlayButton-playbackMask--left { transform: rotate(147.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="92"] .ProgressPlayButton-playbackMask--left { transform: rotate(151.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="93"] .ProgressPlayButton-playbackMask--left { transform: rotate(154.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="94"] .ProgressPlayButton-playbackMask--left { transform: rotate(158.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="95"] .ProgressPlayButton-playbackMask--left { transform: rotate(162deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="96"] .ProgressPlayButton-playbackMask--left { transform: rotate(165.6deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="97"] .ProgressPlayButton-playbackMask--left { transform: rotate(169.2deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="98"] .ProgressPlayButton-playbackMask--left { transform: rotate(172.8deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="99"] .ProgressPlayButton-playbackMask--left { transform: rotate(176.4deg); }
.ProgressPlayButton.is-progressing[data-playback-percentage="100"] .ProgressPlayButton-playbackMask--left { transform: rotate(180deg); }

/** @state is-seeking
  * Progress amount is changing by > 1% so the transition needs to happen faster
  */
.ProgressPlayButton.is-seeking .ProgressPlayButton-playbackMask {
  transition-duration: 500ms;
}
