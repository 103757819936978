/* @define PlayerSocialActions */

.PlayerSocialActions {
  display: flex;
  justify-content: space-between;
  font-size: 35px; /* Icon scaling */
}

.PlayerSocialActions-icon {
  border-color: transparent;
}

.PlayerSocialActions-icon:link:hover,
button.PlayerSocialActions-icon:hover,
.PlayerSocialActions-icon:focus {
  background-color: #49AEB7;
}
