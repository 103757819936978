/** @define AddReminder */
:root {
  --AddReminder-buttonTextColor: var(--bffBrandBlue);
}

.AddReminder-subscribeButton {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--AddReminder-buttonTextColor);
  cursor: pointer;
}

.AddReminder-subscribeButton:hover .AddReminder-label,
.AddReminder-subscribeButton:focus .AddReminder-label {
  text-decoration: underline;
}

.AddReminder-icon {
  margin-right: 5px;
}

.AddReminder-label {
  vertical-align: text-bottom;
}

.AddReminder-dialog {
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  opacity: 0;
}

.AddReminder.is-subscribing .AddReminder-dialog {
  clip: auto;
  width: auto;
  height: auto;

  transform: scale(1.0);
  opacity: 1;
}
