/* @define OfferStar */

.OfferStar {
  position: relative;

  /* Ensure label is in the middle of the star */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.OfferStar,
.OfferStar::before,
.OfferStar::after {
  height: 5em;
  width: 5em;
  background: inherit;
}

.OfferStar::before,
.OfferStar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}

.OfferStar::before {
  transform: rotate(30deg);
}

.OfferStar::after {
  transform: rotate(60deg);
}

.OfferStar-text {
  z-index: 1; /* Appear above the start points */
  text-transform: uppercase;

  color: inherit;
}
