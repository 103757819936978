/** @define Article */

:root {
  --Article-authorColor: var(--bffBrandRed);
  --Article-metadataColor: var(--accentTextColor);
  --Article-imageBorder: var(--accentBackground);
}

.Article-header {
  margin-bottom: 10px;
}

.Article-title {
  font-size: var(--fontSizeTitle);
  line-height: var(--fontHeightTitle);
  font-weight: var(--fontWeightBold);
  margin-bottom: 10px;
}

.Article-authorBlock {
  margin: 10px 0;
}

.Article-byline {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: uppercase;
  color: var(--Article-metadataColor);
  margin-top: 10px;
}

.Article-bylineItem {
  font-weight: var(--fontWeightThemeBold);
  color: var(--Article-authorColor);
}

.Article-with {
  text-transform: none;
}

.Article-hosts, .Article-guests {
  display: inline;
}

.Article-meta {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  color: var(--Article-metadataColor);
}

.Article-date {
  color: var(--Article-metadataColor);
}

.Article-categories {
  display: inline;
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  color: var(--Article-metadataColor);
}

.Article-categories::after {
  content: ' · ';
}

.Article-categories:last-child::after {
  content: '';
}

.Article-image {
  max-width: 100%;
  margin: 20px 0;
}

.Article-imageSource {
  max-width: 100%;
  border: 6px solid var(--Article-imageBorder);
  transform: translate(-6px);
}

.Article-broadcast {
  margin: 20px 0;
}

/* Podcasts */
.Article-player {
  margin: 30px 0;
}

/* Google Form iframes */
.Article-form {
  width: 100%;
  height: 600px;
  border: 0;
  margin: 20px 0;
}

.Article-neighbors {
  margin: 40px 0;
}

.Article-authorBio {
  margin: 40px 0;
}

@media (--smallWidth) {
  .Article-title {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    font-weight: var(--fontWeightBold);
  }

  .Article-byline {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .Article-meta {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
  }
}

/* @variant summaryHeader */

.Article--summaryHeader .Article-header {
  display: flex;
  flex-direction: column;
}

.Article--summaryHeader .Article-title {
  order: 2;
  margin-bottom: 0;
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
}

.Article--summaryHeader .Article-authorBlock,
.Article--summaryHeader .Article-byline {
  order: 3;
}

.Article--summaryHeader .Article-meta {
  order: 1;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightThemeBold);
  text-transform: uppercase;
  margin-bottom: 10px;
}
