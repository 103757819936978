/** @define DonationCampaign */

:root {
  --DonationCampaign-buttonColor: var(--bffBrandYellow);
  --DonationCampaign-buttonTextColor: var(--bffBrandBlack);
}

.DonationCampaign {}

.DonationCampaign-blurb {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}

.DonationCampaign-summary {
  flex-grow: 50;
}

.DonationCampaign-icon {
  flex-grow: 0;
  width: 80px;
}

.DonationCampaign-button {
  margin: 10px 0;
  width: 100%;
  background-color: var(--DonationCampaign-buttonColor);
  color: var(--DonationCampaign-buttonTextColor);
}
