/** @define GetNewsletter */

:root {
  --GetNewsletter-inputBackground: var(--accentBackground);
  --GetNewsletter-inputTextColor: var(--accentTextColor);
  --GetNewsletter-buttonBackground: var(--bffBrandYellow);
  --GetNewsletter-buttonTextColor: var(--bffBrandBlack);
}

@media (prefers-color-scheme: dark) {
  :root {
    --GetNewsletter-inputBackground: var(--regularBackground);
  }
}

.GetNewsletter {
  display: block;
}

.GetNewsletter-link:link:hover,
.GetNewsletter-link:link:focus {
  text-decoration: none;
}

.GetNewsletter-input {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  text-align: center;

  border: 0;
  background: var(--GetNewsletter-inputBackground);
  color: var(--GetNewsletter-inputTextColor);

  padding: 10px 30px;
}


.GetNewsletter-link:link:hover .GetNewsletter-input,
.GetNewsletter-link:link:focus .GetNewsletter-input {
  opacity: .7;
}

.GetNewsletter-button {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  text-align: center;

  border: 0;
  background: var(--GetNewsletter-buttonBackground);
  color: var(--GetNewsletter-buttonTextColor);

  padding: 10px 30px;
}

.GetNewsletter-link:link:hover .GetNewsletter-button,
.GetNewsletter-link:link:focus .GetNewsletter-button {
  text-decoration: underline;
}

.GetNewsletter-input,
.GetNewsletter-button {
  display: block;
  width: 100%;
  max-width: 230px;
  margin: 20px auto;
  border-radius: 4px;
}

/* @variant inline horizontal arrangement */
.GetNewsletter--inline .GetNewsletter-link {
  display: flex;
  justify-content: space-between;
}

.GetNewsletter--inline .GetNewsletter-input,
.GetNewsletter--inline .GetNewsletter-button {
  margin: 10px 0;
  width: auto;
  max-width: none;
}

.GetNewsletter--inline .GetNewsletter-input {
  flex-grow: 100;
  margin-right: 8px;
}

@media (--smallWidth) {
  .GetNewsletter--inline .GetNewsletter-link {
    flex-direction: column;
  }

  .GetNewsletter--inline .GetNewsletter-input {
    margin-right: 0;
  }
}
