/** @define WikiPage */

:root {
  --WikiPage-regularTextColor: var(--regularTextColor);
  --WikiPage-sidebarColor: var(--accentTextColor);
  --WikiPage-sidebarBgColor: var(--accentBackground);
  --WikiPage-nameHighlight: var(--bffBrandRed);
}

.WikiPage {}

/* Flex layout for vertical alignment of imagery */
.WikiPage-header {
  display: flex;
  align-items: stretch;
}

.WikiPage-banner {
  flex-basis: 100%;
  flex-shrink: 0;
}

.WikiPage-metaHeading {
  color: var(--WikiPage-regularTextColor);
  font-size: var(--fontSizeTitle);
  line-height: var(--fontHeightTitle);
  font-weight: var(--fontWeightBold);
}

.WikiPage-metaSubHeading {
  color: var(--WikiPage-regularTextColor);
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  margin: 20px 0;
}

.WikiPage-lede {
  margin: 20px 0;
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
}

/* Summary Stats Bar */
.WikiPage-summaryStats {
  margin: 20px 0 40px;
}

.WikiPage-nameHighlight {
  color: var(--WikiPage-nameHighlight);
}

/* Text and Info About Section */
.WikiPage-about,
.WikiPage-stats {
  margin: 20px 0;
}

.WikiPage-description {
  margin: 20px 0;
}

.WikiPage-footnotes {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 10px 0;
}

/* The info box aside the text about the artist */
.WikiPage-aboutInfo {
  flex-basis: 380px;
  flex-shrink: 50;
  min-width: 300px;
}

.WikiPage-aboutInfoBox {
  background: var(--WikiPage-sidebarBgColor);
  color: var(--WikiPage-sidebarColor);
  padding: 10px 20px;
  margin-bottom: 20px;
}

.WikiPage-websiteLink {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 10px 0;
}

.WikiPage-socialIcons {
  margin: 20px 0;
}

.WikiPage-recommendations {
  margin-top: 20px;
  margin-bottom: 20px;
}

.WikiPage-associatedArtists {
  margin: 20px 0;
}

.WikiPage-associatedLabels {
  margin: 20px 0;
}

.WikiPage-contributors {
  --WikiContributors-avatarBorderColor: var(--regularBackground);

  margin: 20px 0;
  background: var(--accentBackground);
  padding: 20px;
}

@media (--mediumWidth) {
  .WikiPage-metaHeading {
    font-size: var(--fontSizeXL);
    line-height: var(--fontHeightXL);
  }
}

@media (--smallWidth) {
  .WikiPage-breadcrumb {
    display: none;
  }

  .WikiPage-metaHeading {
    font-size: var(--fontSizeL);
    line-height: var(--fontHeightL);
  }

  .WikiPage-metaSubHeading {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }

  .WikiPage-lede {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }

  .WikiPage-contributors {
    margin: 10px 0;
    padding: 10px;
  }
}
