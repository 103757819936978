/** @define HorizontalBar */

:root {
  --HorizontalBar-background: var(--bffBrandRedAlpha);
  --HorizontalBar-fill: var(--bffBrandRed);
}

.HorizontalBar {
  width: 100%;
  height: 10px;
  background: var(--HorizontalBar-background);
  border-radius: 5px;
  overflow: hidden;
}

.HorizontalBar-fill {
  height: 10px;
  background: var(--HorizontalBar-fill);
}
