/** @define Broadcast */

@import "../components/blockplayer";
@import "../components/article-neighbors";
@import "../components/breadcrumb";
@import "../components/tracklisting";
@import "../components/tracklist-item";
@import "../components/transcript";

:root {
  --Broadcast-metadataColor: var(--regularTextColor);
  --Broadcast-imageBorderColor: var(--accentBackground);
  --Broadcast-supportButtonColor: var(--accentBackground);
  --Broadcast-offsetTextColor: var(--accentTextColor);
  --Broadcast-linkColor: var(--bffBrandBlue);
}

.Broadcast {}

.Broadcast-breadcrumb {
  margin: 0 0 20px;
  color: var(--Broadcast-metadataColor);
}

.Broadcast-title {
  font-size: var(--fontSizeTitle);
  line-height: var(--fontHeightTitle);
  font-weight: var(--fontWeightBold);
  margin: 10px 0;
}

.Broadcast-metadata {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--Broadcast-metadataColor);
  margin: 20px 0;
}

.Broadcast-with {
  text-transform: lowercase;
}

.Broadcast-with:first-child {
  text-transform: uppercase;
}

.Broadcast-guests,
.Broadcast-hosts {
  display: inline;
}

.Broadcast-image {
  max-width: 100%;
  margin: 20px 0;
}

.Broadcast-imageSource {
  max-width: 100%;
  border: 6px solid var(--Broadcast-imageBorderColor);
  transform: translate(-6px);
}

.Broadcast-transcriptLink {
  color: var(--Broadcast-offsetTextColor);
  font-weight: var(--fontWeightBold);
  font-style: italic;
  margin: 20px 0;
}

.Broadcast-transcriptLink a {
  color: var(--Broadcast-linkColor);
}

.Broadcast-player {
  margin: 20px 0;
}

.Broadcast-support {
  margin: 20px 0;
}

.Broadcast-inlineSubscribe {
  background-color: var(--Broadcast-supportButtonColor);
}

.Broadcast-credits {
  margin: 20px 0;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-style: italic;
}

@media (--smallWidth) {
  .Broadcast-title {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    font-weight: var(--fontWeightBold);
  }

  .Broadcast-metadata {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .Broadcast-byline {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .Broadcast-credits {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}
