/** @define Icon */

:root {
  --Icon-circleWidth: 1px;
  --Icon-circleBorderColor: var(--regularTextColor);
  --Icon-circleHighlightBorderColor: var(--bffBrandBlack);

  --Icon-twitterBackground: #1DA1F2;
  --Icon-facebookBackground: #1877F2;
  --Icon-instagramBackground: #e9478b;
  --Icon-mastodonBackground: linear-gradient(#6364FF, #563ACC);
  --Icon-lastfmBackground: #d50000;
  --Icon-mixcloudBackground: #273a4b;
  --Icon-soundcloudBackground: linear-gradient(#f79810, #f8310e);
  --Icon-spotifyBackground: #2Ed153;
  --Icon-appleMusicBackground: #d60017;
  --Icon-youtubeBackground: #fafafa;
  --Icon-bandcampBackground: #1DA0C3;
  --Icon-blueskyBackground: #0085ff;
  --Icon-tiktokBackground: #121212;
  --Icon-threadsBackground: #101010;

  --Icon-podcastsBackground: #a51ccc;
  --Icon-googleplayBackground: #27d0fd;
  --Icon-playerfmBackground: #b70024;
  --Icon-tuneinBackground: #1c203c;
  --Icon-breakerBackground: #0a2b9a;
  --Icon-pocketcastsBackground: #eB2c2f;
  --Icon-overcastBackground: #fc7e0f;
  --Icon-castroBackground: radial-gradient(circle at 51% 51%,#151515 0,#151515 57%,rgba(0,0,0,0) 57%), linear-gradient(#00ccbf,#00b265);
  --Icon-googlePodcastBackground: #ffffff;
  --Icon-amazonBackground: #ff9900;
  --Icon-stitcherBackground: #101010;
  --Icon-rssBackground: #e06321;

  --Icon-feedlyBackground: #2bb24c;
  --Icon-feedbinBackground: #2b82fc;
  --Icon-newsblurBackground: linear-gradient(#d6953a, #aa4922);
  --Icon-oldreaderBackground: #fb0007;

  --Icon-googleCalendarBackground: #3d75ee;
  --Icon-icalBackground: #df4042;
  --Icon-outlookBackground: #125eb5;
}

.Icon {
  display: inline-block;
  width: 1em;
  height: 1em;

  /* SVGs are black masks, so we expect implementation to use opacity to grey them out.
   * If hovered on, they will become full-color and fade in */
  transition: opacity .2s ease-out;
}

.Icon--encircled {
  border: var(--Icon-circleWidth) solid var(--Icon-circleBorderColor);
  border-radius: 50%;
}

.Icon-glyph {
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  padding: 0;
  background-size: 1em 1em;
  background-repeat: no-repeat;
  background-position: 50%;
}

.Icon--encircled .Icon-glyph {
  width: 0.5em;
  height: 0.5em;
  padding: 0.5em;
  background-size: 0.55em 0.55em;
  transform: translate(calc(var(--Icon-circleWidth) / -2), calc(var(--Icon-circleWidth) / -2));
}

/* override facebook size */
.Icon--encircled.Icon--facebook .Icon-glyph {
  width: 0.7em;
  height: .7em;
  background-size: .75em .75em;
  transform: translate(calc(var(--Icon-circleWidth) / -2), 2.5px);
}

/* @variant white forces glyphs to be displayed white rather than default dark colour by inverting with CSS filter. */
@media (prefers-color-scheme: light) {
  .Icon--white .Icon-glyph {
    filter: invert(1) brightness(150%);
  }
}

/* icon.svg.css:
.Icon--<network> .Icon-glyph {
  background-image: url('../assets/icons/social-twitter.svg');
  background-image: url('../assets/icons/social-facebook.svg');
  background-image: url('../assets/icons/social-instagram.svg');
}
*/

.Icon:link:hover,
.Icon:link:focus {
  opacity: 1.0;
}

.Icon--encircled:link:hover,
.Icon--encircled:link:focus {
  border-color: var(--Icon-circleHighlightBorderColor);
}

/* On hover, these icons display coloured backgrounds (defined in icon.css) so always white */
@media (prefers-color-scheme: light) {
  .Icon--encircled:link:hover .Icon-glyph,
  .Icon--encircled:link:focus .Icon-glyph {
    filter: invert(1) brightness(150%);
  }
}

@media (prefers-color-scheme: dark) {
  .Icon--encircled:link:hover .Icon-glyph,
  .Icon--encircled:link:focus .Icon-glyph {
    filter: brightness(150%);
  }
}

.Icon--encircled.Icon--twitter:link:hover,
.Icon--encircled.Icon--twitter:link:focus {
  background-color: var(--Icon-twitterBackground);
}

.Icon--encircled.Icon--facebook:link:hover,
.Icon--encircled.Icon--facebook:link:focus {
  background-color: var(--Icon-facebookBackground);
}

.Icon--encircled.Icon--instagram:link:hover,
.Icon--encircled.Icon--instagram:link:focus {
  background-color: var(--Icon-instagramBackground);
}

.Icon--encircled.Icon--mastodon:link:hover,
.Icon--encircled.Icon--mastodon:link:focus {
  background: var(--Icon-mastodonBackground);
}

.Icon--encircled.Icon--youtube:link:hover,
.Icon--encircled.Icon--youtube:link:focus {
  background-color: var(--Icon-youtubeBackground);
}

.Icon--encircled.Icon--bandcamp:link:hover,
.Icon--encircled.Icon--bandcamp:link:focus {
  background-color: var(--Icon-bandcampBackground);
}

.Icon--encircled.Icon--lastfm:link:hover,
.Icon--encircled.Icon--lastfm:link:focus {
  background-color: var(--Icon-lastfmBackground);
}

.Icon--encircled.Icon--mixcloud:link:hover,
.Icon--encircled.Icon--mixcloud:link:focus {
  background-color: var(--Icon-mixcloudBackground);
}

.Icon--encircled.Icon--soundcloud:link:hover,
.Icon--encircled.Icon--soundcloud:link:focus {
  background-image: var(--Icon-soundcloudBackground);
}

.Icon--encircled.Icon--applemusic:link:hover,
.Icon--encircled.Icon--applemusic:link:focus {
  background-color: var(--Icon-appleMusicBackground);
}

.Icon--encircled.Icon--spotify:link:hover,
.Icon--encircled.Icon--spotify:link:focus {
  background-color: var(--Icon-spotifyBackground);
}

.Icon--encircled.Icon--itunes:link:hover,
.Icon--encircled.Icon--itunes:link:focus {
  background-color: var(--Icon-podcastsBackground);
}

.Icon--encircled.Icon--google:link:hover,
.Icon--encircled.Icon--google:link:focus {
  background-color: var(--Icon-googleplayBackground);
}

.Icon--encircled.Icon--playerfm:link:hover,
.Icon--encircled.Icon--playerfm:link:focus {
  background-color: var(--Icon-playerfmBackground);
}

.Icon--encircled.Icon--breaker:link:hover,
.Icon--encircled.Icon--breaker:link:focus {
  background-color: var(--Icon-breakerBackground);
}

.Icon--encircled.Icon--pocketcasts:link:hover,
.Icon--encircled.Icon--pocketcasts:link:focus {
  background-color: var(--Icon-pocketcastsBackground);
}

.Icon--encircled.Icon--overcast:link:hover,
.Icon--encircled.Icon--overcast:link:focus {
  background-color: var(--Icon-overcastBackground);
}

.Icon--encircled.Icon--castro:link:hover,
.Icon--encircled.Icon--castro:link:focus {
  background-image: var(--Icon-castroBackground);
}

.Icon--encircled.Icon--googlepodcast:link:hover,
.Icon--encircled.Icon--googlepodcast:link:focus {
  background-color: var(--Icon-googlePodcastBackground);
}

.Icon--encircled.Icon--stitcher:link:hover,
.Icon--encircled.Icon--stitcher:link:focus {
  background-color: var(--Icon-stitcherBackground);
}

.Icon--encircled.Icon--amazon:link:hover,
.Icon--encircled.Icon--amazon:link:focus {
  background-color: var(--Icon-amazonBackground);
}

.Icon--encircled.Icon--googleCalendar:link:hover,
.Icon--encircled.Icon--googleCalendar:link:focus {
  background-color: var(--Icon-googleCalendarBackground);
}

.Icon--encircled.Icon--ical:link:hover,
.Icon--encircled.Icon--ical:link:focus {
  background-color: var(--Icon-icalBackground);
}

.Icon--encircled.Icon--outlook:link:hover,
.Icon--encircled.Icon--outlook:link:focus {
  background-color: var(--Icon-outlookBackground);
}

.Icon--encircled.Icon--tunein:link:hover,
.Icon--encircled.Icon--tunein:link:focus {
  background-color: var(--Icon-tuneinBackground);
}

.Icon--encircled.Icon--rss:link:hover,
.Icon--encircled.Icon--rss:link:focus {
  background-color: var(--Icon-rssBackground);
}

.Icon--encircled.Icon--feedly:link:hover,
.Icon--encircled.Icon--feedly:link:focus {
  background-color: var(--Icon-feedlyBackground);
}

.Icon--encircled.Icon--feedbin:link:hover,
.Icon--encircled.Icon--feedbin:link:focus {
  background-color: var(--Icon-feedbinBackground);
}

.Icon--encircled.Icon--newsblur:link:hover,
.Icon--encircled.Icon--newsblur:link:focus {
  background: var(--Icon-newsblurBackground);
}

.Icon--encircled.Icon--oldreader:link:hover,
.Icon--encircled.Icon--oldreader:link:focus {
  background-color: var(--Icon-oldreaderBackground);
}

.Icon--encircled.Icon--email:link:hover,
.Icon--encircled.Icon--email:link:focus {
  background-color: var(--Icon-facebookBackground);
}

.Icon--encircled.Icon--share:link:hover,
.Icon--encircled.Icon--share:link:focus {
  background-color: var(--Icon-facebookBackground);
}

.Icon--encircled.Icon--bluesky:link:hover,
.Icon--encircled.Icon--bluesky:link:focus {
  background-color: var(--Icon-blueskyBackground);
}

.Icon--encircled.Icon--tiktok:link:hover,
.Icon--encircled.Icon--tiktok:link:focus {
  background-color: var(--Icon-tiktokBackground);
}

.Icon--encircled.Icon--threads:link:hover,
.Icon--encircled.Icon--threads:link:focus {
  background-color: var(--Icon-threadsBackground);
}

/* Glyph background images declared in icon.svg.css */

/* Animation */
@keyframes Icon-spinning {
  0% {
    transform: rotate(0);
    opacity: 1.0;
  }
  50% {
    opacity: .7;
  }
  100% {
    transform: rotate(-359deg);
    opacity: 1.0;
  }
}

.Icon--spinning .Icon-glyph {
  animation: Icon-spinning 1.5s linear infinite;
}
