/** @define DonationConfirmation */

:root {
  --DonationConfirmation-backgroundColor: var(--regularBackground);
  --DonationConfirmation-textColor: var(--regularTextColor);
  --DonationConfirmation-designationTextColor: var(--bffBrandRed);
  --DonationConfirmation-buttonBackgroundColor: var(--bffBrandYellow);
  --DonationConfirmation-buttonTextColor: var(--bffBrandBlack);
}

.DonationConfirmation {
  position: relative;
}

.DonationConfirmation-confirm {
  text-align: left;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
}

.DonationConfirmation-dialog {
  background: var(--DonationConfirmation-backgroundColor);
  color: var(--DonationConfirmation-textColor);
  padding: 20px;
  max-width: 450px;
}

.DonationConfirmation-heading {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  margin: 0 0 10px;
}

.DonationConfirmation-desc {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: none;
  margin: 10px 0 0;
}

.DonationConfirmation-rewardBlurb {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: none;
  margin: 20px 0;
}

.DonationConfirmation-image {
  display: block;
  margin: 0 auto 20px;
  width: 150px;
}

.DonationConfirmation-alert,
.DonationConfirmation-alertInvert {
  padding: 10px 20px 1px;
  margin: 20px 0;
}


.DonationConfirmation-alert {
  background: var(--DonationPrompt-designationTextColor);
  color: #fff;
  /* HACK: 1px bottom margin to ensure internal paragraph margin is applied correctly */
}

.DonationConfirmation-alertInvert {
  color: var(--DonationConfirmation-designationTextColor);
  background: transparent;
  /* HACK: This component is getting overloaded and should be broken up in future */
  padding: 0 20px;
}

.DonationConfirmation-reward {
  display: none;
}

.DonationConfirmation-rewardHighlight,
.DonationConfirmation-rewardAmount {
  text-transform: uppercase;
  color: var(--DonationPrompt-designationTextColor);
}

.DonationConfirmation-rewardOption {
  text-align: center;
}

.DonationConfirmation-rewardOptionButton {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  background-color: var(--DonationConfirmation-buttonBackgroundColor);
  color: var(--DonationConfirmation-buttonTextColor);
}

.DonationConfirmation-learnMore {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
}

/* @state is-rewarding When a donation is made that qualigfies for a reward */
.DonationConfirmation.is-rewarding .DonationConfirmation-reward {
  display: block;
}

/* @state is-donating Show the dialog after donation is performed */
.DonationConfirmation.is-showing .DonationConfirmation-confirm {
  clip: auto;
  width: auto;
  height: auto;

  transform: scale(1.0);
  opacity: 1;
}
