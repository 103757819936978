/** @define PostBanner */

:root {
  --PostBanner-textColor: var(--onColorTextColor);
  --PostBanner-categoryColor: var(--bffBrandRed);
  --PostBanner-loclCategoryColor: var(--bffBrandBlue);
  --PostBanner-loclCategoryTextColor: var(--bffBrandBlack);
  --PostBanner-thumbnailBorderColor: var(--regularBackground);
}

.PostBanner {
  min-height: 480px;
  position: relative;
  background-color: var(--bffBrandBlue);
}

.PostBanner-backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PostBanner-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  color: var(--PostBanner-textColor);
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.01) 9.5%,
    hsla(0, 0%, 0%, 0.039) 18.5%,
    hsla(0, 0%, 0%, 0.083) 27%,
    hsla(0, 0%, 0%, 0.14) 35%,
    hsla(0, 0%, 0%, 0.207) 42.6%,
    hsla(0, 0%, 0%, 0.282) 49.7%,
    hsla(0, 0%, 0%, 0.36) 56.5%,
    hsla(0, 0%, 0%, 0.44) 62.9%,
    hsla(0, 0%, 0%, 0.518) 69%,
    hsla(0, 0%, 0%, 0.593) 74.7%,
    hsla(0, 0%, 0%, 0.66) 80.2%,
    hsla(0, 0%, 0%, 0.717) 85.5%,
    hsla(0, 0%, 0%, 0.761) 90.5%,
    hsla(0, 0%, 0%, 0.79) 95.3%,
    hsla(0, 0%, 0%, 0.8) 100%
  );

  height: 100%;
  padding: 40px 40px 80px;
  padding-left: max(env(safe-area-inset-left), 40px);
}

.PostBanner-title {
  font-size: var(--fontSizeBanner);
  line-height: var(--fontHeightBanner);
  font-weight: var(--fontWeightBold);
  margin: 0 0 20px;
  max-width: 55%;
  text-shadow: rgba(0,0,0,0.5) 0 1px 2px;
}

.PostBanner-category,
.PostBanner-localTag {
  display: inline-block;
  background: var(--PostBanner-categoryColor);
  border-radius: 2px;
  font-size: var(--fontSizeS);
  line-height: var(--fontSizeS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  padding: 6px 16px;
  margin: 0 0 20px;
}

.PostBanner-localTag {
  background: var(--PostBanner-loclCategoryColor);
  color: var(--PostBanner-loclCategoryTextColor);
}

.PostBanner-summary {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  max-width: 55%;
  text-shadow: rgba(0,0,0,0.5) 0 1px 2px;
}

.PostBanner-readMore {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  white-space: nowrap;
}

.PostBanner-readMore::after {
  content: ' →';
}

.PostBanner-attribution {
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);
}

.PostBanner-listen {
  margin: 20px 0 0;
}

.PostBanner-listenLabel {
  display: inline-block;
  vertical-align: text-bottom;
  padding: 2px 10px;

  font-size: var(--fontSizeS);
  line-height: 24px; /* To match the player-button */
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
}

.PostBanner-thumbnail {
  position: absolute;
  right: 40px;
  bottom: -40px;

  background: var(--PostBanner-thumbnailBorderColor);
  border: 6px var(--PostBanner-thumbnailBorderColor) solid;

  width: 300px;
  height: 300px;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.45);

  border-radius: 2px;
}

.PostBanner-thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/** @variant compact smaller text */
.PostBanner--compact .PostBanner-title {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  margin-bottom: 10px;
  max-width: 100%;
}

.PostBanner--compact .PostBanner-summary {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}

/** @variant slim reduce the min-height of the header for when there is no background image */
.PostBanner--slim {
  min-height: 240px;
}

/** @variant withThumbnail reserve wrapping space within the content region */
.PostBanner--withThumbnail {
  min-height: 480px;
}

.PostBanner--withThumbnail .PostBanner-content {
  padding-right: 320px;
}

@media (--midiWidth) {
  .PostBanner-title {
    font-size: var(--fontSizeTitle);
    line-height: var(--fontHeightTitle);
    max-width: 85%;
  }

  .PostBanner-summary {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    max-width: 85%;
  }

  .PostBanner-thumbnail {
    width: 200px;
    height: 200px;
  }

  .PostBanner--withThumbnail .PostBanner-content {
    padding-right: 220px;
  }

  .PostBanner--compact .PostBanner-title {
    font-size: var(--fontSizeL);
    line-height: var(--fontHeightL);
    max-width: 100%;
  }

  .PostBanner--compact .PostBanner-summary {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
  }
}

@media (--smallWidth) {
  .PostBanner {
    min-height: 380px;
  }

  .PostBanner-content,
  .PostBanner--withThumbnail .PostBanner-content {
    padding: 20px 20px 60px;
  }

  .PostBanner-thumbnail {
    position: relative;
    right: auto;
  }

  .PostBanner-title {
    margin: 0 0 10px;
    max-width: 100%;
  }

  .PostBanner-category,
  .PostBanner-localTag {
    font-size: var(--fontSizeXS);
    line-height: var(--fontSizeXS);
    padding: 4px 8px;
    margin: 0 0 10px;
  }

  .PostBanner-summary {
    max-width: 100%;
  }
}
