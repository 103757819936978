/** @define HomePageBlocks */

:root {
  --HomePageBlocks-gutter: 20px;
  --HomePageBlocks-backgroundColor: var(--regularBackground);
  --HomePageBlocks-maskColor:var(--accentBackground);
}

@media (prefers-color-scheme: dark) {
  :root {
    --HomePageBlocks-backgroundColor: var(--accentBackground);
    --HomePageBlocks-maskColor:  var(--regularBackground);
  }
}

.HomePageBlocks {
  background: var(--HomePageBlocks-backgroundColor);

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  align-items: stretch;

  margin: 20px 0;

  /* Compensate for the :even blocks getting pulled negative-left by intending */
  padding-left: var(--HomePageBlocks-gutter);
}

.HomePageBlocks-block {
  box-sizing: border-box;
  flex-basis: 50%;

  border: var(--HomePageBlocks-gutter) solid var(--HomePageBlocks-maskColor);
  border-width: 0 var(--HomePageBlocks-gutter) 0 0;
  padding: 0 20px 0 0;
}

.HomePageBlocks-block:nth-child(even) {
  margin-left: calc(-1 * var(--HomePageBlocks-gutter));
  border-width: 0 0 0 var(--HomePageBlocks-gutter);
  padding: 0 0 0 20px;
}

@media (--mediumWidth) {
  /* At medium width, the page background turns white, so the grey faux-mask needs to disappear */
  .HomePageBlocks-block,
  .HomePageBlocks-block:nth-child(even) {
    border-color: var(--HomePageBlocks-backgroundColor);
  }
}

@media (--smallWidth) {
  /* At small width we move into single-column, and the blocks are separated into their own modules */
  .HomePageBlocks {
    background: transparent;
    padding: 0;
  }

  .HomePageBlocks-block,
  .HomePageBlocks-block:nth-child(even) {
    flex-basis: 100%;

    background: var(--HomePageBlocks-backgroundColor);

    margin: 20px 0;
    padding: 0 20px;
    border: none;
  }
}
