/** @define Popover */

.Popover {
  position: relative;
  z-index: 6000;

  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-top: 10px;
}

.Popover-arrow {
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 6000;

  transform: rotate(45deg);
  background-color: inherit;

  width: 20px;
  height: 20px;

  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.Popover-content {
  background-color:inherit;
  position: relative;
  z-index: 6100;
  border-radius: 5px;
}

.Popover--arrowTop {

}