/** @define PodcastFeature */

:root {
  --PodcastFeature-maxWidth: var(--contentMaxWidth);
  --PodcastFeature-horizontalPadding: var(--contentStandardPadding);
  --PodcastFeature-verticalMargin: var(--contentStandardPadding);
}

.PodcastFeature {
  padding: 0 20px;
}

@media (--fullBleed) {
  .PodcastFeature {
    padding: 0;
  }
}
