/** @define PodcastInfo */

:root {
  --PodcastInfo--titleColor: var(--regularTextColor);
  --PodcastInfo--captionColor: var(--accentTextColor);
  --PodcastInfo--buttonBackground: var(--accentBackground);
}

.PodcastInfo {
  position: relative;
  margin-bottom: 20px;
  padding: 10px 0;
}

.PodcastInfo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PodcastInfo-title {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  color: var(--PodcastInfo--titleColor);
}

.PodcastInfo-caption {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  color: var(--PodcastInfo--captionColor);
}

.PodcastInfo-button {
  margin-left: 15px;
  background-color: var(--PodcastInfo--buttonBackground);
}

@media (--smallWidth) {
  .PodcastInfo-title {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .PodcastInfo-caption {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}
