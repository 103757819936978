/** @define PageContainer */

:root {
  --PageContainer-maxWidth: var(--contentMaxWidth);
  --PageContainer-horizontalPadding: var(--contentStandardPadding);
  --PageContainer-verticalMargin: var(--contentStandardPadding);
}

.PageContainer {
  box-sizing: border-box;
  margin: 0 auto;
  padding: var(--PageContainer-verticalMargin) var(--PageContainer-horizontalPadding);
  padding-left: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-left));
  padding-right: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-right));

  width: 100%;
  max-width: var(--PageContainer-maxWidth);
}

/** @variant textWidth
  * Line length limited for text legibility
  */
.PageContainer--textWidth {
  max-width: 52em;
}

/** @variant bleedLeft
  * Content bleeds to the left
  */
.PageContainer--bleedLeft {
  padding-left: 0;
}

/** @variant bleedRight
  * Content bleeds to the right
  */
.PageContainer--bleedRight {
  padding-right: 0;
}

/** @variant bleedTop
  * Content bleeds above
  */
.PageContainer--bleedTop {
  padding-top: 0;
}

/** @variant contentIndent
  * Additional indentation for content pages
  */
.PageContainer--contentIndent {
  padding: var(--PageContainer-verticalMargin) calc(var(--PageContainer-horizontalPadding) + 100px);
}

/** @variant contentIndentBleedable
  * Compatible with contentIndent at regular widths, but bleeds to the page edge ala bleedLeft/right
  */
.PageContainer--contentIndentBleedable {
  padding: var(--PageContainer-verticalMargin) calc(var(--PageContainer-horizontalPadding) + 80px);
}

.PageContainer--contentIndentBleedable .PageContainer-noBleed {
  padding: 0 20px;
}

@media (--midiWidth) {
  .PageContainer--contentIndent {
    padding: var(--PageContainer-verticalMargin) calc(var(--PageContainer-horizontalPadding) + 40px);
  }

  .PageContainer--contentIndentBleedable {
    padding: var(--PageContainer-verticalMargin) calc(var(--PageContainer-horizontalPadding) + 20px);
  }

  .PageContainer--contentIndentBleedable.PageContainer--bleedLeft {
    padding-left: 0;
  }

  .PageContainer--contentIndentBleedable.PageContainer--bleedRight {
    padding-right: 0;
  }

  .PageContainer--contentIndentBleedable.PageContainer--bleedLeft .PageContainer-noBleed {
    padding-left: calc(var(--PageContainer-horizontalPadding) + 40px);
  }

  .PageContainer--contentIndentBleedable.PageContainer--bleedRight .PageContainer-noBleed {
    padding-right: calc(var(--PageContainer-horizontalPadding) + 40px);
  }
}

@media (--smallWidth) {
  .PageContainer--contentIndent {
    padding: var(--PageContainer-verticalMargin) var(--PageContainer-horizontalPadding);
    padding-left: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-left));
    padding-right: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-right));
  }

  .PageContainer--contentIndentBleedable {
    padding: var(--PageContainer-verticalMargin) var(--PageContainer-horizontalPadding);
    padding-left: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-left));
    padding-right: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-right));
  }

  .PageContainer--contentIndentBleedable .PageContainer-noBleed {
    padding: 0 var(--PageContainer-horizontalPadding);
    padding-left: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-left));
    padding-right: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-right));
  }

  .PageContainer--contentIndentBleedable.PageContainer--bleedLeft .PageContainer-noBleed {
    padding-left: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-left));
  }

  .PageContainer--contentIndentBleedable.PageContainer--bleedRight .PageContainer-noBleed {
    padding-right: max(var(--PageContainer-horizontalPadding), env(safe-area-inset-right));
  }
}
