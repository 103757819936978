/** @define ShowListItem */

.ShowListItem {}

.ShowListItem-title {
  display: block;
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
}

.ShowListItem-context {
  display: block;
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  text-transform: uppercase
}
