/* @define Pagination */

:root {
  --Pagination-highlightColor: var(--bffBrandBlue);
  --Pagination-offsetColor: var(--accentTextColor);
  --Pagination-faintColor: var(--lightGreyTextColor);
}

.Pagination {
  margin: 10px auto;
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);

  display: flex;
  justify-content: space-between;

  text-align: center;
}

.Pagination-pages {
  flex-shrink: 50;
  display: flex;
  overflow-x: scroll;
}

.Pagination-item {
  display: inline-block;
  padding: 0 15px;
}

.Pagination-separator {
  color: var(--Pagination-faintColor);
}

.Pagination-label,
.Pagination-previous,
.Pagination-next {
  flex-shrink: 0;
  white-space: nowrap;

  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  text-transform: uppercase;
  color: var(--Pagination-offsetColor);
}

.Pagination-label {
  color: var(--Pagination-faintColor);
  text-transform: none;
}

.Pagination-previous:hover,
.Pagination-previous:active,
.Pagination-previous:focus,
.Pagination-next:hover,
.Pagination-next:active,
.Pagination-next:focus {
  text-decoration: none;
  color: var(--Pagination-highlightColor);
}

.Pagination-previous::before,
.Pagination-next::after {
  font-size: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: text-bottom;
  font-weight: var(--fontWeightBold);
  color: var(--Pagination-highlightColor);
}

.Pagination-previous::before {
  content: "< ";
  padding: 0 6px 0 0;
}

.Pagination-next::after {
  content: " >";
  padding: 0 0 0 6px;
}

.Pagination-currentPage {
  font-weight: var(--fontWeightBold);
  color: var(--Pagination-highlightColor);
}
