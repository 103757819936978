/** @define ComingUpBar */

.ComingUpBar {
  padding: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.ComingUpBar-label {}
.ComingUpBar-info {}

.ComingUpBar-title {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
}
.ComingUpBar-summary {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}
