/** @define BroadcastSummary */

:root {
  --BroadcastSummary-headlineColor: var(--regularTextColor);
  --BroadcastSummary-showColor: var(--bffBrandBlue);
  --BroadcastSummary-bodyColor: var(--accentTextColor);
  --BroadcastSummary-metadataColor: var(--accentTextColor);
}

.BroadcastSummary {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.BroadcastSummary-title {
  order: 1;

  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
  font-weight: var(--fontWeightBold);
  color: var(--BroadcastSummary-headlineColor);
  margin: 0 0 1px 0;
}

.BroadcastSummary-show {
  order: 2;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--BroadcastSummary-showColor);

  margin: 1px 0 3px;
}

.BroadcastSummary-people {
  order: 2;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  text-transform: uppercase;
  margin: 1px 0 3px;
}

.BroadcastSummary-host,
.BroadcastSummary-guest {
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--BroadcastSummary-showColor);
}

.BroadcastSummary-metadata {
  order: 3;
  margin: 0 0 10px;

  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  color: var(--BroadcastSummary-metadataColor);
}

.BroadcastSummary-description {
  order: 4;

  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 10px 10px 15px 0; /* Ends up looking more like 15px after line heights */
  color: var(--BroadcastSummary-bodyColor);

  /* Use experimentla line-clamp to get the boxes to be more consistent sizes */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BroadcastSummary-categories {
  order: 5;

  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightBold);
  color: var(--BroadcastSummary-metadataColor);
  text-transform: uppercase;
}

@media (--smallWidth) {
  .BroadcastSummary {
    text-align: center;
  }

  .BroadcastSummary-description {
    margin: 10px 15px 15px 15px;
  }

  .BroadcastSummary--leftAlign {
    text-align: left;
  }

  .BroadcastSummary--leftAlign .BroadcastSummary-description {
    margin: 10px 10px 10px 0;
  }
}
