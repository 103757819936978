/** @define MusicHome */

:root {
  --MusicHome-linkColor: var(--bffBrandBlue);
  --MusicHome-nextBarBackground: var(--accentBackground);
  --MusicHome-nextBarText: var(--regularTextColor);
}

.MusicHome-heading {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin-bottom: 20px;
}

.MusicHome-description {
  margin-bottom: 20px;
}

.MusicHome-showHeader {
  margin: 20px 0 10px;
}

.MusicHome-bannerContainer {
  display: flex;
  align-items: stretch;
}

.MusicHome-bannerContent {
  flex-basis: 100%;
}

.MusicHome-onAir {
  flex-basis: 66%;
}

.MusicHome-upNext {
  flex-basis: 34%;
}

.MusicHome-upNextSlim {
  display: none;
  padding: 20px 40px;
  background: var(--MusicHome-nextBarBackground);
  color: var(--MusicHome-nextBarText);
}

.MusicHome-nowPlaying {
  --Nubbin-attributionColor: var(--accentTextColor);
  margin-bottom: 20px;
}

.MusicHome-broadcastList {
  margin: 20px 0;
}

.MusicHome-browseSchedule {
  margin: 20px 0;
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
}

.MusicHome-browseScheduleLink {
  color: var(--MusicHome-linkColor);
}

.MusicHome-support {
  margin-top: 40px;
}

@media (--midiWidth) {
  .MusicHome-upNextRegular { display: none; }
  .MusicHome-upNextSlim { display: flex; }
}

@media (--smallWidth) {
  .MusicHome-upNextSlim {
    padding: 10px 20px;
  }
}
