/** @define AlbumCard */

.AlbumCard {
  text-align: center;
}

.AlbumCard-art {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

/* Usual technique for forcing aspect ratio of the image */
.AlbumCard-artHeight {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.AlbumCard-artImage {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.AlbumCard-artPlaceholder {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}

.AlbumCard-artPlaceholderIcon {
  font-size: 150px;
}

.AlbumCard-artist {
  font-weight: var(--fontWeightBold);
  margin-bottom: 5px;
}

.AlbumCard-artist,
.AlbumCard-title {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
}

@media (--midiWidth) {
 .AlbumCard-artist,
 .AlbumCard-title {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}
