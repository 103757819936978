/** @define ButtonPlayer */

:root {
  --ButtonPlayer--playingColor: var(--bffBrandBlue);
  --ButtonPlayer--regularButtonColor: var(--regularTextColor);
  --ButtonPlayer--backgroundColor: color(#101010 a(60%));
  --ButtonPlayer--highlightColor: color(#101010 a(45%));
  --ButtonPlayer--borderRing: transparent;
  --ButtonPlayer--highlightedBorder: var(--bffBrandWhite);
}

@media (prefers-color-scheme: dark) {
  :root {
    --ButtonPlayer--borderRing: var(--bffBrandWhite);
  }
}

.ButtonPlayer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  border-radius: 50%;

  color: var(--ButtonPlayer--regularButtonColor);
  background: var(--ButtonPlayer--backgroundColor);
  border: 2px solid var(--ButtonPlayer--borderRing);

  font-size: 32px; /* size the icons */

  transition: transform .1s ease-in .1s, background-color .2s linear, border-color .1s linear;
}

.ButtonPlayer:hover,
.ButtonPlayer:link:hover,
.ButtonPlayer:link:focus {
  text-decoration: none;
  background: var(--ButtonPlayer--highlightColor);
  border-color: var(--ButtonPlayer--playingColor);
  cursor: pointer;
}

.ButtonPlayer:active {
  transform: scale(.9);
  transition: transform .1s ease-out;
}

.ButtonPlayer-loadingButton,
.ButtonPlayer-pauseButton {
  display: none;
}

/* @state is-playing Playback of this source is occuring */
.ButtonPlayer.is-playing,
.ButtonPlayer.is-playing:hover,
.ButtonPlayer.is-playing:link:hover,
.ButtonPlayer.is-playing:link:focus {
  background: var(--ButtonPlayer--playingColor);
}

.ButtonPlayer.is-playing .ButtonPlayer-pauseButton {
  display: block;
}

.ButtonPlayer.is-playing .ButtonPlayer-playButton {
  display: none;
}

/* @state is-loading The source is loading before being played */
@keyframes ButtonPlayer-loading {
  0% {
    transform: rotate(0);
    opacity: 1.0;
  }
  50% {
    opacity: .7;
  }
  100% {
    transform: rotate(-359deg);
    opacity: 1.0;
  }
}

.ButtonPlayer.is-loading {
  border-style: dashed;
  border-color: var(--ButtonPlayer--playingColor);
  animation: ButtonPlayer-loading 1.5s linear infinite;
}

.ButtonPlayer.is-loading .ButtonPlayer-pauseButton,
.ButtonPlayer.is-loading .ButtonPlayer-playButton {
  display: none;
}

.ButtonPlayer.is-loading .ButtonPlayer-loadingButton {
  display: block;
}

/**
  * @variant small Smaller button for cosier contexts
  */
.ButtonPlayer--small {
  font-size: 24px; /* size the icons */
}

/**
  * @variant large Larger button for banners and cover contexts
  */
  .ButtonPlayer--large {
    font-size: 52px; /* size the icons */
  }


/**
  * @variant border Always draw a solid border
  */
.ButtonPlayer--border {
  border-color: var(--ButtonPlayer--highlightedBorder);
}
