/** @define SlideDeck */
:root {
  --SlideDeck-buttonBackground: var(--regularBackground);
  --SlideDeck-buttonBorder: var(--accentBackground);
  --SlideDeck-buttonColor: var(--regularTextColor);
}

@media (prefers-color-scheme: dark) {
  :root {
    --SlideDeck-buttonBackground: var(--accentBackground);
    --SlideDeck-buttonBorder: var(--regularBackground);
  }
}

.SlideDeck {
  position: relative;
}

.SlideDeck-slides {
  display: flex;
  align-items: stretch;
}

.SlideDeck-slide {
  flex-basis: 100%;
  flex-shrink: 0;
}

.SlideDeck-navigation {
  margin: 0;
}

.SlideDeck-nextSlide,
.SlideDeck-previousSlide {
  border-radius: 20px;
  width: 40px;
  height: 40px;

  background: var(--SlideDeck-buttonBackground);
  border: 3px solid var(--SlideDeck-buttonBorder);
  color: var(--SlideDeck-buttonColor);
  font-weight: bold;

  position: absolute;
  top: calc(50% - 20px);
  left: -20px;
}

.SlideDeck-nextSlide {
  left: auto;
  right: -20px;
}

.SlideDeck-pagination {
  text-align: center;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 0 10px;
}

.SlideDeck-paginationItem {
  display: inline-block;
  margin: 0 4px;
}

/* @variant bleedAware Ensure nav buttons render within component when at full bleed
   Moves buttons to the bottom to avoid blocking text.
 */
@media (--fullBleed) {
  .SlideDeck--bleedAware .SlideDeck-nextSlide,
  .SlideDeck--bleedAware .SlideDeck-previousSlide {
    top: auto;
    bottom: -20px;
    left: 20px;
  }
  .SlideDeck--bleedAware .SlideDeck-nextSlide {
    left: auto;
    right: 20px;
  }
}
