/* Custom styles for Double the Donation widget */
/* Use `html` prefix for specificity boost */

html #dd-container {
  margin: 20px 0;
}

html #dd-container,
html .dtd-plugin {
  font-family: inherit; /* reset font to match page */
  color: var(--regularTextColor);
}

html #dd-container .dtd-callout {
  padding: 0;
  box-shadow: none;
  background-color: var(--regularBackground);
  border: none;
}

html #dd-container .data-cell {
  background-color: var(--accentBackground);
  padding: 20px 10px;
}

html #dd-container p {
  color: var(--regularTextColor);
}

html #dd-container a,
html #dd-container a:link,
html #dd-container a:hover,
html #dd-container a:focus,
html #dd-container a:active {
  color: var(--bffBrandBlue);
}

html #dd-container a:hover,
html #dd-container a:focus,
html #dd-container a:active {
  text-decoration: underline;
}

html #dd-container .search-title {
  text-align: left;
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-weight: var(--fontWeightBold);
  margin-bottom: var(--FormattedText-margin);
}

html #dd-container .amount-header,
html #dd-container .eligibility-text {
  color: var(--accentTextColor);
}

html #dd-container .search-another-top a,
html #dd-container .search-another-top-condensed a {
  background-color: var(--accentBackground);
  color: var(--accentTextColor);
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--fontSizeM);
}

html #dd-container a[class*="mg-"][class*="-button"],
html #dd-container a[class*="mg-"][class*="-button"]:hover,
html #dd-container a[class*="mg-"][class*="-button"]:focus,
html #dd-container a[class*="mg-"][class*="-button"][disabled],
html #dd-container .lets-check,
html #dd-container .lets-check:hover,
html #dd-container .lets-check:focus {
  border: none;
  background: var(--bffBrandYellow);
  color: var(--bffBrandBlack);
  text-transform: uppercase;
  font-weight: bold;

  padding: 10px 30px;
  border-radius: 4px;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  white-space: nowrap;

  text-align: center;
  cursor: pointer;
}
