/** @define SummaryCard */
:root {
  --SummaryCard-imageBackground: var(--regularBackground);
  --SummaryCard-labelBackground: var(--bffBrandRed);
  --SummaryCard-labelColor: var(--onColorTextColor);
}

.SummaryCard {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.SummaryCard-imageContainer {
  order: 0;
  margin: 0 0 20px 0;
  flex-shrink: 0;
}

.SummaryCard-imageHeight {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
}

.SummaryCard-linkedImage {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 50;
}

.SummaryCard-mainImage {
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.SummaryCard-audioOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
}

.SummaryCard-label {
  display: inline-block;
  white-space: nowrap;

  position: absolute;
  top: 10px;
  left: 10px;

  background: var(--SummaryCard-labelBackground);
  color: var(--SummaryCard-labelColor);

  font-size: var(--fontSizeS);
  line-height: var(--fontSizeS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;

  border-radius: 2px;
  padding: 6px 10px;

  z-index: 80;
}

.SummaryCard--offsetLabelLeft .SummaryCard-label {
  left: -10px;
}

.SummaryCard--offsetLabelRight .SummaryCard-label {
  left: auto;
  right: -10px;
}

/* e.g. there is a label provided, but there's no image to overlay, so insert space to prevernt overlapping text */
.SummaryCard--paddingForLabel {
  padding-top: 50px;
}

.SummaryCard-content {
  order: 1;
}
