/** @define TagList; strict */

.TagList {
  display: block;
}

.TagList-item {
  display: inline-block;
}

.TagList-item::after {
  content: ' · ';
  margin: 0 5px;
}

.TagList-item:last-child::after {
  content: none;
}
