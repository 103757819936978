/** @define Totaliser */

:root {
  --Totaliser-spacing: 20px;

  --Totaliser-barWidth: 40px;
  --Totaliser-barColor: var(--bffBrandBlue);
  --Totaliser-trackColor: var(--bffBrandBlueAlpha);
  --Totaliser-barTextColor: var(--bffBrandWhite);
  --Totaliser-barTextShadowColor: rgba(0, 0, 0, .85);
  --Totaliser-markerColor: var(--accentBackground);
  --Totaliser-markerBorderColor: var(--regularTextColor);
  --Totaliser-targetColor: var(--bffBrandRed);
  --Totaliser-actionButtonBackground: var(--bffBrandYellow);
  --Totaliser-actionButtonText: var(--bffBrandBlack);
  --Totaliser-fundedPosition: 95%;
  --Totaliser-overFundedPosition: 100%;
}

.Totaliser {
}

.Totaliser-summary {
  display: flex;
  align-items: center;
}

.Totaliser-barContainer {
  position: relative;
  margin-right: var(--Totaliser-spacing);
  width: 100%;
}

.Totaliser-bar {
  min-width: 100px;
  flex-shrink: 10;
  flex-grow: 100;
  height: var(--Totaliser-barWidth);
  border-radius: calc(var(--Totaliser-barWidth) / 2);
  background: var(--Totaliser-trackColor);
}

.Totaliser-mercury {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 10%;
  background: var(--Totaliser-barColor);
  border-radius: calc(var(--Totaliser-barWidth) / 2);

  transition: width 2s cubic-bezier(0.175, 0.885, 0.32, 1.2);
}

.Totaliser-barLabel {
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);
  line-height: var(--Totaliser-barWidth);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--Totaliser-barTextColor);
  text-shadow: var(--Totaliser-barTextShadowColor) 1px 1px 2px;

  position: absolute;
  top: 0;
  right: auto;
  left: var(--Totaliser-barWidth);
  text-align: right;
}

.Totaliser-labelLiveData {
  display: none;
}

.Totaliser-goalMarker {
  position: absolute;
  top: -18px;
  left: var(--Totaliser-fundedPosition);

  display: block;

  width: calc(var(--Totaliser-barWidth) / 2);
  height: calc(var(--Totaliser-barWidth) / 2);
  border-radius: calc(var(--Totaliser-barWidth) / 4) calc(var(--Totaliser-barWidth) / 4) calc(var(--Totaliser-barWidth) / 4) 0;
  transform: translateX(calc(var(--Totaliser-barWidth) - (var(--Totaliser-barWidth) / -4))) rotate(-45deg);

  background: var(--Totaliser-markerColor);
  border: 4px var(--Totaliser-markerBorderColor) solid;
}

.Totaliser-description {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: 10px calc(var(--Totaliser-barWidth) / 2) 0;
}

.Totaliser-target {
  color: var(--Totaliser-targetColor);
}

.Totaliser-actions {
  display: flex;
  flex-direction: column;
}

.Totaliser-actionButton {
  margin-bottom: 4px;
  background: var(--Totaliser-actionButtonBackground);
  color: var(--Totaliser-actionButtonText);
}

.Totaliser[data-funded="5"] .Totaliser-mercury { width: calc(5 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="10"] .Totaliser-mercury { width: calc(10 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="15"] .Totaliser-mercury { width: calc(15 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="20"] .Totaliser-mercury { width: calc(20 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="25"] .Totaliser-mercury { width: calc(25 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="30"] .Totaliser-mercury { width: calc(30 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="35"] .Totaliser-mercury { width: calc(35 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="40"] .Totaliser-mercury { width: calc(40 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="45"] .Totaliser-mercury { width: calc(45 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="50"] .Totaliser-mercury { width: calc(50 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="55"] .Totaliser-mercury { width: calc(55 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="60"] .Totaliser-mercury { width: calc(60 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="65"] .Totaliser-mercury { width: calc(65 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="70"] .Totaliser-mercury { width: calc(70 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="75"] .Totaliser-mercury { width: calc(75 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="80"] .Totaliser-mercury { width: calc(80 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="85"] .Totaliser-mercury { width: calc(85 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="90"] .Totaliser-mercury { width: calc(90 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="95"] .Totaliser-mercury { width: calc(95 / 100 * var(--Totaliser-fundedPosition)); }
.Totaliser[data-funded="100"] .Totaliser-mercury { width: var(--Totaliser-fundedPosition); }
.Totaliser[data-funded="over"] .Totaliser-mercury { width: var(--Totaliser-overFundedPosition); }

.Totaliser[data-funded="5"] .Totaliser-barLabel,
.Totaliser[data-funded="10"] .Totaliser-barLabel,
.Totaliser[data-funded="15"] .Totaliser-barLabel,
.Totaliser[data-funded="20"] .Totaliser-barLabel,
.Totaliser[data-funded="25"] .Totaliser-barLabel,
.Totaliser[data-funded="30"] .Totaliser-barLabel,
.Totaliser[data-funded="35"] .Totaliser-barLabel,
.Totaliser[data-funded="40"] .Totaliser-barLabel,
.Totaliser[data-funded="45"] .Totaliser-barLabel {
  right: var(--Totaliser-barWidth);
  left: auto;
  text-align: left;
}

/* @state is-updated After data has been recieved */

.Totaliser.is-updated .Totaliser-labelAnyTarget { display: block; }

.Totaliser.is-updated .Totaliser-labelUnderTarget { display: block; }
.Totaliser.is-updated[data-funded="100"] .Totaliser-labelUnderTarget,
.Totaliser.is-updated[data-funded="over"] .Totaliser-labelUnderTarget { display: none; }

.Totaliser.is-updated[data-funded="100"] .Totaliser-labelOverTarget,
.Totaliser.is-updated[data-funded="over"] .Totaliser-labelOverTarget { display: block; }

@media (--smallWidth) {

  .Totaliser-description {
    margin: 10px 0 0;
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }

  .Totaliser-barContainer {
    position: relative;
    margin-right: calc(var(--Totaliser-spacing) / 2);
  }

  .Totaliser-barLabel {
    font-size: var(--fontSizeM);
    line-height: var(--fontHeightM);
    text-transform: uppercase;
    font-weight: var(--fontWeightBold);
    line-height: var(--Totaliser-barWidth);
    left: calc(var(--Totaliser-barWidth) / 2);
  }

  .Totaliser[data-funded="5"] .Totaliser-barLabel,
  .Totaliser[data-funded="10"] .Totaliser-barLabel,
  .Totaliser[data-funded="15"] .Totaliser-barLabel,
  .Totaliser[data-funded="20"] .Totaliser-barLabel,
  .Totaliser[data-funded="25"] .Totaliser-barLabel,
  .Totaliser[data-funded="30"] .Totaliser-barLabel,
  .Totaliser[data-funded="35"] .Totaliser-barLabel,
  .Totaliser[data-funded="40"] .Totaliser-barLabel,
  .Totaliser[data-funded="45"] .Totaliser-barLabel {
    right: calc(var(--Totaliser-barWidth) / 2);
  }
}
