/** @define Billboard */

.Billboard {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 30px 5%;
  background: linear-gradient(45deg, #7BDDD5, #49AEB7);
  color: #fff;

  text-align: center;
}

.Billboard-title {
  order: 2;
  font-size: var(--fontSizeL);
  line-height: var(--fontHeightL);
  font-size: 30px;
  line-height: 36px;
  font-weight: var(--fontWeightLight);
  margin: 20px 0;
}

.Billboard-title::after {
  content: "";
  display: block;
  margin: 20px auto;
  width: 45px;
  border: 2px solid #fff;
  border-width: 4px 0 0 0;
}

.Billboard-tag {
  order: 1;
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  text-transform: uppercase;
  margin: 0 0 20px;
}

.Billboard-excerpt {
  order: 3;
  font-size: var(--fontSizeR);
  line-height: var(--fontHeightR);
}

.Billboard--alt {
  background: linear-gradient(45deg, #252525, #58595B);
}

@media (--midiWidth) {
  .Billboard-title {
    font-size: var(--fontSizeL);
    line-height: var(--fontHeightL);
    font-weight: var(--fontWeightLight);
  }

  .Billboard-excerpt {
    font-size: var(--fontSizeS);
    line-height: var(--fontHeightS);
  }
}

@media (--smallWidth) {
  .Billboard {
    padding: 5%;
  }

  .Billboard-tag {
    margin: 0 0 10px;
  }

  .Billboard-title {
    font-size: var(--fontSizeR);
    line-height: var(--fontHeightR);
    font-weight: var(--fontWeightLight);
    margin: 10px 0;
  }

  .Billboard-excerpt {
    font-size: var(--fontSizeXS);
    line-height: var(--fontHeightXS);
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
