/** @define HomePageContainer */

@import "../components/slidedeck";
@import "../components/slides/billboard";
@import "../components/slides/responsiveimageslide";
@import "../components/featurelist";
@import "../components/minischedule";
@import "../components/homepage-blocks";
@import "../components/infoblock";
@import "../components/getnewsletter";
@import "../legacy/top30";

:root {
  --HomePageContainer-backgroundColor: var(--accentBackground);
  --HomePageContainer-blockBackgroundColor: var(--regularBackground);
  --HomePageContainer-innerBorderColor: var(--accentTextColor);
  --HomePageContainer-accentRuleColor: var(--bffBrandBlue);
}

@media (prefers-color-scheme: dark) {
  :root {
    --HomePageContainer-backgroundColor: var(--regularBackground);
    --HomePageContainer-blockBackgroundColor: var(--accentBackground);
    --HomePageContainer-innerBorderColor: var(--accentBackground);
  }
}

.HomePageContainer {
  background: var(--HomePageContainer-backgroundColor);
  padding: 25px 0;
}

.HomePageContainer-main {
  border-bottom: 1px solid var(--HomePageContainer-innerBorderColor);
}

.HomePageContainer-blocks {}

.HomePageContainer-chart {
  flex-basis: 380px;
  flex-shrink: 0;
  background-color: var(--HomePageContainer-blockBackgroundColor);
}

.HomePageContainer-infoBlocks {
  text-align: center;
  background-color: var(--HomePageContainer-blockBackgroundColor);
}

.HomePageContainer-donate {
  justify-content: center;
}

@media (--fullBleed) {
  .HomePageContainer {
    padding-top: 0;
  }
}

@media (--mediumWidth) {
  .HomePageContainer {
    background: none;
    padding: 0;
  }

  .HomePageContainer-main {
    border: none;
  }
}

@media (--smallWidth) {
  .HomePageContainer {
    background: var(--HomePageContainer-backgroundColor);
  }

  .HomePageContainer-infoBlocks {
    display: block;
  }
}
