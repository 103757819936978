/** @define TrackInfo */

.TrackInfo {
  flex-grow: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TrackInfo-title {
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TrackInfo-subTitle {
  display: block;

  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
