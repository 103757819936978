/** @define FullScreenMenu */

:root {
  --FullScreenMenu-dividerColor: var(--lightGreyTextColor);
  --FullScreenMenu-firstDividerColor: var(--bffBrandBlue);
  --FullScreenMenu-secondDividerColor: var(--bffBrandYellow);
  --FullScreenMenu-thirdDividerColor: var(--bffBrandRed);
  --FullScreenMenu-maxWidth: var(--contentMaxWidth);
}

.FullScreenMenu {
  text-align: center;
  padding-top: 80px;
}

.FullScreenMenu-content {
  width: 100%;
  max-width: var(--FullScreenMenu-maxWidth);
  margin: 0 auto;
}

.FullScreenMenu-list {
  margin: auto 0;
}

.FullScreenMenu-listItem {
  font-size: 30px;
  line-height: 300%;
  font-weight: var(--fontWeightLight);
  text-transform: uppercase;
}

.FullScreenMenu-listItem::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 45px;
  border: 2px solid var(--FullScreenMenu-dividerColor);
  border-width: 2px 0 0 0;
}

.FullScreenMenu-listItem:nth-child(3n+1)::after {
  border-color: var(--FullScreenMenu-firstDividerColor);
}

.FullScreenMenu-listItem:nth-child(3n+2)::after {
  border-color: var(--FullScreenMenu-secondDividerColor);
}

.FullScreenMenu-listItem:nth-child(3n+3)::after {
  border-color: var(--FullScreenMenu-thirdDividerColor);
}

.FullScreenMenu-listItem:last-child::after {
  border-width: 0;
}

.FullScreenMenu-discover {
  margin: 40px 0 0;
}

.FullScreenMenu-search {
  margin: 0 auto 40px;
}

.FullScreenMenu-socialIcons {
  margin-bottom: 20px;
}

@media (--smallWidth), (max-height: 550px) {
  .FullScreenMenu {
    padding-top: 30px;
  }

  .FullScreenMenu-list {
    margin: 0 0 40px;
  }

  .FullScreenMenu-listItem {
    font-size: 20px;
    line-height: 300%;
    font-weight: var(--fontWeightLight);
    text-transform: uppercase;
  }

  .FullScreenMenu-listItem::after {
    border-width: 1.5px 0 0 0;
  }

  .FullScreenMenu-discover {
    margin: 20px 0 0;
  }
}
