/** @define BFFLogo */
:root {
  --BFFLogo-bounceEasing: var(--transitionBounce);
}

.BFFLogo {
  display: inline-block;
  position: relative;
  width: 300px;
}

.BFFLogo-canvas {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
}

.BFFLogo-layout {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  transition: transform .3s var(--BFFLogo-bounceEasing);
}

.BFFLogo-bf {
  position: absolute;
  width: 32%;
  height: 56.3%;
  background: transparent no-repeat;
  /* bfflogo.svg.css: background-image: url('../assets/logo/letters-bf.svg') */
  transition: transform .5s var(--BFFLogo-bounceEasing) .2s;
  transform: translate(45%, 0);
  transform-origin: 50% 0;

  z-index: 200;
}

.BFFLogo-fdotfm {
  position: absolute;
  width: 45.3%;
  height: 56.7%;

  background: transparent no-repeat;
  /* bfflogo.svg.css: background-image: url('../assets/logo/letters-fdotfm.svg') */
  transition: transform .5s var(--BFFLogo-bounceEasing) .2s;
  transform: translate(100%, 7%);
  transform-origin: 50% 0;

  z-index: 300;
}

.BFFLogo-bracelet {
  position: absolute;
  width: 97.3%;
  height: 73.3%;
  background: transparent no-repeat;
  /* bfflogo.svg.css: background-image: url('../assets/logo/bracelet.svg') */
  transition: transform .9s var(--BFFLogo-bounceEasing);
  transform: translate(0, 55%);

  z-index: 250;
}

/** @state is-minimized — the logo is collapsed and shrunk */
.BFFLogo.is-minimized {}

.BFFLogo.is-minimized .BFFLogo-bf {
  transform: scale(0.5) translate(150%, -30%);
}

.BFFLogo.is-minimized .BFFLogo-fdotfm {
  transform: scale(0.5) translate(157%, -25%);
}

.BFFLogo.is-minimized .BFFLogo-bracelet {
  transform: translate(0, -200%) scale(0.1, 0.5);
}

.BFFLogo:hover .BFFLogo-layout,
.BFFLogo:focus-visible .BFFLogo-layout {
  transform: scale(1.2) translateY(6px);
}
