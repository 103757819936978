/** @define SubscribeDialog */
:root {
  --SubscribeDialog-backgroundColor: var(--regularBackground);
  --SubscribeDialog-offsetBackgroundColor: var(--accentBackground);
  --SubscribeDialog-textColor: var(--regularTextColor);
  --SubscribeDialog-urlBackgroundColor: var(--accentBackground);
  --SubscribeDialog-designationTextColor: var(--bffBrandRed);

  --SubscribeDialog-padding: 20px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --SubscribeDialog-backgroundColor: var(--accentBackground);
    --SubscribeDialog-urlBackgroundColor: var(--regularBackground);
 }
}

.SubscribeDialog {
  background: var(--SubscribeDialog-backgroundColor);
  color: var(--SubscribeDialog-textColor);
  padding: var(--SubscribeDialog-padding);

  text-align: left;
}

.SubscribeDialog-title {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
  margin: 0 0 var(--SubscribeDialog-padding) 0;
}

.SubscribeDialog-info {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  margin: var(--SubscribeDialog-padding) 0;
}

.SubscribeDialog-designation {
  text-transform: uppercase;
  color: var(--SubscribeDialog-designationTextColor);
}

.SubscribeDialog-footnote {
  font-size: var(--fontSizeS);
  line-height: var(--fontHeightS);
  margin: var(--SubscribeDialog-padding) 0;
}

.SubscribeDialog-url {
  font-size: var(--fontSizeM);
  line-height: var(--fontHeightM);
  font-family: monospace;
  font-weight: var(--fontWeightBold);
  text-transform: lowercase;
  white-space: nowrap;
  padding: 10px;
  margin: var(--SubscribeDialog-padding) 0;
  background: var(--SubscribeDialog-urlBackgroundColor);
  display: block;

  overflow-x: hidden;
  overflow-x: ellipsis;
  text-overflow: ellipsis;
}

.SubscribeDialog-urlIcon {
  vertical-align: middle;
}

.SubscribeDialog-services {
  margin: var(--SubscribeDialog-padding) 0;
  font-size: 70px;
}

.SubscribeDialog--smallServiceIcons .SubscribeDialog-services {
  font-size: 35px;
}

.SubscribeDialog-actions {
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
}

.SubscribeDialog-actions > li {
  margin-right: 10px;
}

.SubscribeDialog-actions > li:last-child {
  margin-right: 0;
}

.SubscribeDialog-extra {
  margin: var(--SubscribeDialog-padding) calc(-1 * var(--SubscribeDialog-padding)) calc(-1 * var(--SubscribeDialog-padding)) calc(-1 * var(--SubscribeDialog-padding));
  padding: var(--SubscribeDialog-padding);
  background: var(--SubscribeDialog-offsetBackgroundColor);
}

@media (--smallWidth) {
  .SubscribeDialog-services {
    font-size: 42px;
  }

  .SubscribeDialog-actions {
    flex-direction: column;
  }

  .SubscribeDialog-actions > li {
    margin-right: 0;
  }

  .SubscribeDialog-action {
    width: 100%;
    margin-bottom: 4px;
  }
}
