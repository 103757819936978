/** @define AdvancedPlaybackControls */

:root {
  --AdvancedPlaybackControls--textColor: var(--bffBrandBlack);
  --AdvancedPlaybackControls--hoverColor: var(--bffBrandBlack);
}

.AdvancedPlaybackControls {
  display: flex;
  justify-content: space-between;
  color: var(--AdvancedPlaybackControls--textColor);
}

.AdvancedPlaybackControls-button {
  width: 40px;
  height: 40px;
  overflow: hidden;

  color: var(--AdvancedPlaybackControls--textColor);

  transition: transform .1s ease-out;

  cursor: pointer;
}

.AdvancedPlaybackControls-button:hover,
.AdvancedPlaybackControls-button:focus {
  transform: scale(1.4);
  outline: none;
}

.AdvancedPlaybackControls-skipBackButton,
.AdvancedPlaybackControls-skipForwardButton,
.AdvancedPlaybackControls-skipToTheEndButton {
  position: relative;
  font-size: 35px;
  line-height: 40px;
}

.AdvancedPlaybackControls-skipBackLabel,
.AdvancedPlaybackControls-skipForwardLabel {
  font-size: var(--fontSizeXS);
  line-height: var(--fontHeightXS);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;

  position: absolute;
  top: 14px;
  right: 15px;
}

.AdvancedPlaybackControls-skipForwardLabel {
  right: auto;
  left: 15px;
}

.AdvancedPlaybackControls-jumpToButton {
  line-height: 35px;
}

.AdvancedPlaybackControls.is-paused {
  display: none;
}

.AdvancedPlaybackControls.is-streamingLive {
  display: flex;
}

/* Can't skip forward in live broadcasts */
.AdvancedPlaybackControls.is-streamingLive .AdvancedPlaybackControls-skipForward15Control,
.AdvancedPlaybackControls.is-streamingLive .AdvancedPlaybackControls-jumpToControl {
  display: none;
}

/* Don't allow any seeking for archives. */
.AdvancedPlaybackControls.is-playingArchive .AdvancedPlaybackControls-skipForward15Control,
.AdvancedPlaybackControls.is-playingArchive .AdvancedPlaybackControls-jumpToControl {
  display: none;
}

.AdvancedPlaybackControls.is-playingPodcast {
  display: flex;
}

.AdvancedPlaybackControls.is-playingPodcast .AdvancedPlaybackControls-skipToTheEndControl {
  display: none;
}
